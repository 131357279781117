import { Formik, type FormikProps } from 'formik';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
import { useMutation } from '@tanstack/react-query';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useGlobalToast } from '@/lib/hook';
import { FC, useCallback, useMemo, useRef, useState } from 'react';
import { UiStack, uiStyles, UiText } from '@/lib/ui';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { sendTestEmails, SendTestEmailsRequest, SendTestEmailsResponse } from '@/api/registration/automatedEmail';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';

interface TestEmailFormProps {
  emailId: number
  emailType: string
  isOpen: boolean
  onClose: () => void
}

interface FormData {
  toEmails: string
}

const TestEmailsForm: FC<TestEmailFormProps> = ({
  emailId,
  emailType,
  isOpen,
  onClose,
}: TestEmailFormProps) => {
  const formRef = useRef<FormikProps<FormData> | null>(null);
  const [errors, setErrors] = useState<string[]>([]);
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();
  const { showToast } = useGlobalToast();
  const { reportToGlobal } = useApiErrorHandler();

  const initialValues = useMemo(() => {
    return {
      emailId,
      toEmails: '',
    };
  }, [
    emailId,
  ]);

  const { mutate: sendTestEmail, isLoading: isCreateEmailCampaignLoading } = useMutation<SendTestEmailsResponse, Error, SendTestEmailsRequest>({
    mutationFn: async (params: Omit<SendTestEmailsRequest, 'eventId'>) => {
      return await sendTestEmails(createTenantAdminApiRequest)(params);
    },
    onSuccess: (res) => {
      if ((res.errors ?? []).length > 0) {
        setErrors(res?.errors ?? []);
        return;
      }
      showToast.success('Emails sent.');
      onClose();
      if (formRef?.current) {
        formRef?.current?.resetForm();
      }
    },
    onError: (error) => {
      reportToGlobal(error);
      setErrors([error.message ?? 'Failed to send the test emails.']);
    }
  });
  const submitForm = useCallback(async (values: FormData) => {
    sendTestEmail({
      emailId: emailId,
      toEmails: values.toEmails.trim().split(','),
    });
  }, [
    sendTestEmail,
  ]);

  return (
    <Formik<FormData>
      initialValues={initialValues}
      onSubmit={submitForm}
      enableReinitialize
      innerRef={formRef}
    >
      {() => {
          return (
            <BaseFormDrawer
              title={'Send test emails'}
              isOpen={isOpen}
              isLoading={isCreateEmailCampaignLoading}
              onClose={() => {
                onClose();
                setErrors([]);
                formRef?.current?.resetForm();
              }}
              size={'lg'}
              buttonText={'Send'}
              buttonLoadingText={'Sending...'}
            >
              <BaseFormFieldGroup>
                <UiStack
                  spacing={1}
                  p={4}
                  borderRadius={uiStyles.borderRadius}
                  bgColor={'blackAlpha.50'}
                >
                  <UiText variant={'body2'} color={'text.secondary'}>Email type</UiText>
                  <UiText>{emailType}</UiText>
                </UiStack>
                <BaseDividerHorizontal height={0} />
                <BaseFormInputField
                  name={'toEmails'}
                  label={'To emails'}
                  helperText={'If you want to send to multiple emails, please separate them by ",". e.g. nick@evexus.app,john@evexus.app,jess@evexus.app'}
                />
                {errors.length > 0 && (
                  <UiStack spacing={4} flexGrow={1} py={4}>
                    {errors.map((error, index) => {
                      return (
                        <BaseMessageBarError key={index}>
                          {error}
                        </BaseMessageBarError>
                      );
                    })}
                  </UiStack>
                )}
              </BaseFormFieldGroup>
            </BaseFormDrawer>
          );
        }
      }
    </Formik>
  );
};

export default TestEmailsForm;

import {
  UiHStack,
  UiImage,
  uiStyles,
  type UiHStackProps,
  UiStack,
  UiText,
  UiIconArrowRight,
  UiBox, UiIconGear, UiIconLayout,
} from '@/lib/ui';
import {
  useDisclosure
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useCallback, type FC } from 'react';
import { registration } from '@/api';
import { type ApiResponseSingle } from '@/api/tenantClient';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTenantApi } from '@/account/hook/useTenantApi';
import BaseButtonPlainIconEdit from '@/base/Button/PlainIconEdit';
import BasePlainIcon from '@/base/Button/PlainIcon';
import BadgeForm from '@/registration/manage/RegistrationBadge/BadgeForm';

dayjs.extend(utc);

export interface BadgeRowProps extends UiHStackProps {
  data: registration.RegistrationBadgeTableRow
}

const BadgeRow: FC<BadgeRowProps> = ({
  data,
  ...props
}) => {
  const { isOpen, onToggle } = useDisclosure();
  const queryClient = useQueryClient();

  return (
    <>
      <UiHStack
        py={4}
        px={8}
        bgColor={'#fff'}
        // px={8}
        flexGrow={1}
        spacing={4}
        justifyContent={'space-between'}
        {...uiStyles.hoverFlex({ bgColor: 'gray.50' })}
        {...props}
      >
        <UiHStack>
          <UiText>
            {data.title ?? ''}
          </UiText>
        </UiHStack>
        <UiHStack spacing={8}>
          <BaseButtonPlainIconEdit onClick={onToggle}/>
          <BasePlainIcon
            label={'Design'}
            Icon={UiIconLayout}
            onClick={() => {}}
          />
        </UiHStack>
      </UiHStack>
      <BadgeForm
        // onSubmit={}
        onClose={onToggle}
        onSaveSuccess={() => {}}
        isVisible={isOpen}
        badgeId={Number(data.id)}
      />
    </>
  );
};

export default BadgeRow;

import { type FC } from 'react';
import {
  UiHStack,
  type UiHStackProps,
  UiStack,
  UiText,
  UiButton,
  UiDrawer,
  UiDrawerOverlay,
  UiDrawerCloseButton,
  UiDrawerContent,
  UiDrawerBody,
  UiDrawerFooter,
  UiTag,
  UiSimpleGrid
} from '@/lib/ui';
import { registration, type account } from '@/api';
import BaseButtonPlainIconEdit from '@/base/Button/PlainIconEdit';
import BaseButtonPlainIconDelete from '@/base/Button/PlainIconDelete';
import { PermissionAction, PermissionDomain } from '@/api/constant/adminUserPermission';
import BaseAclContainer from '@/account/component/AclContainer';
import { useDisclosure } from '@chakra-ui/react';
import TaxRateForm from './TaxRateForm';
import DeletionConfirmation from '../DiscountCodes/DeletionConfirmation';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useGlobalToast } from '@/lib/hook';

export interface CodeCardProps extends UiHStackProps {
  tenant: account.TenantData
  taxRate: registration.TaxRate
}

const TaxRateCard: FC<CodeCardProps> = ({ tenant, taxRate, ...props }) => {
  const queryClient = useQueryClient();
  const { showToast } = useGlobalToast();
  const { createTenantAdminApiRequest } = useTenantApi();
  const { eventId } = useRegisterRoute();
  const { isOpen: isVisibleEdit, onToggle: onToggleEdit } = useDisclosure();
  const { isOpen: confirmDeletion, onToggle: onToggleConfirmDeletion } = useDisclosure();

  const { mutate: deleteTaxRateMutate, isLoading: isDeleteTaxRateLoading } = useMutation({
    mutationFn: async () => {
      return await registration.deleteTaxRate(createTenantAdminApiRequest)({ id: taxRate.id, eventId });
    },
    onSuccess: (res) => {
      if (res.errors?.length) {
        const [errorMsg] = res.errors;
        showToast.error(errorMsg);
        return;
      }
      void queryClient.invalidateQueries({
        queryKey: [registration.taxRateListQueryKey, { eventId }]
      });
    }
  });

  const onConfirmDelete = () => {
    deleteTaxRateMutate();
    onToggleConfirmDeletion();
  };

  return (
    <>
      <UiHStack
        alignItems={'center'}
        spacing={8}
        p={6}
        bgColor={'#fff'}
        // borderRadius={uiStyles.borderRadius}
        {...props}
      >
        <UiSimpleGrid
          columns={3}
          gap={8}
          flexGrow={1}
        >
          <UiStack alignItems={'stretch'} justifyContent={'space-between'} spacing={0}>
            <UiText variant={'body2'} color={'text.secondary'}>Name</UiText>
            <UiText variant={'body1'}>{taxRate.name}</UiText>
          </UiStack>
          <UiStack flexGrow={1} justifyContent={'space-between'} spacing={0}>
            <UiText variant={'body2'} color={'text.secondary'}>Percentage</UiText>
            <UiText variant='body1'>{taxRate.percentage}</UiText>
          </UiStack>
          <UiStack flexGrow={1} justifyContent={'space-between'} spacing={0}>
            <UiText variant={'body2'} color={'text.secondary'}>Active</UiText>
            <UiText variant='body1'><UiTag>
              {taxRate.active ? 'True' : 'False'}</UiTag></UiText>
          </UiStack>
        </UiSimpleGrid>
        <BaseAclContainer tenantId={tenant?.id ?? 0} permissionsRequired={[[PermissionDomain.Registration, PermissionAction.Write]]}>
          <UiHStack spacing={6} justifyContent={'space-between'}>
            <BaseButtonPlainIconEdit
              onClick={(onToggleEdit)}
            />
            {/* <BaseButtonPlainIconClone
            // onClick={onToggleConfirmClone}
            /> */}
            <BaseButtonPlainIconDelete
              onClick={onToggleConfirmDeletion}
            />
          </UiHStack>
          <TaxRateForm
            taxRate={taxRate}
            onClose={onToggleEdit}
            isVisible={isVisibleEdit}
            onSaveSuccess={onToggleEdit}
          />
        </BaseAclContainer>
      </UiHStack>
      <UiDrawer
        placement={'bottom'}
        size={'lg'}
        isOpen={false}
        onClose={() => { }}
      // isOpen={isConfirmClone} onClose={onToggleConfirmClone}
      >
        <UiDrawerOverlay />
        <UiDrawerCloseButton size={'lg'} color={'primary.500'} />
        <UiDrawerContent p={4}>
          <UiDrawerBody>
            <UiText variant={'title'} fontWeight={400}>
              Do you confirm to clone this discount code?
            </UiText>
          </UiDrawerBody>
          <UiDrawerFooter>
            <UiHStack spacing={8}>
              <UiButton
              // onClick={onToggleConfirmClone} colorScheme={'gray'} px={8}
              >
                No
              </UiButton>
              <UiButton colorScheme={'primary'}
                ml={4}
                onClick={onConfirmDelete}
                isLoading={isDeleteTaxRateLoading}
                px={8}
              >
                Yes
              </UiButton>
            </UiHStack>
          </UiDrawerFooter>
        </UiDrawerContent>
      </UiDrawer>
      <DeletionConfirmation
        isOpen={confirmDeletion}
        onClose={onToggleConfirmDeletion}
        onConfirm={onConfirmDelete}
        name={''} />
    </>
  );
};

export default TaxRateCard;

import {
  callApi,
  type ApiRequest,
  type ApiResponse,
  deleteAPI,
  type ApiResponseSingle,
} from '@/api/tenantClient';

export const localeQueryKey = 'registration.locale';
export const localeListQueryKey = 'registration.localeList';

export interface Locale {
  id: number
  name: string
  country: string
  default: boolean
  language: string
  currencyId: number | string
  eventId: number
  currency: string
  taxRateId: number
  createdAt: string
  updatedAt: string
}

export interface LoadLocaleListRequest {
  eventId?: number
}

export function loadLocaleList(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (
    params: LoadLocaleListRequest
  ): Promise<ApiResponse<Locale>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/admin/locales';
    request.endpoint.query = {
      ...params.eventId && { event_id: params.eventId.toString() }
    };
    return await callApi(request);
  };
}

export interface SaveLocaleRequest {
  id?: string | number
  eventId: string | number
  name: string
  country: string
  currency: string | number
  language: string
  taxRateId?: string | number
  default: boolean | string
}

export function saveLocale(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (
    params: SaveLocaleRequest
  ): Promise<ApiResponse<Locale[]>> => {
    if (params.id) {
      const { name, currency, eventId, country: code, taxRateId } = params;
      request.method = 'PUT';
      request.endpoint.path = '/api/v1/admin/locales/' + params.id;
      request.payload = {
        admin_locale: {
          name,
          code,
          default_language: 'en', // this is for the default language for now
          default: params.default,
          event_id: eventId,
          currency_id: currency,
          registration_tax_rate_id: taxRateId
        }
      };
    } else {
      const { name, country, language, currency, eventId, taxRateId } = params;
      request.method = 'POST';
      request.endpoint.path = '/api/v1/admin/locales';
      request.payload = {
        admin_locale: {
          name,
          code: country,
          default_language: language, // this is for the default language for now
          default: params.default,
          currency_id: currency,
          event_id: eventId,
          registration_tax_rate_id: taxRateId
        }
      };
    }

    return await callApi<Locale[]>(request);
  };
}

export interface DeleteLocaleRequest {
  id: number
  eventId: number | string
}

export function deleteLocale(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (params: DeleteLocaleRequest): Promise<ApiResponse<null>> => {
    request.method = 'DELETE';
    request.endpoint.path = '/api/v1/admin/locales/' + params.id;
    return await callApi(request);
  };
}

export interface ICurrencyRes {
  id: number
  code: string
  eventId: number
  createdAt: string
  updatedAt: string
}

export function currencyOptions(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (params: {
    eventId: number | string
  }) => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/admin/currencies';
    request.endpoint.query = {
      event_id: params.eventId,
    };
    return await callApi<ICurrencyRes>(request);
  };
}

export type ICountryOptionsRes = [string, string];

export function countryOptions(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (): Promise<ApiResponse<ICountryOptionsRes>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/admin/locales/country_options';
    return await callApi(request);
  };
}

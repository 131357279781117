import { useQuery } from '@tanstack/react-query';
import { registration } from '@/api';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useRegisterRoute } from './useRegisterRoute';
import { useMemo } from 'react';

export function useEventOptionsQuery() {
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } =
    useTenantApi();
  const { tenantCode } = useRegisterRoute();
  const eventOptionsQuery = useQuery({
    queryKey: [registration.eventOptionsQueryKey, { tenantCode }],
    queryFn: async () => {
      return await registration.loadEventOptions(createTenantAdminApiRequest)();
    },
    enabled: !isApiPreparing && !!tenantCode,
  });

  const eventOptions = useMemo(() => {
    return (eventOptionsQuery?.data?.items ?? []).map(
      (event: { id: string, name: string }) => {
        return {
          value: event.id,
          label: event.name,
        };
      }
    );
  }, [eventOptionsQuery]);

  return { eventOptions, ...eventOptionsQuery };
}

import { type FC } from 'react';
import {
  UiText,
  UiStack,
  UiIconClipboardText, UiSimpleGrid,
} from '@/lib/ui';
import { generatePageUrl } from '@/app/pages';
import Step from './Step';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useEventSettingsQuery } from '@/registration/hook/useEventSettingsQuery';

export interface LocalesProps {
  isActive?: boolean
}

const Locales: FC<LocalesProps> = ({
  isActive
}) => {
  const { tenantCode, eventId } = useRegisterRoute();
  const { data: eventSettings } = useEventSettingsQuery(eventId);

  return (
    <Step
      Icon={UiIconClipboardText}
      description={'Locales'}
      isActive={isActive}
      navLink={generatePageUrl('RegistrationBuildEventLocales', { tenantCode, eventId })}
      isSet={eventSettings?.locales?.isSet}
      // stepNumber={3}
    >
      <UiStack>
        <UiSimpleGrid flexGrow={1} columns={2} gap={4}>
          <UiText variant={'body2'} color={'text.secondary'}>{eventSettings?.locales?.total ?? 1} total</UiText>
        </UiSimpleGrid>
      </UiStack>
    </Step>
  );
};

export default Locales;

import { type FC, type ReactElement } from 'react';
import { Field, type FieldProps } from 'formik';
import {
  UiFormControl,
  UiFormLabel,
  UiInput,
  type UiInputProps,
  UiFormErrorMessage,
  UiFormHelperText,
  UiHStack,
  UiText,
  UiVStack,
  UiInputGroup,
  UiInputRightElement,
} from '@/lib/ui';
import FieldContainer, { type FieldContainerProps } from './FieldContainer';

export interface InputFieldProps extends Omit<FieldContainerProps, 'children'> {
  label?: string
  name: string
  type?: UiInputProps['type']
  placeholder?: string
  helperText?: string
  isRequired?: boolean
  disabled?: boolean
  hidden?: boolean
  rightElement?: ReactElement
  // For date or number input
  min?: number
  max?: number
  maxLength?: number
}

const InputField: FC<InputFieldProps> = ({
  label,
  name,
  type = 'text',
  helperText = undefined,
  isRequired = true,
  placeholder = '',
  layout = 'inline',
  disabled = false,
  hidden = false,
  rightElement = null,
  min,
  max,
  maxLength,
}) => {
  return (
    <Field name={name} >
      {({ field, form }: FieldProps) => {
        return (
          <UiFormControl isInvalid={!!form.errors[name]} flexGrow={1} style={{ display: hidden ? 'none' : 'block' }}>
            <FieldContainer
              layout={layout}
            >
              {!!label  ? isRequired ? (
                <UiHStack alignItems={'flex-start'} spacing={0}>
                  <UiFormLabel>{label}</UiFormLabel>
                  <UiText color={'gray.600'} variant='title'>*</UiText>
                </UiHStack>
              ) : (
                <UiFormLabel>{label}</UiFormLabel>
              ) : (<UiFormLabel/>)}
              <UiVStack alignItems={'stretch'} spacing={0}>
                {rightElement ? (
                  <UiInputGroup size='md'>
                    <UiInput
                      {...field}
                      placeholder={placeholder}
                      type={type}
                      disabled={disabled}
                      pr="2.5rem"
                      min={min}
                      max={max}
                      maxLength={maxLength}
                    />
                    <UiInputRightElement width='2.5rem'>
                      {rightElement}
                    </UiInputRightElement>
                  </UiInputGroup>
                ) : (
                  <UiInput {...field}
                    placeholder={placeholder}
                    type={type}
                    disabled={disabled}
                    min={min}
                    max={max}
                    maxLength={maxLength}
                  />
                )}
                {!!helperText && (
                  <UiFormHelperText>
                    {helperText}
                  </UiFormHelperText>
                )}
                {!!form.errors[name] && (<UiFormErrorMessage>{form.errors[name] as string}</UiFormErrorMessage>)}
              </UiVStack>
            </FieldContainer>
          </UiFormControl>
        );
      }}
    </Field>
  );
};

export default InputField;

import { Formik, type FormikProps } from 'formik';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
import { useRef } from 'react';
import { type IScheduledEmailRes } from '@/api/registration/scheduledEmail';

interface ViewScheduledEmailProps {
  isOpen: boolean
  emailId: string | number | null
  onToggle: () => void
  onClose: () => void
  scheduledEmailTableData: IScheduledEmailRes[]
}

interface FormData extends Omit<IScheduledEmailRes, 'id'> { }

const ViewScheduledEmail = ({ isOpen, emailId, onClose, scheduledEmailTableData }: ViewScheduledEmailProps) => {
  const formRef = useRef<FormikProps<FormData> | null>(null);

  const scheduledEmailInfo = scheduledEmailTableData.find((item) => { return item.id === emailId; });

  return (
    <Formik<FormData>
      initialValues={scheduledEmailInfo ?? {
        dateTimeScheduled: '',
        subjectLine: '',
        event: '',
        type: '',
      }}
      onSubmit={() => { }}
      enableReinitialize
      innerRef={formRef}
    >
      {
        () => {
          return <BaseFormDrawer
            title={'View scheduled email'}
            isOpen={isOpen}
            isHiddenButton
            onClose={() => {
              onClose();
            }}>
            <BaseFormFieldGroup>
              <BaseFormInputField
                isRequired={false}
                label='Subject line'
                name='subjectLine'
                disabled
              />
              <BaseFormInputField
                isRequired={false}
                label='Type'
                name='type'
                disabled
              />
              <BaseFormInputField
                isRequired={false}
                label='Event'
                name='event'
                disabled />
              <BaseFormInputField
                isRequired={false}
                label='Date and time scheduled'
                name='dateTimeScheduled'
                disabled
              />
            </BaseFormFieldGroup>
          </BaseFormDrawer>;
        }
      }
    </Formik>
  );
};

export default ViewScheduledEmail;

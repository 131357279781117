import { type FC } from 'react';
import {
  useDisclosure
} from '@chakra-ui/react';
import {
  type UiHStackProps,
  UiIconPlusCircle
} from '@/lib/ui';
import BaseButtonIconText from '@/base/Button/IconText';
import CurrencyForm from './CurrencyForm';

export interface NewCurrencyProps extends UiHStackProps {}

const NewCurrency: FC<NewCurrencyProps> = () => {
  const { isOpen, onToggle } = useDisclosure();
  return (
    <>
      <BaseButtonIconText Icon={UiIconPlusCircle} onClick={onToggle}>Add currency</BaseButtonIconText>
      <CurrencyForm
        onClose={onToggle}
        isVisible={isOpen}
        onSaveSuccess={() => {}}
      />
    </>
  );
};

export default NewCurrency;

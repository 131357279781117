import { Box, Button, Container, createIcon, Flex, Heading, Menu, MenuButton, MenuDivider, MenuItem, MenuList, Select, useToast } from '@chakra-ui/react';
import React, { FC, useState } from 'react';
import { BadgeTemplate } from './BadgeDesignerPage';
import { EditorRef } from 'react-email-editor';
import SaveAsModalMenuItem from './SaveAsModalMenuItem';
import { designActivated, designDeletedToast, designNotActivated, designNotDeletedToast, designNotSavedToast, designSavedToast } from './toasts';
import { activateBadgeTemplate, BadgeTemplateData, BadgeTemplateQueryRequest, deleteBadgeTemplate, saveAsBadgeTemplate, saveBadgeTemplate } from './requests';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { ApiResponse } from '@/api/tenantClient';
import NewModalMenuItem from './NewModalMenuItem';
interface Props {
  disabled: boolean;
  badgeEditorRef: React.MutableRefObject<EditorRef | null>;
  badgeTemplates: BadgeTemplate[] | null;
  template: BadgeTemplate | null;
  setTemplate: React.Dispatch<React.SetStateAction<BadgeTemplate | null>>;
  fetchTemplates: () => Promise<void>;
}

const BadgeBuilderNav: FC<Props> = ({
  badgeEditorRef,
  badgeTemplates,
  template,
  setTemplate,
  fetchTemplates
}) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const toast = useToast();
  const { createTenantAdminApiRequest } = useTenantApi();
  const handleSelect = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    if (badgeTemplates) {
      const badgeTemplate = badgeTemplates.find(
        (template) => template.id.toString() === ev.target.value
      );
      if (badgeTemplate) {
        // console.log(badgeTemplate);
        setTemplate(badgeTemplate);
      }
    }
  };

  const renderTemplates = badgeTemplates?.map((template) => {
    return (
      <option key={template.id} value={template.id}>
        {template.name}
        {template.active == true ? ' (active)' : ''}
      </option>
    );
  });

  const ChevronDownIcon = createIcon({
    displayName: "ChevronDownIcon",
    d: "M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z",
  });

  const handleNewTemplate = async (ev: React.MouseEvent<HTMLElement>) => {
    ev.preventDefault;
    setLoading(true);
  
    const params: BadgeTemplateQueryRequest = {
      badgeTemplate: {
        name: name,
        html: '',
        template: []
      },
      createBlank: true,
    };
    const response = await saveAsBadgeTemplate(createTenantAdminApiRequest)(params);

    const responseTemplate: BadgeTemplateData = await response.items?.[0];
    if (responseTemplate.success == true) {
      setName('');
      fetchTemplates().then(() => {
        setTemplate(responseTemplate.badgeTemplate || null);
      });
    }
    setLoading(false);
  };
  
  function handleSave(event: React.MouseEvent<HTMLElement>): void {
    event.preventDefault;
    if (badgeEditorRef?.current?.editor) {
      badgeEditorRef.current.editor.exportHtml(async (requestData) => {
        setLoading(true);
        if (template) {
          const id = template.id;
          const { design, html } = requestData;
          navigator.clipboard.writeText(html);
          
          const params: BadgeTemplateQueryRequest = {
            badgeTemplate: {
              html: html,
              template: design
            },
            createBlank: false,
          };

          const response = await saveBadgeTemplate(createTenantAdminApiRequest)(id.toString(), params);
          const responseTemplate: BadgeTemplateData = await response.items?.[0];
          if (responseTemplate.success == true) {
            designSavedToast(toast);
            setName('');
            fetchTemplates().then(() => {
              setTemplate(responseTemplate.badgeTemplate || null);
            });
          } else {
            designNotSavedToast(toast);
          }
        }
        setLoading(false);
      });
    }
  }
  
  const handleSaveAs = (ev: React.MouseEvent<HTMLElement>) => {
    ev.preventDefault;
    if (badgeEditorRef?.current?.editor) {
      badgeEditorRef.current.editor.exportHtml(async (requestData) => {
        setLoading(true);
        const { design, html } = requestData;
        const params: BadgeTemplateQueryRequest = {
          badgeTemplate: {
            name: name,
            html: html,
            template: design,
          },
          createBlank: false, // Passing false as createBlank
        };

        const response = await saveAsBadgeTemplate(createTenantAdminApiRequest)(params);
        const responseTemplate: BadgeTemplateData = await response.items?.[0];
        if (responseTemplate.success == true) {
          designSavedToast(toast);
          setName('');
          fetchTemplates().then(() => {
            setTemplate(responseTemplate.badgeTemplate || null);
          });
        } else {
          designNotSavedToast(toast);
        }
        setLoading(false);
      });
    }
  };

  const handleDelete = async (ev: React.MouseEvent<HTMLElement>) => {
    ev.preventDefault;
    setLoading(true);
    if (template) {
      const response = await deleteBadgeTemplate(createTenantAdminApiRequest)(template.id.toString());
      
      const responseTemplate: BadgeTemplateData = await response.items?.[0];
      if (responseTemplate.success == true) {
        designDeletedToast(toast);
        fetchTemplates().then(() => {
          if (badgeTemplates) {
            const badgeTemplate = badgeTemplates.find((badgeTemplate) => {
              return badgeTemplate.active === true;
            });
            if (badgeTemplate) setTemplate(badgeTemplate);
          }
        });
      } else {
        designNotDeletedToast(toast, responseTemplate.error_message ?? 'Unknown error occurred');
      }
    }
    setLoading(false);
  };

  const handleActivate = async (ev: React.MouseEvent<HTMLElement>) => {
    ev.preventDefault;
    setLoading(true);
    if (template) {
      const response = await activateBadgeTemplate(createTenantAdminApiRequest)(template.id.toString());
      const responseTemplate: BadgeTemplateData = await response.items?.[0];
      if (responseTemplate.success == true) {
        designActivated(toast);
        fetchTemplates().then(() => {
          setTemplate(responseTemplate.badgeTemplate || null);
        });
      } else {
        designNotActivated(toast);
      }
    }
    setLoading(false);
  };

  return (
    <>
      <Box>
        <Flex
          px={2}
          h={12}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Heading as="h5">{"Edit Badge Templates"}</Heading>
            <Flex alignItems={'center'} justifyContent={'space-between'}>
              <Container paddingRight={0}>
                
              </Container>
              <Container minWidth={200} paddingRight={0}>
                <Select
                  width={'100%'}
                  value={template?.id}
                  onChange={(ev) => handleSelect(ev)}
                >
                  {badgeTemplates && renderTemplates}
                </Select>
              </Container>
              <Container paddingRight={0}>
                <Menu>
                  <MenuButton
                    as={Button}
                    variant={'secondary'}
                    rightIcon={<ChevronDownIcon />}
                    isLoading={loading}
                    disabled={loading}
                  >
                    {"Menu"}
                  </MenuButton>
                  <MenuList alignItems={'center'}>
                    <NewModalMenuItem
                      setName={setName}
                      handleNewTemplate={handleNewTemplate}
                      loading={loading}
                    />
                    <MenuItem onClick={handleSave}>
                      {"Save"}
                    </MenuItem>
                     <SaveAsModalMenuItem
                      setName={setName}
                      handleSaveAs={handleSaveAs}
                      loading={loading}
                    />
                    <MenuItem onClick={handleDelete}>
                      {"Delete"}
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Container>
            </Flex>
        </Flex>
      </Box>
    </>
  )
}

export default BadgeBuilderNav;
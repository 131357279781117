import { type FC, useCallback } from 'react';
import { Field, type FieldProps } from 'formik';
import {
  UiFormControl,
  UiFormLabel,
  UiCheckbox,
  // UiCheckboxProps,
  UiFormErrorMessage,
  UiFormHelperText,
  UiHStack,
  UiText,
  UiVStack,
  UiStack,
  uiStyles,
  UiRadio, UiCheckboxGroup, UiRadioGroup,
} from '@/lib/ui';
import FieldContainer, { type FieldContainerProps } from './FieldContainer';

export interface CheckboxOption {
  value: string
  label: string
  description?: string
  disabled?: boolean
}

// type Data = CheckboxOption[];

export interface CheckboxListFieldProps extends Omit<FieldContainerProps, 'children' | 'placeholder'> {
  label: string
  name: string
  options: CheckboxOption[]
  isMultiple?: boolean
  helperText?: string
  isRequired?: boolean
  disabled?: boolean
}

const CheckboxListField: FC<CheckboxListFieldProps> = ({
  label,
  name,
  options = [],
  isMultiple = true,
  helperText = undefined,
  isRequired = true,
  layout = 'inline',
  disabled = false
}) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        if (isMultiple) {
          return (
            <FieldContainer
              layout={layout}
            >
              {isRequired ? (
                <UiHStack alignItems={'flex-start'} spacing={0}>
                  <UiFormLabel>{label}</UiFormLabel>
                  <UiText color={'gray.600'} variant='title'>*</UiText>
                </UiHStack>
              )
              : (
                <UiFormLabel>{label}</UiFormLabel>
              )}
              <UiVStack alignItems={'stretch'}>
                <UiCheckboxGroup defaultValue={field.value}>
                  <UiStack
                    enableDivider={true}
                    borderRadius={uiStyles.formElementBorderRadius}
                    borderWidth={'1px'}
                    borderColor={form?.errors?.[name] ? 'red.500' : 'gray.300'}
                    borderStyle={'solid'}
                    bgColor={'#fff'}
                    spacing={0}
                  >
                    {options.map((option) => {
                      return (
                        <CheckboxListItem
                          key={option.value}
                          field={field}
                          form={form}
                          option={option}
                          isMultiple={isMultiple}
                          disabled={disabled || option.disabled}
                        />
                      );
                    })}
                  </UiStack>
                </UiCheckboxGroup>
                {!!helperText && (
                  <UiText variant={'body2'} color={'text.secondary'}>
                    {helperText}
                  </UiText>
                )}
                {!!form.errors[name] && (
                  <UiText color={'red.500'} variant={'body2'}>{form.errors[name] as string}</UiText>
                )}
              </UiVStack>
            </FieldContainer>
          );
        }

        return (
          <UiFormControl isInvalid={!!form.errors[name]} flexGrow={1} as={'div'}>
            <FieldContainer
              layout={layout}
            >
              {isRequired ? (
                <UiHStack alignItems={'flex-start'} spacing={0}>
                  <UiFormLabel>{label}</UiFormLabel>
                  <UiText color={'gray.600'} variant='title'>*</UiText>
                </UiHStack>
              )
              : (
                <UiFormLabel>{label}</UiFormLabel>
              )}
              <UiVStack alignItems={'stretch'}>
                <UiRadioGroup defaultValue={field.value}>
                  <UiStack
                    enableDivider={true}
                    borderRadius={uiStyles.formElementBorderRadius}
                    borderWidth={'1px'}
                    borderColor={'gray.300'}
                    borderStyle={'solid'}
                    bgColor={'#fff'}
                    spacing={0}
                  >
                    {options.map((option) => {
                      return (
                        <CheckboxListItem
                          key={option.value}
                          field={field}
                          form={form}
                          option={option}
                          isMultiple={isMultiple}
                          disabled={disabled || option.disabled}
                        />
                      );
                    })}
                  </UiStack>
                </UiRadioGroup>
                {!!helperText && (
                  <UiFormHelperText>
                    {helperText}
                  </UiFormHelperText>
                )}
                {!!form.errors[name] && (<UiFormErrorMessage>{form.errors[name] as string}</UiFormErrorMessage>)}
              </UiVStack>
            </FieldContainer>
          </UiFormControl>
        );
      }}
    </Field>
  );
};

export interface SingleSelectProps {
  form: FieldProps['form']
  field: FieldProps['field']
  option: CheckboxOption
  isMultiple: boolean
  disabled?: boolean
}

const CheckboxListItem: FC<SingleSelectProps> = ({
  // form,
  field,
  option,
  isMultiple,
  disabled = false
}) => {
  // The checkbox will be handled by formik automatically it seems. No need to write custom function to update the field value.
  // const onChange = useCallback(
  //   (optionValue: CheckboxOption['value']) => {
  //     const values = field.value as CheckboxOption['value'][];
  //     const valueIndex = values.indexOf(optionValue);
  //     if (valueIndex >= 0) {
  //       values.splice(valueIndex, 1);
  //     } else {
  //       values.push(optionValue);
  //     }

  //     console.log(optionValue, valueIndex, values);

  //     form.setFieldValue(
  //       field.name,
  //       values,
  //     );
  //   },
  //   [form, field]
  // );

  if (isMultiple) {
    return (
      <UiCheckbox colorScheme={'primary'} {...field} value={option.value} isChecked={field.value?.indexOf(option.value) >= 0} p={4} disabled={disabled}>
        <UiText px={2}>{option.label}</UiText>
        {!!option.description && (
          <UiText px={2} variant={'body2'} color={'text.secondary'}>{option.description}</UiText>
        )}
      </UiCheckbox>
    );
  } else {
    return (
      <UiRadio colorScheme={'primary'} {...field} value={option.value} isChecked={field.value?.indexOf(option.value) >= 0} p={4} disabled={disabled}>
        <UiText px={2}>{option.label}</UiText>
        {!!option.description && (
          <UiText px={2} variant={'body2'} color={'text.secondary'}>{option.description}</UiText>
        )}
      </UiRadio>
    );
  }
};

export default CheckboxListField;

import { type FC } from 'react';

import BaseBlockList, { BlockListProps as BaseBlockListProps } from '@/base/BlockList';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';

import NoteRow from './NoteRow';
import { type AdminNote } from '@/api/admin/note';

export interface NoteListProps extends BaseBlockListProps {
  profileableType: string
  profileableId: number | null
  data: AdminNote[]
  onSaveSuccess: () => void
}

const NoteList: FC<NoteListProps> = ({
  data,
  profileableType,
  profileableId,
  onSaveSuccess,
  ...props
}) => {
  return (
    <BaseBlockList bgColor={'#fff'} {...props}>
      {data.map((note: AdminNote, index) => {
        return (
          <>
            {index !== 0 && (<BaseDividerHorizontal />)}
            <NoteRow
              key={note.id}
              profileableType={profileableType}
              profileableId={profileableId}
              note={note}
              onSaveSuccess={onSaveSuccess} />
          </>

        );
      })}
    </BaseBlockList>
  );
};

export default NoteList;

import { type FC } from 'react';
import {
  UiIconPlusCircle,
} from '@/lib/ui';
import BaseButtonIconText from '@/base/Button/IconText';
import { useDisclosure } from '@chakra-ui/react';
import BadgeForm from '@/registration/manage/RegistrationBadge/BadgeForm';

const NewBadge: FC = () => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <BaseButtonIconText
        Icon={UiIconPlusCircle}
        onClick={onToggle}
      >
        New badge
      </BaseButtonIconText>
      <BadgeForm
        // onSubmit={}
        onClose={onToggle}
        onSaveSuccess={() => {}}
        isVisible={isOpen}
      />
    </>
  );
};

export default NewBadge;

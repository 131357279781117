import { type FC } from 'react';
import {
  useQuery
} from '@tanstack/react-query';
import {
  registration
} from '@/api';
import { UiStack } from '@/lib/ui';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import { useTenantApi } from '@/account/hook/useTenantApi';
import BaseBlockList from '@/base/BlockList';
import TaxRateCard from './TaxRateCard';
import { type ApiResponse } from '@/api/tenantClient';

export interface TaxRateListProps {
  // queryParams: registration.EventListQueryRequest;
}

const TaxRateList: FC<TaxRateListProps> = () => {
  const { eventId, tenant } = useRegisterRoute();
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();

  const taxRateListQuery = useQuery<ApiResponse<registration.TaxRate>, Error>({
    queryKey: [registration.taxRateListQueryKey, { eventId }],
    queryFn: async () => {
      return await registration.loadTaxRateList(createTenantAdminApiRequest)({ eventId: Number(eventId) });
    },
    enabled: !isApiPreparing
  });

  if (tenant) {
    return (
      <UiStack
        spacing={4}
        alignItems={'stretch'}
        flexGrow={1}
      >
        <QueryContainer query={taxRateListQuery}>
          {(data) => {
            return (
              <BaseBlockList>
                {(data.items ?? []).map((taxRate) => {
                  return (
                    <TaxRateCard
                      key={taxRate.id}
                      taxRate={taxRate}
                      tenant={tenant}
                    />
                  );
                })}
              </BaseBlockList>
            );
          }}
        </QueryContainer>
      </UiStack>
    );
  }
};

export default TaxRateList;

import { type FC, useMemo } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  UiIconBrowsers,
  UiIconShoppingCart,
  UiIconIdentificationBadge,
  UiIconChartLine,
  UiIconReceiptX,
  UiIconHandCoins,
  UiIconListChecks, UiIconCreditCard, UiIconLayout, UiIconUsersThree, UiIconUserList, UiIconIdentificationCard,
} from '@/lib/ui';
import { generatePageUrl } from '@/app/pages';
import { UiIconUpload } from '@/lib/ui/Icon/UiIconUpload';
import BaseModuleMenu, { type MenuProps as BaseMenuProps, type Menu as BaseMenu } from '@/base/Layout/ModuleMenu';
dayjs.extend(utc);

// To use generatePageUrl, it must wait until the routes are initialized (meaning the function generatePageUrl has to be used inside components).
const getMenus = (tenantCode: string): BaseMenu[] => {
  return [
    // {
    //   name: 'dashboard', // The name has to be unique on the same level.
    //   label: 'Dashboard',
    //   subMenu: [
    //     {
    //       name: 'overview',
    //       label: 'Overview',
    //       url: generatePageUrl('RegistrationDashboardOverview', { tenantCode }),
    //       icon: (<UiIconInfo size={'2xl'} />)
    //     }
    //   ]
    // },
    {
      name: 'manage', // The name has to be unique on the same level.
      label: 'Manage',
      subMenu: [
        {
          name: 'events',
          label: 'Registration form',
          url: generatePageUrl('RegistrationManageEvent', { tenantCode }),
          icon: (<UiIconBrowsers size={'2xl'} />)
        },
        {
          name: 'paymentOptions',
          label: 'Payment methods',
          url: generatePageUrl('RegistrationSettingsPaymentOptions', { tenantCode }),
          icon: (<UiIconCreditCard size={'2xl'} />)
        },
        {
          name: 'financeTemplates',
          label: 'Finance templates',
          url: generatePageUrl('RegistrationSettingsFinanceTemplates', { tenantCode }),
          icon: (<UiIconLayout size={'2xl'} />)
        },
        {
          name: 'registration',
          label: 'Registration importer',
          url: generatePageUrl('RegistrationImporterRegistration', { tenantCode }),
          icon: (<UiIconUpload size={'2xl'} />)
        },
        // {
        //   name: 'registrationBadges',
        //   label: 'Registration badges',
        //   url: generatePageUrl('RegistrationBadgeManageTable', { tenantCode }),
        //   icon: (<UiIconIdentificationCard size={'2xl'} />)
        // },
      ]
    },
    {
      name: 'report', // The name has to be unique on the same level.
      label: 'Reports',
      subMenu: [
        {
          name: 'orders',
          label: 'Orders',
          url: generatePageUrl('RegistrationManageOrder', { tenantCode }),
          icon: (<UiIconShoppingCart size={'2xl'} />)
        },
        {
          name: 'registrations',
          label: 'Registrations',
          url: generatePageUrl('RegistrationManageTable', { tenantCode }),
          icon: (<UiIconIdentificationBadge size={'2xl'} />)
        },
        {
          name: 'groupRegistrations',
          label: 'Group registrations',
          url: generatePageUrl('GroupRegistrationManageTable', { tenantCode }),
          icon: (<UiIconUserList size={'2xl'} />)
        },
        // {
        //   name: 'cancelled-registrations',
        //   label: 'Cancellations',
        //   url: generatePageUrl('CancelledRegistrationManageTable', { tenantCode }),
        //   icon: (<UiIconReceiptX size={'2xl'} />)
        // },
        // {
        //   name: 'refunds',
        //   label: 'Refunds',
        //   url: generatePageUrl('RefundManageTable', { tenantCode }),
        //   icon: (<UiIconHandCoins size={'2xl'} />)
        // },
        {
          name: 'form-responses',
          label: 'Form responses',
          url: generatePageUrl('FormResponseManageTable', { tenantCode }),
          icon: (<UiIconListChecks size={'2xl'} />)
        }
      ]
    },
    {
      name: 'dashbaords', // The name has to be unique on the same level.
      label: 'Dashboards',
      subMenu: [
        {
          name: 'insight',
          label: 'Insights',
          url: generatePageUrl('RegistrationReportInsight', { tenantCode }),
          icon: (<UiIconChartLine size={'2xl'} />)
        },
        {
          name: 'orders',
          label: 'Orders',
          url: generatePageUrl('RegistrationReportOrder', { tenantCode }),
          icon: (<UiIconChartLine size={'2xl'} />)
        },
        {
          name: 'registrations',
          label: 'Registrations',
          url: generatePageUrl('RegistrationReportRegistration', { tenantCode }),
          icon: (<UiIconChartLine size={'2xl'} />)
        },
      ],
    },
  ];
};

export interface SideNavProps extends Omit<BaseMenuProps, 'menus'> {
  tenantCode: string
}

const AdminSideNav: FC<SideNavProps> = ({
  tenantCode,
  activeNodes = [],
  enableTransition = false
}) => {
  const menus = useMemo(() => {
    return getMenus(tenantCode);
  }, [tenantCode]);

  return (
    <BaseModuleMenu
      menus={menus}
      activeNodes={activeNodes}
      enableTransition={enableTransition}
    />
  );
};

export default AdminSideNav;

import BaseLayout from '@/base/Layout';
import BaseLayoutBody from '@/base/Layout/Body';
import {
  UiButton,
  UiHStack,
  UiSimpleGrid,
  UiStack,
  uiStyles,
  UiText,
} from '@/lib/ui';
import { type FC } from 'react';
import { useTenantApi } from '@/account/hook/useTenantApi';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import SmartWindow from '@/base/Layout/SmartWindow';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import AdminSideNav from '@/registration/component/Layout/AdminSideNav';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { type FinanceTemplate, loadFinanceTemplate, financeTemplateQueryKey, updateFinanceTemplate, type FinanceTemplateParams } from '@/api/admin/financeTemplate';
import BaseMessageBarInfo from '@/base/MessageBar/Info';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import Uploader from '@/base/Form/Uploader';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { Formik, Form, type FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
import { type ApiResponseSingle } from '@/api/tenantClient';
import { useGlobalToast } from '@/lib/hook';

const FinanceTemplates: FC = () => {
  const { tenant } = useTenantRoute();
  const { tenantCode } = useRegisterRoute();
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();
  const queryClient = useQueryClient();
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const { showToast } = useGlobalToast();

  const formSchema = Yup.object().shape({
    invoiceTemplate: Yup.mixed(),
    refundReceipt: Yup.mixed()
  });

  interface FormData {
    invoiceTemplate: string
    refundReceipt: string
    invoiceTemplateOriginalName: string
    refundReceiptOriginalName: string
  }

  const financeTemplateQuery = useQuery(
    [financeTemplateQueryKey],
    async () => {
      const result = await loadFinanceTemplate(createTenantAdminApiRequest)({});
      return result?.item;
    },
    { enabled: !isApiPreparing }
  );

  const { mutateAsync: mutateFinanceTemplate, isLoading } = useMutation<ApiResponseSingle<FinanceTemplate>, Error, FinanceTemplateParams>({
    mutationFn: async (data: FinanceTemplateParams) => {
      return await updateFinanceTemplate(createTenantAdminApiRequest)(data);
    },
    onSuccess: (result) => {
      if (result?.errors && result.errors?.length > 0) {
        setSaveErrors(result?.errors);
      } else {
        void queryClient.invalidateQueries({ queryKey: [financeTemplateQueryKey] });
        setSaveErrors([]);
        showToast.success('Finance templates have been successfully updated');
      }
    },
    onError: (error) => {
      setSaveErrors([error.message ?? 'Failed to save the finance templates.']);
    }
  });

  const submitForm = async (values: FormData, fileNames: { invoiceTemplateName: string, refundReceiptName: string }) => {
    await mutateFinanceTemplate({
      customInvoiceTemplate: values.invoiceTemplate,
      customRefundReceiptTemplate: values.refundReceipt,
      invoiceTemplateName: fileNames.invoiceTemplateName,
      refundReceiptName: fileNames.refundReceiptName
    });
  };

  return (
    <>
      <BaseLayout
        smartWindow={(<SmartWindow />)}
        sideMenu={(<AdminSideNav activeNodes={['settings', 'financeTemplates']} tenantCode={tenantCode} />)}
        appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard showDescription={false} />} currentAppName={'registration'} />)}
      >
        <BaseLayoutBody
          isStandalone={false}
        >
          <UiStack spacing={4} justifyContent={'flex-start'}>
            <UiStack spacing={4}>
              <UiText variant={'title'}>Default finance templates</UiText>
              <BaseMessageBarInfo borderRadius={uiStyles.borderRadius}>
                The default finance templates are used by each event by default. You can still set different finance templates per event in the registration setup.
              </BaseMessageBarInfo>
            </UiStack>
            <UiStack spacing={4} alignItems={'stretch'}>
              <QueryContainer query={financeTemplateQuery}>
                {(financeTemplate) => {
                  return (
                    <UiStack
                      borderRadius={uiStyles.borderRadius}
                      bgColor={'#fff'}
                      padding={8}
                      // borderWidth={'1px'}
                      // borderColor={'gray.300'}
                      // borderStyle={'solid'}
                      shadow={uiStyles.cardShadow}
                    >
                      <Formik
                        initialValues={{
                          invoiceTemplate: '',
                          refundReceipt: '',
                          invoiceTemplateOriginalName: '',
                          refundReceiptOriginalName: ''
                        }}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validationSchema={formSchema}
                        onSubmit={async (
                          values: FormData,
                          { setSubmitting }: FormikHelpers<FormData>
                        ) => {
                          setSubmitting(true);
                          const fileNames = {
                            invoiceTemplateName: values.invoiceTemplateOriginalName,
                            refundReceiptName: values.refundReceiptOriginalName
                          };
                          await submitForm(values, fileNames);
                          setSubmitting(false);
                        }}
                      >
                        {({ setFieldValue }) => {
                          return (
                            <Form>
                              <BaseFormFieldGroup>
                                {saveErrors.length > 0 && (
                                  <UiStack spacing={4} flexGrow={1} py={4}>
                                    {saveErrors.map((error, index) => {
                                      return (
                                        <BaseMessageBarError key={index}>
                                          {error}
                                        </BaseMessageBarError>
                                      );
                                    })}
                                  </UiStack>
                                )}
                                <UiSimpleGrid columns={{base: 1, 'xl': 2}} spacing={16}>
                                  <Uploader
                                    name="invoiceTemplate"
                                    label="Default Invoice Template"
                                    helperText='Please upload file under format: .tlf'
                                    acceptFiles={{ 'application/octet-stream': ['.tlf'] }}
                                    setFieldValue={setFieldValue}
                                    defaultTemplateLink={`${tenant?.apiEndpoint}/api/v1/registrations/download_default_template?template_name=invoice`}
                                    defaultTemplateTitle='Download default invoice template'
                                    defaultValue={financeTemplate.customInvoiceTemplate}
                                  />
                                  <Uploader
                                    name="refundReceipt"
                                    label="Default Refund Receipt"
                                    helperText='Please upload file under format: .tlf'
                                    acceptFiles={{ 'application/octet-stream': ['.tlf'] }}
                                    setFieldValue={setFieldValue}
                                    defaultTemplateLink={`${tenant?.apiEndpoint}/api/v1/registrations/download_default_template?template_name=refund_receipt`}
                                    defaultTemplateTitle='Download default refund receipt template'
                                    defaultValue={financeTemplate.customRefundReceiptTemplate}
                                  />
                                </UiSimpleGrid>
                              </BaseFormFieldGroup>
                              <UiStack flexDirection={'row-reverse'} pt={12}>
                                <UiButton
                                  px={8}
                                  size={'lg'}
                                  shadow={'base'}
                                  colorScheme={'primary'}
                                  type={'submit'}
                                  isLoading={isLoading}
                                  disabled={isLoading}
                                >
                                  Save
                                </UiButton>
                              </UiStack>
                            </Form>
                          );
                        }}
                      </Formik>
                    </UiStack>
                  );
                }}
              </QueryContainer>
            </UiStack>
          </UiStack>
        </BaseLayoutBody>
      </BaseLayout>
    </>
  );
};

export default FinanceTemplates;

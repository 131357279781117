import {
  type registration
} from '@/api';
import BaseBlockList from '@/base/BlockList';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { UiHStack, UiSpinner, UiStack, UiVStack } from '@/lib/ui';
// import { useBadgeListQuery } from '@/registration/hook/useBadgeListQuery';
import React, { useEffect, useMemo, useState, type FC } from 'react';
import BadgeRow from './BadgeRow';
import Paginate from '@/base/Paginate/Paginate';
import BaseLoadingSpinner from '@/base/Loading/Spinner';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useQuery } from '@tanstack/react-query';
import type { ApiResponse } from '@/api/tenantClient';
import {
  loadRegistrationBadgesForTable,
  RegistrationBadgeTableRow,
  registrationTableQueryKey,
} from '@/api/registration';

export interface BadgeListProps {
  // queryParams: registration.RegistrationTableQueryRequest
  searchKey?: string
}

export interface PaginationParams {
  pageIndex: number
  pageSize: number
}

export interface BadgesListQueryParams {
  pagination: {
    pageIndex: number
    pageSize: number
  }
  searchKey?: string
}

const useBadgesListQuery = (params: BadgesListQueryParams) => {
  const { createTenantAdminApiRequest, isLoading: isTenantLoading } = useTenantApi();
  return useQuery<ApiResponse<RegistrationBadgeTableRow>, Error>({
    queryKey: [registrationTableQueryKey, params],
    queryFn: async (): Promise<ApiResponse<RegistrationBadgeTableRow>> => {
      return await loadRegistrationBadgesForTable(createTenantAdminApiRequest)({
        page: params.pagination.pageIndex,
        size: params.pagination.pageSize,
        type: 'all',
        filters: {},
        // sorting,
        search: params.searchKey ?? '',
      });
    },
    keepPreviousData: true,
    // staleTime: 30_000,
    enabled: !isTenantLoading,
  });
};

const BadgeList: FC<BadgeListProps> = ({
  // queryParams,
  searchKey = ''
}) => {
  const [selectedEventId, setSelectedEventId] = useState<string>();
  const [pagination, setPagination] = useState<any>({ pageIndex: 0, pageSize: 10 });
  // isFetching, refetch
  const { data, isLoading, isError } = useBadgesListQuery({ pagination, searchKey, });

  // console.log('----', data);
  useEffect(() => {
    setPagination({ ...pagination, pageIndex: 0 });
  }, [searchKey]);

  const badgeList = useMemo(() => {
    return data?.items ?? [];
  }, [data?.items,]);

  const pageCount = useMemo(() => {
    const total = data?.pagination?.total ?? 0;
    return Math.ceil(total / pagination.pageSize);
  }, [data?.pagination?.total, pagination.pageSize]);

  const currentPage = useMemo(() => {
    const page = data?.pagination?.page;
    if (page) {
      return page - 1;
    }
    return pagination.page;
  }, [data?.pagination?.page, pagination.page]);

  if (isLoading && !data) {
    return (
      <BaseLoadingSpinner/>
    );
  }
  if (isError) {
    return (
      <BaseMessageBarError>
        Failed to load the event list. Please try to refresh the page.
      </BaseMessageBarError>
    );
  }

  return (
    <UiStack alignItems={'stretch'} spacing={4}>
      <BaseBlockList>
        {badgeList?.map((badge) => {
          return (
            <BadgeRow
              key={badge.id}
              data={badge}
              // onClick={() => { setSelectedEventId(event.id); }}
            />
          );
        })}
      </BaseBlockList>
      <UiHStack justifyContent={'flex-end'}>
        <Paginate
          onPageChange={(value) => {
            setPagination({ ...pagination, pageIndex: value.selected });
          }}
          pageCount={pageCount}
          currentPage={currentPage}
        />
      </UiHStack>
    </UiStack>
  );
};

export default BadgeList;

import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { registration as registrationAPI } from '@/api';
import { type RefundTableRow } from '@/api/registration';
import { type DatatableSelectOptions } from '@/api/tenantClient';
import { useAdminAuth } from '@/app/ProviderAdminAuth';
import { UiButton, UiHStack } from '@/lib/ui';
import { useGlobalToast } from '@/lib/hook';
import { useDataTableOptions } from '@/registration/hook/useDataTableOptions';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import {
  MantineReactTable,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
  useMantineReactTable,
  type MRT_ColumnDef
} from 'mantine-react-table';
import type React from 'react';
import { useCallback, useMemo, type FC } from 'react';
import { useNavigate } from 'react-router-dom';

dayjs.extend(advancedFormat);

interface DatatableProps {
  data: RefundTableRow[]
  isLoading: boolean
  isError: boolean
  isFetching: boolean
  refetch: () => void
  filters: MRT_ColumnFiltersState
  setFilters: React.Dispatch<React.SetStateAction<MRT_ColumnFiltersState>>
  sorting: MRT_SortingState
  setSorting: React.Dispatch<React.SetStateAction<MRT_SortingState>>
  search: string
  setSearch: (searchText: string) => void
  pagination: MRT_PaginationState
  setPagination: React.Dispatch<React.SetStateAction<MRT_PaginationState>>
  rowCount: number
  delegateTypeSelectOptions: DatatableSelectOptions
  sessionSelectOptions: DatatableSelectOptions
}

const Datatable: FC<DatatableProps> = ({
  data, isLoading, isError, isFetching, refetch, filters, setFilters, sorting, setSorting, pagination, setPagination, search, setSearch,
  rowCount, delegateTypeSelectOptions, sessionSelectOptions
}) => {
  const navigate = useNavigate();
  const { tenantCode } = useRegisterRoute();
  const { createTenantAdminApiRequest } = useTenantApi();
  const { adminAuth } = useAdminAuth();
  const { reportToGlobal } = useApiErrorHandler();
  const dataTableOptions = useDataTableOptions<RefundTableRow>();
  const { showToast } = useGlobalToast();

  const { mutate: exportEmailAsCSV, isLoading: isExportCSVLoading } = useMutation<{}, Error>({
    mutationFn: async () => {
      return await registrationAPI.exportEmailAsCSV(createTenantAdminApiRequest)({
        model: 'Refund',
        email: adminAuth.user!.email
      });
    },
    onError: (error) => {
      reportToGlobal(error);
    }
  });

  const onExportEmailAsCSV = useCallback(() => {
    showToast.success('CSV email sent!');
    return exportEmailAsCSV();
  }, [exportEmailAsCSV]);

  const columns = useMemo<Array<MRT_ColumnDef<RefundTableRow>>>(
    () => {
      return [
        {
          accessorKey: 'event.name',
          header: 'Event'
        },
        {
          accessorKey: 'attendeeCategory.name',
          header: 'Attendee group'
        },
        {
          accessorKey: 'discountCode',
          header: 'Discount code'
        },
        {
          accessorKey: 'attendee.name',
          header: 'Name'
        },
        {
          accessorKey: 'attendee.email',
          header: 'Email'
        },
        {
          accessorKey: 'country',
          header: 'Country'
        },
        {
          accessorKey: 'delegateType.name',
          header: 'Registration type',
          filterVariant: 'multi-select',
          mantineFilterSelectProps: {
            data: delegateTypeSelectOptions
          }
        },
        {
          accessorKey: 'session.name',
          header: 'Tickets',
          filterVariant: 'multi-select',
          mantineFilterSelectProps: {
            data: sessionSelectOptions
          }
        },
        {
          accessorKey: 'refundAmountFormat',
          header: 'Refund amount',
          Cell: ({ cell }) => {
            return cell.getValue() as string;
          },
          filterVariant: 'range',
          filterFn: 'betweenInclusive',
        }
      ];
    },
    []
  );

  const handleClearFilters = () => {
    setFilters([]);
    setPagination({ pageIndex: 0, pageSize: 10 });
    setSearch('');
    setSorting([]);
  };

  const table = useMantineReactTable({
    columns,
    data,
    initialState: {
      showColumnFilters: true,
      showGlobalFilter: !!search,
    },
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    onColumnFiltersChange: setFilters,
    onSortingChange: setSorting,
    onGlobalFilterChange: setSearch,
    onPaginationChange: setPagination,
    rowCount,
    state: {
      columnFilters: filters,
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isFetching,
      sorting,
      globalFilter: search,
      pagination
    },
    displayColumnDefOptions: { 'mrt-row-actions': { size: 96 } },
    enableRowActions: false,
    positionActionsColumn: 'last',
    globalFilterFn: 'contains',
    renderTopToolbarCustomActions: () => {
      return (
        <UiHStack
          spacing={8}
          flexGrow={1}
          justifyContent={'flex-end'}
          px={8}
        >
          <UiButton
            px={0}
            size={'sm'}
            variant={'ghost'}
            colorScheme={'primary'}
            onClick={onExportEmailAsCSV}
            isLoading={isExportCSVLoading}>
            Email as CSV
          </UiButton>
          <UiButton
            px={0}
            size={'sm'}
            variant={'ghost'}
            colorScheme={'primary'}
            onClick={handleClearFilters}>
            Clear all filters
          </UiButton>
          <UiButton
            px={0}
            size={'sm'}
            variant={'ghost'}
            colorScheme={'primary'}
            onClick={refetch}>
            Apply filter
          </UiButton>
        </UiHStack>
      );
    },
    ...dataTableOptions
  });

  return <MantineReactTable table={table} />;
};

export default Datatable;

import type React from 'react';
import { type FC, useCallback, useMemo } from 'react';
import {
  MantineReactTable,
  type MRT_VisibilityState,
  useMantineReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_SortingState,
  type MRT_PaginationState
} from 'mantine-react-table';
import dayjs, { type Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { registration as registrationAPI } from '@/api';
import { UiBadge, UiButton, UiHStack, UiIconArrowRight, UiStack, uiStyles, UiTag } from '@/lib/ui';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { type SerializedRegistrationTableRow } from './RegistrationTable';
import { type DatatableSelectOptions } from '@/api/tenantClient';
import { useNavigate } from 'react-router-dom';
import { generatePageUrl } from '@/app/pages';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useAdminAuth } from '@/app/ProviderAdminAuth';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { useMutation } from '@tanstack/react-query';
import { useDataTableOptions } from '@/registration/hook/useDataTableOptions';
import { PermissionAction, PermissionDomain } from '@/api/constant/adminUserPermission';
import BaseAclContainer from '@/account/component/AclContainer';
import { useGlobalToast } from '@/lib/hook';
import { Link } from '@chakra-ui/react';

dayjs.extend(advancedFormat);
dayjs.extend(timezone);

interface DatatableProps {
  data: SerializedRegistrationTableRow[]
  isLoading: boolean
  isError: boolean
  isFetching: boolean
  refetch: () => void
  filters: MRT_ColumnFiltersState
  setFilters: React.Dispatch<React.SetStateAction<MRT_ColumnFiltersState>>
  sorting: MRT_SortingState
  setSorting: React.Dispatch<React.SetStateAction<MRT_SortingState>>
  search: string
  setSearch: (searchText: string) => void
  pagination: MRT_PaginationState
  setPagination: React.Dispatch<React.SetStateAction<MRT_PaginationState>>
  rowCount: number
  columnVisibility: MRT_VisibilityState
  setColumnVisibility: React.Dispatch<React.SetStateAction<MRT_VisibilityState>>
  delegateTypeSelectOptions: DatatableSelectOptions
  booleanSelectOptions: DatatableSelectOptions
  statusSelectOptions: DatatableSelectOptions
  workshopTicketsSelectOptions: DatatableSelectOptions
  functionTicketsSelectOptions: DatatableSelectOptions
  tourTicketsSelectOptions: DatatableSelectOptions
  attendeeCategorySelectOptions: DatatableSelectOptions
  paymentSelectOptions: DatatableSelectOptions
}

const Datatable: FC<DatatableProps> = ({
  data, isLoading, isError, isFetching, refetch, filters, setFilters, sorting, setSorting, pagination, setPagination, search, setSearch,
  rowCount, delegateTypeSelectOptions, booleanSelectOptions, statusSelectOptions, paymentSelectOptions, workshopTicketsSelectOptions, functionTicketsSelectOptions, tourTicketsSelectOptions, attendeeCategorySelectOptions, columnVisibility, setColumnVisibility
}) => {
  const navigate = useNavigate();
  const { showToast } = useGlobalToast();
  const { tenantCode, tenant } = useRegisterRoute();
  const { createTenantAdminApiRequest } = useTenantApi();
  const { adminAuth } = useAdminAuth();
  const { reportToGlobal } = useApiErrorHandler();
  const dataTableOptions = useDataTableOptions<SerializedRegistrationTableRow>();

  const { mutate: exportEmailAsCSV, isLoading: isExportCSVLoading } = useMutation<{}, Error>({
    mutationFn: async () => {
      return await registrationAPI.exportEmailAsCSV(createTenantAdminApiRequest)({
        model: 'Registration',
        email: adminAuth.user!.email
      });
    },
    onError: (error) => {
      reportToGlobal(error);
    }
  });

  const onExportEmailAsCSV = useCallback(() => {
    showToast.success('CSV email sent!');
    return exportEmailAsCSV();
  }, [exportEmailAsCSV]);

  const columns = useMemo<Array<MRT_ColumnDef<SerializedRegistrationTableRow>>>(
    () => {
      return [
        {
          accessorKey: 'id',
          header: 'ID'
        },
        {
          accessorKey: 'orderId',
          header: 'Order id',
          Cell: ({ cell }) => {
            const value = cell.getValue() as string;

            if (value === 'No order') {
              return (
                <UiTag colorScheme='red'>
                  No order
                </UiTag>
              );
            } else {
              return (
                <UiTag>{value}</UiTag>
              );
            }
          }
        },
        {
          accessorKey: 'idInfo',
          header: 'ID info'
        },
        {
          accessorKey: 'event.name',
          header: 'Event'
        },
        {
          accessorKey: 'user.fullName',
          header: 'Name'
        },
        {
          accessorKey: 'user.email',
          header: 'Email'
        },
        {
          accessorKey: 'lastPageVisit',
          header: 'Last page visit',
          Cell: ({ cell }) => {
            const value = cell.getValue() as string;
            const formattedValue = value.replace(/Registration/g, '').replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^\w/, c => c.toUpperCase());
            return formattedValue;
          }
        },
        {
          accessorKey: 'paymentOption',
          header: 'Payment option',
          filterVariant: 'select',
          mantineFilterSelectProps: {
            data: paymentSelectOptions
          }
        },
        {
          accessorKey: 'delegateType.name',
          header: 'Registration type',
          filterVariant: 'select',
          mantineFilterSelectProps: {
            data: delegateTypeSelectOptions
          }
        },
        {
          accessorKey: 'attendeeCategory.name',
          header: 'Attendee group',
          filterVariant: 'select',
          size: 300,
          mantineFilterSelectProps: {
            data: attendeeCategorySelectOptions
          },
          enableSorting: false,
        },
        {
          accessorKey: 'ticket.function',
          header: 'Function tickets',
          filterVariant: 'multi-select',
          size: 300,
          mantineFilterSelectProps: {
            data: functionTicketsSelectOptions
          },
          enableSorting: false,
          Cell: ({ cell }) => {
            const tickets = cell.getValue() as Array<{ name: string, quantity: number, type: string }>;
            return (
              <>
                {tickets.map((ticket, index) => {
                  return (
                    <UiBadge key={index} colorScheme="blue" mr={1}>
                      {`${ticket.name}`}
                    </UiBadge>
                  );
                })}
              </>
            );
          }
        },
        {
          accessorKey: 'ticket.workshop',
          header: 'Workshop tickets',
          filterVariant: 'multi-select',
          size: 300,
          enableSorting: false,
          mantineFilterSelectProps: {
            data: workshopTicketsSelectOptions
          },
          Cell: ({ cell }) => {
            const tickets = cell.getValue() as Array<{ name: string, quantity: number, type: string }>;
            return (
              <>
                {tickets.map((ticket, index) => {
                  return (
                    <UiBadge key={index} colorScheme="blue" mr={1}>
                      {`${ticket.name}`}
                    </UiBadge>
                  );
                })}
              </>
            );
          }
        },
        {
          accessorKey: 'ticket.tour',
          header: 'Tour tickets',
          filterVariant: 'multi-select',
          size: 300,
          enableSorting: false,
          mantineFilterSelectProps: {
            data: tourTicketsSelectOptions
          },
          Cell: ({ cell }) => {
            const tickets = cell.getValue() as Array<{ name: string, quantity: number, type: string }>;
            return (
              <>
                {tickets.map((ticket, index) => {
                  return (
                    <UiBadge key={index} colorScheme="blue" mr={1}>
                      {`${ticket.name}`}
                    </UiBadge>
                  );
                })}
              </>
            );
          }
        },
        {
          accessorKey: 'status',
          header: 'Status',
          filterVariant: 'select',
          mantineFilterSelectProps: {
            data: statusSelectOptions
          }
        },
        {
          accessorKey: 'abandonedCart',
          header: 'Abandoned cart',
          filterVariant: 'select',
          mantineFilterSelectProps: {
            data: booleanSelectOptions
          },
          Cell: ({ cell }) => {
            if (cell.getValue() === null) return;

            if (cell.getValue()) {
              return (
                <UiTag colorScheme='red'>
                  Yes
                </UiTag>
              );
            } else if (!cell.getValue()) {
              return (
                <UiTag colorScheme='gray'>
                  No
                </UiTag>
              );
            }
          }
        },
        {
          accessorKey: 'isGroupRegistration',
          header: 'Is group rego',
          filterVariant: 'select',
          mantineFilterSelectProps: {
            data: booleanSelectOptions
          },
          Cell: ({ cell }) => {
            if (cell.getValue() === null) return;

            if (cell.getValue()) {
              return (
                <UiTag colorScheme='green'>
                  Yes
                </UiTag>
              );
            } else if (!cell.getValue()) {
              return (
                <UiTag colorScheme='gray'>
                  No
                </UiTag>
              );
            }
          }
        },
        {
          accessorKey: 'registeredBy',
          header: 'Registered by',
        },
        {
          accessorKey: 'groupCoordinatorName',
          header: 'Group coordinator’s name',
          size: 320,
        },
        {
          accessorKey: 'groupCoordinatorCompany',
          header: 'Group coordinator’s company',
          size: 320,
        },
        {
          accessorKey: 'createdAt',
          header: 'Created at date',
          id: 'createdAtDate',
          Cell: ({ cell, row }) => {
            return cell.getValue() ? dayjs.tz(cell.getValue() as string | number | Date | Dayjs, row.original.event.timeZoneName).format('Do MMMM') : '';
          },
          filterVariant: 'date-range'
        },
        {
          accessorKey: 'createdAt',
          header: 'Created at time',
          id: 'createdAtTime',
          Cell: ({ cell, row }) => {
            return cell.getValue() ? dayjs.tz(cell.getValue() as string | number | Date | Dayjs, row.original.event.timeZoneName).format('hh:mm A Z') : '';
          },
          enableColumnFilter: false,
          enableSorting: false,
        },
        {
          accessorKey: 'confirmDelegateTypeAt',
          header: 'Completed at date',
          id: 'completedAtDate',
          Cell: ({ cell, row }) => {
            return cell.getValue() ? dayjs.tz(cell.getValue() as string | number | Date | Dayjs, row.original.event.timeZoneName).format('Do MMMM') : '';
          },
          filterVariant: 'date-range'
        },
        {
          accessorKey: 'confirmDelegateTypeAt',
          header: 'Completed at time',
          id: 'completedAtTime',
          Cell: ({ cell, row }) => {
            return cell.getValue() ? dayjs.tz(cell.getValue() as string | number | Date | Dayjs, row.original.event.timeZoneName).format('hh:mm A Z') : '';
          },
          enableColumnFilter: false,
          enableSorting: false,
        },
        {
          accessorKey: 'idPhotocopy',
          header: 'Photocopy',
          enableColumnFilter: false,
          enableSorting: false,
          Cell: ({ cell }) => {
            const { url, filename } = cell.getValue() as { url: string, filename: string };
            return url ? (<Link href={url} target="_blank" rel="">{filename}</Link>) : '';
          }
        }
      ];
    },
    []
  );

  const toRegistrationInfoPage = (registrationId: number) => {
    navigate(generatePageUrl('RegistrationManageInfo', { tenantCode, registrationId: registrationId.toString() }));
  };

  const handleClearFilters = () => {
    setFilters([]);
    setPagination({ pageIndex: 0, pageSize: 10 });
    setSearch('');
    setSorting([]);
  };

  const table = useMantineReactTable({
    columns,
    data,
    initialState: {
      showColumnFilters: true,
      showGlobalFilter: !!search,
      columnVisibility
    },
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    onColumnFiltersChange: setFilters,
    onSortingChange: setSorting,
    onGlobalFilterChange: setSearch,
    onPaginationChange: setPagination,
    onColumnVisibilityChange: setColumnVisibility,
    rowCount,
    state: {
      columnFilters: filters.map((filter) => {
        if (['createdAtDate', 'completedAtDate'].includes(filter.id)) {
          return {
            ...filter,
            value: (filter.value as Array<string | number | Date | dayjs.Dayjs | null | undefined>).map((date) => {
              if (!date) return '';
              return new Date(date as string);
            })
          };
        }
        return filter;
      }),
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isFetching,
      sorting,
      globalFilter: search,
      pagination,
      columnVisibility
    },
    displayColumnDefOptions: { 'mrt-row-actions': { size: 96 } },
    enableRowActions: true,
    positionActionsColumn: 'last',
    globalFilterFn: 'contains',
    renderTopToolbarCustomActions: () => {
      return (
        <UiHStack
          spacing={8}
          flexGrow={1}
          justifyContent={'flex-end'}
          px={8}
        >
          <UiButton
            px={0}
            size={'sm'}
            variant={'ghost'}
            colorScheme={'primary'}
            onClick={onExportEmailAsCSV}
            isLoading={isExportCSVLoading}>
            Email as CSV
          </UiButton>
          <UiButton
            px={0}
            size={'sm'}
            variant={'ghost'}
            colorScheme={'primary'}
            onClick={handleClearFilters}>
            Clear all filters
          </UiButton>
          <UiButton
            px={0}
            size={'sm'}
            variant={'ghost'}
            colorScheme={'primary'}
            onClick={refetch}>
            Apply filter
          </UiButton>
        </UiHStack>
      );
    },
    renderRowActions: ({ row }) => {
      return (
        <BaseAclContainer tenantId={tenant?.id ?? 0} permissionsRequired={[[PermissionDomain.Registration, PermissionAction.Write]]}>
          <UiHStack
            px={0}
            justifyContent={'flex-start'}
            onClick={() => { return toRegistrationInfoPage(row.original.id); }}
            {...uiStyles.hover}
          >
            <UiIconArrowRight size={'2xl'} color={'primary.500'} />
          </UiHStack>
        </BaseAclContainer>
      );
    },
    ...dataTableOptions
  });

  return <MantineReactTable table={table} />;
};

export default Datatable;

import {
  type JSONTemplate
} from 'state/types/types';
import { ApiRequest } from '../globalClient';
import { ApiResponse, properCallApi } from "@/api/tenantClient";

export const badgeTemplatesQueryKey = 'registration.badgeTemplates';
export interface BadgeTemplate {
  id: number;
  name: string;
  template: JSONTemplate;
  html: string;
  active: boolean;
}

export interface BadgeTemplateData {
  success?: boolean;
  badgeTemplate?: BadgeTemplate;
  error_message?: string;
}

export interface BadgeTemplatesData {
  success?: boolean;
  badgeTemplates?: BadgeTemplate[];
  tags?: any,
  error_message?: string;
}

export function getbBadgeTemplates(createTenantAdminApiRequest: () => ApiRequest) {
  const request = createTenantAdminApiRequest();
  return async (): Promise<ApiResponse<BadgeTemplatesData>> => {
    request.method = 'GET';
    request.endpoint.path = `/api/v1/admin/badge_templates`;
    return await properCallApi<ApiResponse<BadgeTemplatesData>>(request);
  };
}
import BaseLayout from '@/base/Layout';
import BaseLayoutBody from '@/base/Layout/Body';
import {
  UiStack, uiStyles,
  UiText,
} from '@/lib/ui';
import { type FC } from 'react';
import { useTenantApi } from '@/account/hook/useTenantApi';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import BaseBlockList from '@/base/BlockList';
import SmartWindow from '@/base/Layout/SmartWindow';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import AdminSideNav from '@/registration/component/Layout/AdminSideNav';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useQuery } from '@tanstack/react-query';
import Invoice from './Invoice';
import Online from './Online';
import { loadPaymentOptionsDefault, paymentOptionDefaultQueryKey } from '@/api/admin/paymentOption';
import BaseMessageBarInfo from '@/base/MessageBar/Info';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';

const PaymentOption: FC = () => {
  const { tenantCode } = useRegisterRoute();
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();

  const paymentOptionQuery = useQuery(
    [paymentOptionDefaultQueryKey],
    async () => {
      const result = await loadPaymentOptionsDefault(createTenantAdminApiRequest)({ tenantCode });
      return result.items;
    },
    { enabled: !isApiPreparing }
  );

  return (
    <>
      <BaseLayout
        smartWindow={(<SmartWindow />)}
        sideMenu={(<AdminSideNav activeNodes={['settings', 'paymentOptions']} tenantCode={tenantCode} />)}
        appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard showDescription={false} />} currentAppName={'registration'} />)}
      >
        <BaseLayoutBody
          isStandalone={false}
        >
          <UiStack spacing={4} justifyContent={'flex-start'}>
            <UiStack spacing={4}>
              <UiText variant={'title'}>Default payment methods</UiText>
              <BaseMessageBarInfo borderRadius={uiStyles.borderRadius}>
                The default payment methods are used by each event by default. You can still set different payment methods per event in the registration setup.
              </BaseMessageBarInfo>
              {/* <UiText variant={'body2'} color={'text.secondary'}>The default payment methods are used by each event by default. You can still set different payment methods per event in the registration setup.</UiText> */}
            </UiStack>
            <UiStack spacing={4} alignItems={'stretch'}>
              <QueryContainer query={paymentOptionQuery}>
                {(paymentOptions) => {
                  return (
                    <BaseBlockList>
                      <Online paymentOptions={paymentOptions} />
                      <Invoice paymentOptions={paymentOptions} />
                    </BaseBlockList>
                  );
                }}
              </QueryContainer>
            </UiStack>
          </UiStack>
        </BaseLayoutBody>
      </BaseLayout>
    </>
  );
};

export default PaymentOption;

import { type ApiResponse, callApi, type ApiRequest } from '../tenantClient';

export interface ICurrency {
  id: number
  code: string
  eventId: number
  createdAt: string
  updatedAt: string
}

export interface IMutateCurrencyParams {
  eventId: number | string
  currencyCode: string // e.g. AUD
  currencyId?: number | string
}

export interface IMutateCurrencyResponse {
  id: number
  code: string
  eventId: number
  createdAt: string
  updatedAt: string
}

export type CurrencyOptionsType = string;

export const currencyListQueryKey = 'currencyListQueryKey';
export const currencyOptionsQueryKey = 'currencyOptionsQueryKey';

export const loadCurrencyList = (createTenantAdminRequest: () => ApiRequest) => {
  return async ({ eventId }: { eventId: number }): Promise<ApiResponse<ICurrency>> => {
    const request = createTenantAdminRequest();
    request.method = 'GET';
    request.endpoint.path = '/api/v1/admin/currencies';
    request.endpoint.query = {
      event_id: eventId,
    };
    return await callApi(request);
  };
};

export const loadCurrencyOptions = (createTenantAdminRequest: () => ApiRequest) => {
  return async (): Promise<ApiResponse<CurrencyOptionsType>> => {
    const request = createTenantAdminRequest();
    request.method = 'GET';
    request.endpoint.path = '/api/v1/admin/currencies/currency_options';
    return await callApi(request);
  };
};

export const mutateCurrency = (createTenantAdminRequest: () => ApiRequest) => {
  const request = createTenantAdminRequest();
  return async (params: IMutateCurrencyParams): Promise<ApiResponse<IMutateCurrencyResponse>> => {
    if (!params.currencyId) {
      request.method = 'POST';
      request.endpoint.path = '/api/v1/admin/currencies';
      request.payload = {
        currency: {
          code: params.currencyCode,
          eventId: params.eventId,
        }
      };
    } else {
      request.method = 'PUT';
      request.endpoint.path = `/api/v1/admin/currencies/${params.currencyId}`;
      request.payload = {
        currency: {
          code: params.currencyCode,
        }
      };
    }
    return await callApi(request);
  };
};

export const deleteCurrency = (createTenantAdminRequest: () => ApiRequest) => {
  const request = createTenantAdminRequest();
  return async ({ id, eventId }: { id: number, eventId: number | string }): Promise<ApiResponse<null>> => {
    request.method = 'DELETE';
    request.endpoint.path = `/api/v1/admin/currencies/${id}`;
    request.payload = {
      eventId,
    };
    return await callApi(request);
  };
};

import { type FC, useEffect, useMemo, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import { IconButton, useDisclosure } from '@chakra-ui/react';
import {
  AddIcon,
  EditIcon,
  ExternalLinkIcon,
  HamburgerIcon,
  RepeatIcon,
} from '@chakra-ui/icons';
import { type OnChangeValue } from 'chakra-react-select';
import {
  UiBox,
  UiButton,
  UiDivider,
  UiGrid,
  UiGridItem,
  UiHStack,
  UiIconCaretLeft,
  UiIconCheck,
  UiIconCheckSquare,
  UiIconFloppyDisk,
  UiIconFolders,
  UiIconPlus,
  UiIconPlusCircle,
  UiIconX,
  UiMenu,
  UiMenuButton,
  UiMenuItem,
  UiMenuList,
  UiModal,
  UiModalBody,
  UiModalCloseButton,
  UiModalContent,
  UiModalFooter,
  UiModalOverlay,
  UiStack,
  uiStyles,
  UiText,
} from '@/lib/ui';
import BaseRouterLink from '@/base/Router/Link';
import { generatePageUrl } from '@/app/pages';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import EmailEditor, { type EditorRef } from 'react-email-editor';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  activateEmailTemplate,
  createEmailTemplate,
  deleteEmailTemplate,
  type IEmailTemplatePayload,
  loadEmailTemplate,
  updateEmailTemplate,
} from '@/api/registration/automatedEmail';
import BaseSimpleSelectElement, { type Option as SelectElementOption } from '@/base/Form/SimpleSelectElement';
import BaseFormInputField from '@/base/Form/InputField';
import { useGlobalToast } from '@/lib/hook';
import defaultTemplate from './template.json';
import { MergeTags, type JSONTemplate } from './MailBuilder';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import BaseButtonIconText from '@/base/Button/IconText';
import { UiIconCheckBoxCircle } from '@/base/FormBuilder/UiIconCheckBoxCircle';

enum SaveEmailType {
  SAVE = 'save',
  SAVE_AS = 'save_as',
  NEW = 'new',
  DELETE = 'delete',
  ACTIVATE = 'activate',
}

interface CancelButtonProps {
  tenantCode: string
  eventId: string
}

interface ICreateTemplatePayload extends IEmailTemplatePayload {
  name: string
  createBlank: boolean
}

// const CancelButton: FC<CancelButtonProps> = ({ tenantCode, eventId }) => {
//   return (
//     <BaseRouterLink
//       to={generatePageUrl('RegistrationBuildEventAutomatedEmail', { tenantCode, eventId })}
//     >
//       <UiHStack {...uiStyles.hover}>
//         <UiIconCaretLeft color={'primary.500'} size={'2xl'} />
//       </UiHStack>
//     </BaseRouterLink>
//   );
// };

const templateQueryKey = 'mailTemplate';

const emailTemplateActions = {
  [SaveEmailType.NEW]: 'New template',
  [SaveEmailType.SAVE]: 'Save',
  [SaveEmailType.SAVE_AS]: 'Save as',
  [SaveEmailType.DELETE]: 'Delete',
  [SaveEmailType.ACTIVATE]: 'Make template active',
};

const ACTION_TEMPLATE_LABEL = {
  [SaveEmailType.NEW]: 'Creating new',
  [SaveEmailType.SAVE]: 'Saving',
  [SaveEmailType.SAVE_AS]: 'Saving',
  [SaveEmailType.DELETE]: 'Deleting',
  [SaveEmailType.ACTIVATE]: 'Activating',
};

export interface EmailDesignBodyProps {
  emailId: number
  emailType: string
}

const EmailDesignBody: FC<EmailDesignBodyProps> = ({
  emailId,
  emailType,
}) => {
  const queryClient = useQueryClient();
  const { showToast } = useGlobalToast();
  const [ activeTemplateId, setActiveTemplateId ] = useState<number|null>(null);
  const [saveType, setSaveType] = useState<SaveEmailType>(SaveEmailType.SAVE);
  const [selectedEmailTemplateId, setSelectedEmailTemplateId] = useState<number | null>(null);
  const { isOpen: isMenuOpen, onToggle: onMenuToggle, onClose: onMenuClose } = useDisclosure();
  const { tenantCode, eventId } = useRegisterRoute();
  const emailEditorRef = useRef<EditorRef>(null);
  const [ready, setReady] = useState(false);
  const { createTenantAdminApiRequest, isLoaded } = useTenantApi();
  const [tags, setTags] = useState<MergeTags>();
  const [signature, setSignature] = useState<string>();
  const [tenantId, setTenantId] = useState<string>();

  const handleLoadingToast = () => {
    const templateName = emailTemplateOptions.find(({ value }) => { return value === selectedEmailTemplateId; })?.label;
    showToast.loading(`${ACTION_TEMPLATE_LABEL[saveType]} email template ${templateName}`);
  };

  // Load email templates for the current email.
  const { data, isSuccess } = useQuery({
    queryKey: [templateQueryKey, { emailId, eventId }],
    queryFn: async () => {
      if (!emailId || !eventId) {
        return null;
      }
      return await loadEmailTemplate(createTenantAdminApiRequest)({
        emailId,
        eventId,
      });
    },
    enabled: isLoaded && !!emailId,
  });

  const { mutate: updateEmailTemplateMutate } = useMutation({
    mutationFn: async (params: IEmailTemplatePayload) => {
      if (!selectedEmailTemplateId) {
        return null;
      }
      return await updateEmailTemplate(createTenantAdminApiRequest)({
        emailTemplateId: selectedEmailTemplateId,
        payload: params,
      });
    },
    onMutate: handleLoadingToast,
    onSuccess: (res) => {
      if ((res?.errors?.length ?? 0) > 0) {
        showToast.error(String(res?.errors?.[0] ?? 'Something went wrong'));
        return;
      }
      void queryClient.invalidateQueries({ queryKey: [templateQueryKey, { emailId, eventId }] });
      showToast.success('Email template updated successfully');
    }
  });

  const { mutate: createOrSaveAsEmailTemplateMutate, isLoading: isCreateLoading } = useMutation({
    mutationFn: async (payload: ICreateTemplatePayload) => {
      if (!emailId) return;
      return await createEmailTemplate(createTenantAdminApiRequest)({
        eventId,
        emailId,
        ...payload,
      });
    },
    onSuccess: (res) => {
      if (((res?.errors ?? []).length) > 0) {
        showToast.error(String(res?.errors?.[0] ?? 'Something went wrong'));
        return;
      }
      void queryClient.invalidateQueries({ queryKey: [templateQueryKey, { emailId, eventId }] });
      setSelectedEmailTemplateId(res?.item?.id ?? selectedEmailTemplateId);
      showToast.success('Email template created successfully');
      onMenuClose();
    }
  });

  const { mutate: deleteTemplateMutate } = useMutation({
    mutationFn: async () => {
      if (!selectedEmailTemplateId) {
        return null;
      }
      return await deleteEmailTemplate(createTenantAdminApiRequest)({ emailTemplateId: selectedEmailTemplateId });
    },
    onMutate: handleLoadingToast,
    onSuccess: (res) => {
      if (((res?.errors ?? []).length) > 0) {
        showToast.error(String(res?.errors?.[0] ?? 'Something went wrong'));
        return;
      }
      const [defaultOption] = emailTemplateOptions;

      setSelectedEmailTemplateId(defaultOption.value);
      void queryClient.invalidateQueries({ queryKey: [templateQueryKey, { emailId, eventId }] });
      showToast.success('Email template deleted successfully');
    }
  });

  const { mutate: activateEmailTemplateMutate } = useMutation({
    mutationFn: async () => {
      if (!selectedEmailTemplateId) {
        return null;
      }
      return await activateEmailTemplate(createTenantAdminApiRequest)({ emailTemplateId: selectedEmailTemplateId });
    },
    onMutate: handleLoadingToast,
    onSuccess: (res) => {
      if (((res?.errors ?? []).length) > 0) {
        showToast.error(res?.errors?.[0] ?? 'Error activating email template');
        return;
      }
      void queryClient.invalidateQueries({ queryKey: [templateQueryKey, { emailId, eventId }] });
      showToast.success('Email template activated successfully');
    }
  });

  const handleSave = <T extends IEmailTemplatePayload | ICreateTemplatePayload>(
    saveAction: (saveActionPayload: T) => void
  ) => {
    return (payload: T) => {
      const unlayer = emailEditorRef.current?.editor;
      unlayer?.saveDesign((template: JSONTemplate) => {
        saveAction({ ...payload, template, html: '' });
      });
    };
  };

  const emailTemplateOptions = useMemo(() => {
    if (!data?.items.length) {
      return [];
    }
    return data.items.map(({ id, name, active }) => {
      const label: string = active ? `${name} (Active)` : name;
      const mergeTags = data?.items[0].mergeTags;
      const signature = data?.items[0].signature;
      const tenantId = data?.items[0].tenantId;
      if (active) {
        setActiveTemplateId(id);
      }
      return {
        value: id,
        label,
        active,
        mergeTags,
        signature,
        tenantId
      };
    });
  }, [data]);

  // Set the default email template id.
  useEffect(() => {
    if (emailTemplateOptions.length > 0) {
      const [defaultOption] = emailTemplateOptions;
      const activatedEmailTemplate = emailTemplateOptions.find(({ active }) => { return active; });
      const defaultEmailTemplate = activatedEmailTemplate ?? defaultOption;
      setSelectedEmailTemplateId(defaultEmailTemplate.value);
      setTags(defaultEmailTemplate.mergeTags);
      setSignature(defaultEmailTemplate.signature);
      setTenantId(defaultEmailTemplate.tenantId);
    }
  }, [
    emailTemplateOptions,
  ]);

  // Get the current template contents
  const currentEmailTemplate = useMemo(() => {
    const emailTemplate = data?.items.find((item) => {
      return item.id === selectedEmailTemplateId;
    }) as IEmailTemplatePayload | undefined;
    return emailTemplate?.template ?? null;
  }, [
    selectedEmailTemplateId,
    data?.items,
  ]);

  // Load the current template contents into the Unlayer editor.
  useEffect(() => {
    if (data?.items?.length && data?.items?.length > 0 && currentEmailTemplate && tags && emailEditorRef.current?.editor && ready) {
      emailEditorRef.current.editor.loadDesign(currentEmailTemplate);
      emailEditorRef.current.editor.setMergeTags(tags);
    }
  }, [data, emailTemplateOptions, currentEmailTemplate, emailEditorRef, ready]);

  const handleOnChange = async (option: OnChangeValue<SelectElementOption, false>) => {
    setSelectedEmailTemplateId(option?.value as number);
  };
  const handleMenu = (menuActionType: SaveEmailType) => {
    setSaveType(menuActionType);
    onMenuToggle();
  };
  const onReady = () => {
    setReady(true);
  };

  return (
    <UiStack
      bgColor={'gray.100'}
      alignItems={'stretch'}
      borderRadius={uiStyles.borderRadius}
      // minHeight={'100vh'}
      flexGrow={1}
      pb={12}
      spacing={4}
    >
      <UiStack spacing={1}>
        <UiText variant={'body2'} color={'text.secondary'}>Email type</UiText>
        <UiText>{emailType}</UiText>
      </UiStack>
      <BaseDividerHorizontal height={4} />
      <UiStack
        spacing={4}
        flexGrow={1}
        alignItems={'center'}
      >
        <UiStack
          p={4}
          minW={1200}
          maxW={1200}
          minH={1000}
          maxH={1000}
          background={'#fff'}
          alignItems={'stretch'}
          borderRadius={uiStyles.bodyRadius}
          boxShadow={uiStyles.cardShadow}
        >
          <UiModal isOpen={isMenuOpen} onClose={onMenuClose}>
            <UiModalOverlay />
            <UiModalContent>
              <UiModalCloseButton />
              <UiModalBody p={8}>
                <Formik
                  initialValues={{ name: '' }}
                  onSubmit={(params) => {
                    const payload = {
                      name: params.name,
                      createBlank: SaveEmailType.NEW === saveType,
                      html: '',
                      template: defaultTemplate,
                    };
                    if (saveType === SaveEmailType.SAVE_AS) {
                      handleSave(createOrSaveAsEmailTemplateMutate)(payload);
                    } else if (saveType === SaveEmailType.NEW) {
                      createOrSaveAsEmailTemplateMutate(payload);
                    }
                  }}
                >
                  {(form) => {
                    return (
                      <Form id='new-template-form' onSubmit={form.handleSubmit}>
                        <UiStack direction={'column'}>
                          <BaseFormInputField label={emailTemplateActions[saveType]} name='name' layout='stack' />
                        </UiStack>
                      </Form>
                    );
                  }}
                </Formik>
              </UiModalBody>
              <UiDivider />
              <UiModalFooter alignItems={'stretch'}>
                <UiHStack justifyContent={'space-between'} spacing={4} flexGrow={1}>
                  <UiButton variant='ghost' onClick={onMenuClose}>Cancel</UiButton>
                  <UiButton type='submit' form='new-template-form' isLoading={isCreateLoading}>Save</UiButton>
                </UiHStack>
              </UiModalFooter>
            </UiModalContent>
          </UiModal>
          <UiHStack
            spacing={8}
            justifyContent={'space-between'}
            borderRadius={uiStyles.borderRadius}
            // borderWidth={'1px'}
            // borderColor={'gray.300'}
            // borderStyle={'solid'}
            px={8}
            py={4}
            bgColor={'blackAlpha.50'}
          >
            {!!selectedEmailTemplateId && (
              <UiHStack spacing={4}>
                <UiText>Template</UiText>
                <BaseSimpleSelectElement
                  optionValue={selectedEmailTemplateId}
                  onChange={handleOnChange}
                  options={emailTemplateOptions}
                  placeholder='Select template'
                />
                {activeTemplateId !== selectedEmailTemplateId && (
                  <BaseButtonIconText
                    Icon={UiIconCheck}
                    onClick={() => {
                      setSaveType(SaveEmailType.ACTIVATE);
                      return activateEmailTemplateMutate();
                    }}
                  >
                    Activate this template
                  </BaseButtonIconText>
                )}
              </UiHStack>
            )}
            <UiHStack spacing={4}>
              <BaseButtonIconText
                Icon={UiIconPlus}
                onClick={() => { return handleMenu(SaveEmailType.NEW); }}
              >
                New
              </BaseButtonIconText>
              <BaseButtonIconText
                Icon={UiIconFloppyDisk}
                onClick={() => {
                  const payload = {
                    html: '',
                    template: defaultTemplate,
                  };
                  handleSave(updateEmailTemplateMutate)(payload);
                }}
              >
                Save
              </BaseButtonIconText>
              <BaseButtonIconText
                Icon={UiIconFolders}
                onClick={() => { return handleMenu(SaveEmailType.SAVE_AS); }}
              >
                Save as
              </BaseButtonIconText>
              <BaseButtonIconText
                Icon={UiIconX}
                onClick={() => {
                  setSaveType(SaveEmailType.DELETE);
                  return deleteTemplateMutate();
                }}
                color={'red.500'}
              >
                Delete
              </BaseButtonIconText>
            </UiHStack>
          </UiHStack>
          <BaseDividerHorizontal height={4}/>
          <EmailEditor 
            onReady={onReady}
            ref={emailEditorRef}
            options={{
              projectId: 244721,
              displayMode: 'email',
              user: {
                id: tenantId,
                signature: signature,
              },
              tools: {
                social: {
                  enabled: true,
                },
              },
            }}
          />
        </UiStack>
      </UiStack>
    </UiStack>
  );
};

export default EmailDesignBody;

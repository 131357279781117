import { type FC } from 'react';
import {
  UiDrawer,
  UiDrawerBody,
  UiDrawerCloseButton,
  UiDrawerContent,
  UiDrawerOverlay,
  type UiDrawerProps,
} from '@/lib/ui';
import EmailDesignBody from '@/registration/component/EmailDesign/EmailDesignBody';

export interface MailBuilderProps {
  emailId: number
  emailType: string
  isOpen: UiDrawerProps['isOpen'];
  onClose: UiDrawerProps['onClose'];
}

const MailBuilder: FC<MailBuilderProps> = ({
  emailId,
  emailType,
  isOpen,
  onClose,
}) => {

  return (
    <UiDrawer
      placement={'right'}
      size={'3xl'}
      isOpen={isOpen}
      onClose={onClose}
    >
      <UiDrawerOverlay />
      <UiDrawerContent>
        <UiDrawerCloseButton size={'lg'} color={'primary.500'}/>
        <UiDrawerBody p={8} bgColor={'gray.100'}>
          <EmailDesignBody emailId={emailId} emailType={emailType}/>
        </UiDrawerBody>
      </UiDrawerContent>
    </UiDrawer>
  );
};

export default MailBuilder;

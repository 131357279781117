import { type FC, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Formik, type FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { type UiHStackProps, UiStack } from '@/lib/ui';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { registration } from '@/api';
import {
  type InfoFormSaveRequest,
  type InfoForm,
  InfoFormType
} from '@/api/registration';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useTenantApi } from '@/account/hook/useTenantApi';
import BaseFormCheckboxListField, { type CheckboxOption } from '@/base/Form/CheckboxListField';
import { useAttendeeCategoryQuery } from '@/registration/hook/useAttendeeCategoryQuery';

export interface AttendeeCategoriesFormProps extends UiHStackProps {
  infoForm: InfoForm
  onClose: () => void
  onSaveSuccess: () => void
  isVisible: boolean
}

interface FormData {
  attendeeCategoryIds: Array<CheckboxOption['value']>
}

const formSchema = Yup.object().shape({
});

const AttendeeCategoriesForm: FC<AttendeeCategoriesFormProps> = ({
  onClose,
  onSaveSuccess,
  isVisible,
  infoForm
}) => {
  const { eventId } = useRegisterRoute();
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const queryClient = useQueryClient();
  const { createTenantAdminApiRequest } = useTenantApi();

  const attendeeCategoryQuery = useAttendeeCategoryQuery(eventId);

  const attendeeCategories: CheckboxOption[] = attendeeCategoryQuery.data?.map((attendeeCategory) => ({
    value: `${attendeeCategory.id}`,
    label: attendeeCategory.name
  })) ?? [];

  const { mutate } = useMutation<{}, Error, InfoFormSaveRequest>(
    {
      mutationFn: async (data: InfoFormSaveRequest) => {
        return await registration.saveInfoForm(createTenantAdminApiRequest)(data);
      },
      onSuccess: () => {
        void queryClient.invalidateQueries({ queryKey: [registration.infoFormsQueryKey, { eventId, formType: InfoFormType.PERSONAL }] });
        void queryClient.invalidateQueries({ queryKey: [registration.infoFormQueryKey, { id: infoForm.id }] });
        void queryClient.invalidateQueries({ queryKey: ["registration.attendeeCategoryList", { eventId }] });
        onSaveSuccess();
        onClose();
      },
      onError: (error) => {
        setSaveErrors([error.message ?? 'Failed to save the host.']);
      }
    }
  );

  const submitForm = async (values: FormData) => {
    mutate({
      infoForm: {
        id: infoForm.id,
        eventId,
        formType: InfoFormType.PERSONAL,
        config: JSON.stringify(infoForm.config),
        attendeeCategoryIds: values.attendeeCategoryIds,
        name: infoForm.name
      }
    });
  };

  return (
    <Formik
      initialValues={{
        attendeeCategoryIds: infoForm.attendeeCategories.map(category => String(category.id))
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={formSchema}
      onSubmit={async (values, { setSubmitting }: FormikHelpers<FormData>) => {
        setSubmitting(true);
        await submitForm(values);
        setSubmitting(false);
      }}
    >{({ setFieldValue }) => (
        <BaseFormDrawer
          isOpen={isVisible}
          onClose={onClose}
          title={'Choose the attendee groups that can see this form'}
          size={'lg'}
          isLoading={attendeeCategoryQuery.isLoading}
        >
          {saveErrors.length > 0 && (
            <UiStack spacing={4} flexGrow={1} py={4}>
              {saveErrors.map((error, index) => {
                return (
                  <BaseMessageBarError key={index}>{error}</BaseMessageBarError>
                );
              })}
            </UiStack>
          )}
          <BaseFormFieldGroup>
            <BaseFormCheckboxListField
              name={'attendeeCategoryIds'}
              label={'Attendee groups'}
              helperText={'If none is selected, the form will not be seen by any attendees.'}
              options={attendeeCategories}
              layout={'stack'}
              isRequired={false}
            />
          </BaseFormFieldGroup>
        </BaseFormDrawer>)}
    </Formik>
  );
};

export default AttendeeCategoriesForm;

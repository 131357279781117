import { type FC } from 'react';
import {
  CornersIn
} from '@phosphor-icons/react';
import { UiIcon, type UiIconProps } from './UiIcon';

export type UiIconCornersInProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconCornersIn: FC<UiIconCornersInProps> = (props) => {
  return (
    <UiIcon
      IconComponent={CornersIn}
      {...props}
    />
  );
};

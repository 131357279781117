import { FC } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { UiIconLayout, UiIconPaperPlaneTilt } from '@/lib/ui';
import TestEmailsForm from '@/registration/buildEvent/AutomatedEmail/TestEmailsForm';
import BaseButtonPlainIcon from '@/base/Button/PlainIcon';
import EmailDesign from '@/registration/component/EmailDesign';

export interface DesignEmailProps {
  emailId: number
  emailType: string
}

const DesignEmail: FC<DesignEmailProps> = ({
  emailId,
  emailType,
}) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <BaseButtonPlainIcon
        label={"Design"}
        Icon={UiIconLayout}
        color={"primary.500"}
        onClick={onToggle}
        iconSize={'xl'}
      />
      <EmailDesign
        emailId={emailId}
        emailType={emailType}
        isOpen={isOpen}
        onClose={onToggle}
      />
    </>
  );
};

export default DesignEmail;

export enum BadgeTemplateStatusType {
  YES = 'true',
  NO = 'false',
}

export enum BadgeTemplatePrintBackType {
  YES = 'true',
  NO = 'false',
}

export enum BadgeTemplateAllowReprintType {
  YES = 'true',
  NO = 'false',
}

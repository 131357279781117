import {
  callApi,
  type ApiRequest,
  type ApiResponse,
  type ApiResponseSingle,
  properCallApi,
} from '@/api/tenantClient';

export const taxRateQueryKey = 'registration.taxRate';
export const taxRateListQueryKey = 'registration.taxRateList';

export interface TaxRate {
  id: number
  active: boolean
  name: string
  percentage: string | number
  localeId: number
  createdAt: string
  updatedAt: string
}

export interface LoadTaxRateListRequest {
  eventId?: number
}

export function loadTaxRateList(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (
    params: LoadTaxRateListRequest
  ): Promise<ApiResponse<TaxRate>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/admin/registration_tax_rates';
    request.endpoint.query = {
      ...(params.eventId && { eventId: params.eventId.toString() }),
    };
    return await callApi(request);
  };
}

export interface SaveTaxRateRequest {
  id?: number
  eventId: number | string
  name: string
  percentage: string | number
}

export function saveTaxRate(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (params: SaveTaxRateRequest): Promise<ApiResponse<TaxRate>> => {
    if (params.id) {
      const { id: taxRateId, name: taxName, percentage: value } = params;
      request.method = 'PUT';
      request.endpoint.path = `/api/v1/admin/registration_tax_rates/${taxRateId}`;
      request.payload = {
        registrationTaxRate: {
          taxName,
          value,
          active: true,
        },
      };
    } else {
      const { name: taxName, percentage: value, eventId } = params;
      request.method = 'POST';
      request.endpoint.path = '/api/v1/admin/registration_tax_rates';
      request.payload = {
        registrationTaxRate: {
          eventId,
          taxName,
          value,
          active: true,
        },
      };
    }

    return await callApi(request);
  };
}

export interface DeleteTaxRateRequest {
  id: number
  eventId: number | string
}

export function deleteTaxRate(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (params: DeleteTaxRateRequest): Promise<ApiResponseSingle<{}>> => {
    request.method = 'DELETE';
    request.endpoint.path = `/api/v1/admin/registration_tax_rates/${params.id}`;
    request.payload = {
      eventId: params.eventId,
    };
    return await properCallApi(request);
  };
}

import { type FC, type ElementType, useMemo, ReactNode } from 'react';
import {
  uiStyles,
  UiButtonProps,
  UiText,
  UiHStack,
  UiHStackProps,
  UiIconProps,
  UiTextProps,
  UiStack,
  UiSpinner,
} from '@/lib/ui';
import BaseLoadingSpinner from '@/base/Loading/Spinner';

export type Variant = 'default' | 'large';

export interface IconTextProps extends UiHStackProps {
  children: ReactNode
  Icon: ElementType
  variant?: Variant
  color?: UiButtonProps['color']
  iconColor?: UiIconProps['color']
  isLoading?: boolean
}

const IconText: FC<IconTextProps> = ({
  children,
  Icon,
  variant = 'default',
  color = 'primary.500',
  iconColor = color,
  isLoading = false,
  ...props
}) => {
  const styles: {
    iconProps: Partial<UiIconProps>,
    textProps: Partial<UiTextProps>,
  } = useMemo(
    () => {
      if (variant === 'default') {
        return {
          iconProps: {
            size: '2xl',
          },
          textProps: {
            variant: 'body1',
          },
        };
      }
      if (variant === 'large') {
        return {
          iconProps: {
            size: '2xl',
            weight: 'bold',
          },
          textProps: {
            variant: 'title',
          },
        };
      }
      return {
        iconProps: {},
        textProps: {},
      };
    },
    [variant]
  );

  if (isLoading) {
    return (
      <UiStack>
        <BaseLoadingSpinner/>
      </UiStack>
    );
  }
  return (
    <UiHStack {...uiStyles.hover} spacing={1} {...props}>
      <Icon color={iconColor} {...styles.iconProps}/>
      <UiText color={color} {...styles.textProps}>{children}</UiText>
    </UiHStack>
  );
};

export default IconText;

import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { exportRegistrationStatistics, type RegistrationReport } from '@/api/registration';
import { useAdminAuth } from '@/app/ProviderAdminAuth';
import BarChart from '@/base/Chart/BarChart';
import {
  UiHStack,
  UiIconExport,
  UiIconMagnifyingGlassMinus,
  UiSimpleGrid,
  UiStack,
  uiStyles,
  UiText,
} from '@/lib/ui';
import { dynamicColor } from '@/lib/util';
import { useMutation } from '@tanstack/react-query';
import { type ChartDataset } from 'chart.js';
import type React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { type useGetRegistrationStatisticsType } from '@/registration/report/Registration';
import { defaultColors } from '@/api/constant/colors';
import BaseSimpleSelectElement, { type Option as SelectElementOption } from '@/base/Form/SimpleSelectElement';
import type { OnChangeValue } from 'chakra-react-select';
import SubscribeButton from '@/base/ScheduleReport/SubscribeButton';
import { type ReportSubscriptionName } from '@/api/reports/reportSubscriptions';
import BasePlainIcon from '@/base/Button/PlainIcon';
import BaseLoadingSpinner from '@/base/Loading/Spinner';

interface RegistrationsChartReportProps {
  selectOptions: Array<{
    label: string
    value: string
  }>
  useGetRegistrationStatistics: useGetRegistrationStatisticsType
  type: string
  title: string
  reportName?: ReportSubscriptionName
  eventOptions?: SelectElementOption[]
}

const RegistrationsChartReport: React.FC<RegistrationsChartReportProps> = ({ selectOptions, useGetRegistrationStatistics, type, title, reportName, eventOptions = [] }) => {
  const [selectedGroup, setSelectedGroup] = useState<string>('');
  const [labels, setLabels] = useState<string[] | undefined>([]);
  const [datasets, setDatasets] = useState<Array<ChartDataset<'bar', number[]>>>([]);
  const [eventId, setEventId] = useState<string>('');

  const { data, isLoading } = useGetRegistrationStatistics({ type, eventId });
  const { adminAuth } = useAdminAuth();
  const { createTenantAdminApiRequest } = useTenantApi();
  const { reportToGlobal } = useApiErrorHandler();

  const { mutate: callExportRegistrationStatistics, isLoading: exportRegistrationStatisticsLoading } = useMutation<{}, Error>({
    mutationFn: async () => {
      return await exportRegistrationStatistics(createTenantAdminApiRequest)({
        type,
        email: adminAuth.user!.email
      });
    },
    onError: (error) => {
      reportToGlobal(error);
    }
  });

  const onExportRegistrationStatistics = useCallback(() => { return callExportRegistrationStatistics(); }, [callExportRegistrationStatistics]);

  useEffect(() => {
    if (data && !data?.errors && selectedGroup) {
      let colors: string[] = [...defaultColors];
      const fetchLabel: string[] = data?.item[selectedGroup as keyof RegistrationReport]?.map((label: { category?: string, ticketType?: string, country?: string, name?: string }) => {
        switch (selectedGroup) {
          case 'byAttendeeCategory':
            return label.category ?? '';
          case 'byMainTicketType':
            return label.ticketType ?? '';
          case 'byCountry':
            return label.country ?? '';
          case 'byDiscountCode':
            return label.name ?? '';
          default:
            return label.name ?? '';
        }
      }) ?? [];
      setLabels(fetchLabel);

      const randomColors: string[] = [];
      const fetchDataset = data?.item[selectedGroup as keyof RegistrationReport]?.map((label: { count: number }) => {
        const color = dynamicColor(colors);
        randomColors.push(color);
        colors = colors.filter((value) => { return value !== color; });

        return label.count;
      });
      if (fetchDataset) {
        setDatasets([{
          data: fetchDataset,
          label: title,
          backgroundColor: randomColors
        }]);
      }
    }
  }, [data, selectedGroup, title]);

  const handleChange = (option: OnChangeValue<SelectElementOption, false>) => {
    setSelectedGroup(option?.value ? `${option?.value}` : '');
  };

  const handleChangeEvent = (option: OnChangeValue<SelectElementOption, false>) => {
    setEventId(option?.value ? `${option?.value}` : '');
  };

  return (
    <UiStack
      alignItems={'stretch'}
      spacing={4}
      borderWidth={'1px'}
      borderColor={'blackAlpha.100'}
      borderRadius={uiStyles.borderRadius}
      p={8}
    >
      <UiHStack spacing={4}>
        <UiHStack spacing={2}>
          {/* {!!icon && icon} */}
          <UiText variant={'body1'}>{title}</UiText>
        </UiHStack>
        {/* <BaseButtonIconText Icon={UiIconExport} isLoading={exportRegistrationStatisticsLoading} onClick={onExportRegistrationStatistics}>Email CSV</BaseButtonIconText> */}
        <UiHStack flexGrow={1} justifyContent={'flex-end'} spacing={4}>
          <BasePlainIcon
            label={'Email CSV'}
            Icon={UiIconExport}
            color={'primary.500'}
            onClick={onExportRegistrationStatistics}
            isLoading={exportRegistrationStatisticsLoading}
          />
          {reportName && <SubscribeButton reportName={reportName} />}
        </UiHStack>
      </UiHStack>
      <UiSimpleGrid columns={2} spacing={4} flexGrow={1}>
        <UiStack flexGrow={1}>
          <BaseSimpleSelectElement
            optionValue={eventId}
            onChange={handleChangeEvent}
            options={eventOptions}
            placeholder={'Select event'}
          />
        </UiStack>
        <UiStack flexGrow={1}>
          <BaseSimpleSelectElement
            optionValue={selectedGroup}
            onChange={handleChange}
            options={selectOptions}
            placeholder={'Select group'}
          />
        </UiStack>
      </UiSimpleGrid>
      {labels?.length === 0 && (
        <UiHStack py={2}>
          <UiIconMagnifyingGlassMinus color={'text.secondary'} />
          <UiText color={'text.secondary'} variant={'body2'}>
            No results
          </UiText>
          {/* <UiTag colorScheme={'blue'}>No results</UiTag> */}
        </UiHStack>
      )}
      {labels && labels.length > 0 && (
        <BarChart datasets={datasets} labels={labels} title={''} />
      )}
      {isLoading && !!eventId && (
        <BaseLoadingSpinner />
      )}

    </UiStack>
  );
};

export default RegistrationsChartReport;

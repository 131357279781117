import { type FC } from 'react';
import {
  UiHStack,
  type UiHStackProps,
  UiStack,
  UiText,
  UiButton,
  UiDrawer,
  UiDrawerOverlay,
  UiDrawerCloseButton,
  UiDrawerContent,
  UiDrawerBody,
  UiDrawerFooter,
  UiSimpleGrid
} from '@/lib/ui';
import { registration, type account } from '@/api';
import BaseButtonPlainIconEdit from '@/base/Button/PlainIconEdit';
import BaseButtonPlainIconDelete from '@/base/Button/PlainIconDelete';
import { PermissionAction, PermissionDomain } from '@/api/constant/adminUserPermission';
import BaseAclContainer from '@/account/component/AclContainer';
import { useDisclosure } from '@chakra-ui/react';
import DeletionConfirmation from '../DiscountCodes/DeletionConfirmation';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTenantApi } from '@/account/hook/useTenantApi';
import LocaleForm from './LocaleForm';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useGlobalToast } from '@/lib/hook';

export interface LocaleCardProps extends UiHStackProps {
  tenant: account.TenantData
  locale: registration.Locale
  taxRates: registration.TaxRate[]
}

const LocaleCard: FC<LocaleCardProps> = ({ tenant, locale, taxRates, ...props }) => {
  const { showToast } = useGlobalToast();
  const queryClient = useQueryClient();
  const { createTenantAdminApiRequest } = useTenantApi();
  const { isOpen: isVisibleEdit, onToggle: onToggleEdit } = useDisclosure();
  const { isOpen: confirmDeletion, onToggle: onToggleConfirmDeletion } = useDisclosure();
  const { eventId } = useRegisterRoute();

  const taxRateName = taxRates.find((item) => { return item.id === locale.taxRateId; })?.name;

  const { mutate: deleteTaxRateMutate, isLoading: isDeleteTaxRateLoading } = useMutation({
    mutationFn: async () => {
      return await registration.deleteLocale(createTenantAdminApiRequest)({ id: locale.id, eventId });
    },
    onSuccess: (res) => {
      if ((res?.errors ?? []).length) {
        showToast.error(res?.errors?.[0] ?? 'Failed to delete locale');
      }
      void queryClient.invalidateQueries({
        queryKey: [registration.localeListQueryKey, { eventId }],
      });
      onToggleConfirmDeletion();
    },
  });

  const onConfirmDelete = () => {
    deleteTaxRateMutate();
  };
  return (
    <>
      <UiHStack
        alignItems={'center'}
        spacing={8}
        p={6}
        bgColor={'#fff'}
        // borderRadius={uiStyles.borderRadius}
        {...props}
      >
        <UiSimpleGrid
          columns={6}
          gap={8}
          flexGrow={1}
        >
          <UiStack alignItems={'stretch'} justifyContent={'space-between'} spacing={0}>
            <UiText variant={'body2'} color={'text.secondary'}>Name</UiText>
            <UiText variant='body1'>{locale.name}</UiText>
          </UiStack>
          <UiStack flexGrow={1} justifyContent={'space-between'} spacing={0}>
            <UiText variant={'body2'} color={'text.secondary'}>Country</UiText>
            <UiText variant='body1'>{locale.country}</UiText>
          </UiStack>
          <UiStack flexGrow={1} justifyContent={'space-between'} spacing={0}>
            <UiText variant={'body2'} color={'text.secondary'}>Language</UiText>
            <UiText variant='body1'>{locale.language}</UiText>
          </UiStack>
          <UiStack flexGrow={1} justifyContent={'space-between'} spacing={0}>
            <UiText variant={'body2'} color={'text.secondary'}>Default</UiText>
            <UiText variant='body1'>{locale.default ? 'True' : 'False'}</UiText>
          </UiStack>
          <UiStack flexGrow={1} justifyContent={'space-between'} spacing={0}>
            <UiText variant={'body2'} color={'text.secondary'}>Currency</UiText>
            <UiText variant='body1'>{locale.currency}</UiText>
          </UiStack>
          <UiStack flexGrow={1} justifyContent={'space-between'} spacing={0}>
            <UiText variant={'body2'} color={'text.secondary'}>Tax rate</UiText>
            <UiText variant='body1'>{taxRateName ?? 'None'}</UiText>
          </UiStack>
        </UiSimpleGrid>
        <BaseAclContainer tenantId={tenant?.id ?? 0} permissionsRequired={[[PermissionDomain.Registration, PermissionAction.Write]]}>
          <UiHStack spacing={6} justifyContent={'space-between'}>
            <BaseButtonPlainIconEdit
              onClick={(onToggleEdit)}
            />
            {/* <BaseButtonPlainIconClone
            // onClick={onToggleConfirmClone}
            /> */}
            <BaseButtonPlainIconDelete
              onClick={onToggleConfirmDeletion}
            />
          </UiHStack>
          <LocaleForm
            locale={locale}
            onClose={onToggleEdit}
            isVisible={isVisibleEdit}
            onSaveSuccess={onToggleEdit}
          />
        </BaseAclContainer>
      </UiHStack>
      <UiDrawer
        placement={'bottom'}
        size={'lg'}
        isOpen={false}
        onClose={() => { }}
      // isOpen={isConfirmClone} onClose={onToggleConfirmClone}
      >
        <UiDrawerOverlay />
        <UiDrawerCloseButton size={'lg'} color={'primary.500'} />
        <UiDrawerContent p={4}>
          <UiDrawerBody>
            <UiText variant={'title'} fontWeight={400}>
              Do you confirm to clone this discount code?
            </UiText>
          </UiDrawerBody>
          <UiDrawerFooter>
            <UiHStack spacing={8}>
              <UiButton
              // onClick={onToggleConfirmClone} colorScheme={'gray'} px={8}
              >
                No
              </UiButton>
              <UiButton colorScheme={'primary'}
                ml={4}
                onClick={onConfirmDelete}
                isLoading={isDeleteTaxRateLoading}
                px={8}
              >
                Yes
              </UiButton>
            </UiHStack>
          </UiDrawerFooter>
        </UiDrawerContent>
      </UiDrawer>
      <DeletionConfirmation
        isOpen={confirmDeletion}
        onClose={onToggleConfirmDeletion}
        onConfirm={onConfirmDelete}
        name={''} />
    </>
  );
};

export default LocaleCard;

import { type FC, useState } from 'react';
import BaseLayout from '@/base/Layout';
import BaseLayoutBody from '@/base/Layout/Body';
// import Filter from './Filter';
import AdminSideNav from '@/registration/component/Layout/AdminSideNav';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { UiFlex, UiHStack, UiIconPlus, UiIconPlusCircle, UiStack, UiText } from '@/lib/ui';
import BaseLayoutFullScreenToggle from '@/base/Layout/FullScreenToggle';
import SubscribeButton from '@/base/ScheduleReport/SubscribeButton';
import { reportSubscriptionName } from '@/api/reports/reportSubscriptions';
import BadgeList from '@/registration/manage/RegistrationBadge/BadgeList';
import { UiIconUpload } from '@/lib/ui/Icon/UiIconUpload';
import BaseButtonIconText from '@/base/Button/IconText';
import BaseSearchBar from '@/base/SearchBar';
import NewBadge from '@/registration/manage/RegistrationBadge/NewBadge';

const RegistrationBadge: FC = () => {
  const { tenantCode } = useRegisterRoute();
  const [searchKey, setSearchKey] = useState<string>('');
  const [name, setName] = useState('');
  const searchName = () => {
    setSearchKey(name);
  };

  return (
    <BaseLayout
      smartWindow={(<SmartWindow />)}
      sideMenu={(<AdminSideNav activeNodes={['manage', 'registrationBadges']} tenantCode={tenantCode} />)}
      appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard showDescription={false} />} currentAppName={'registration'} />)}
    >
      <BaseLayoutBody
        isStandalone={false}
        // breadcrumbNodes={breadcrumbNodes}
      >
        <UiHStack spacing={4}>
          <BaseLayoutFullScreenToggle />
          <UiText variant={'title'}>Registration badges</UiText>
          <NewBadge/>
        </UiHStack>
        <UiStack height={4} />
        <UiHStack>
          <BaseSearchBar
            placeHolder={'Search name'}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            enableOnClear={false}
            onSearch={searchName}
            flexGrow={1}
          />
        </UiHStack>
        <UiStack height={4} />
        <BadgeList/>
        {/*<RegistrationBadgeTable />*/}
      </BaseLayoutBody>
    </BaseLayout>
  );
};

export default RegistrationBadge;

import {
  type ApiRequest,
  type ApiResponse,
  type ApiResponseSingle,
  properCallApi
} from '@/api/tenantClient';
import { type ListActivatedChart } from './registration';

export const ordersQueryKey = 'registration.orderlist';
export const orderQueryKey = 'registration.order';
export const orderTableQueryKey = 'registration.orderTable';
export const orderIdQueryKey = 'registration.orderid';
export const orderStatistics = 'registration.orderStatistics';

export interface OrderItem {
  id: number
  name: string
  price: number
  quantity: number
}

export interface Order {
  id: number
  secureHash: string
  status: string
  serial: string
  event: string
  eventId: string
  tickets: Array<{ name: string, quantity: number }>
  attendee: { name: string, email: string }
  totalPrice: number
  purchasedAt: string
  orderItems: OrderItem[]
  currencyCode: string
}

export interface OrderTableRow {
  id: number
  attendee: { name: string, email: string }
  event: { id: number, name: string, timeZoneName: string }
  purchasedAt: string | null
  tickets: Array<{
    id: number
    name: string
    quantity: number
    type: 'DelegateType' | 'SessionsPricing'
  }>
  status: string
  totalPrice: string
  createdAt: string
  paymentOption: string
  discountCode: string | null
  country: string | null
  attendeeCategory: { name: string }
  totalPriceFormat: string
  currencyCode: string | undefined
  refundAmount: string
}

export function loadOrder(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (
    secureHash: string
  ): Promise<ApiResponseSingle<Order>> => {
    request.method = 'GET';
    request.endpoint.path = `/api/v1/orders/${secureHash}`;
    return await properCallApi<ApiResponseSingle<Order>>(request);
  };
}

export interface OrderQueryRequest {
  page: number
  filters?: any
  sorting?: any
  size: number
  search?: string
}

export function loadOrdersForTable(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (
    params: OrderQueryRequest
  ): Promise<ApiResponse<OrderTableRow>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/orders';
    request.endpoint.query = {
      page: (params?.page ?? 0) + 1,
      filters: JSON.stringify(params.filters ?? []),
      sorting: JSON.stringify(params.sorting ?? []),
      size: params.size ?? 10,
      ...params.search ? { search: params.search } : {}
    };
    return await properCallApi<ApiResponse<OrderTableRow>>(request);
  };
}

export interface OrderItemType {
  discountRuleAmount: number
  discountRuleAmountFormat: string
  discountRuleType: 'fixed_amount' | 'percentage'
  mainTicket: boolean
  name: string
  price: number
  priceFormat: string
  quantity: number
  refundedAt?: string
  id: number
  isDeleted?: boolean
}

export interface OrderById {
  currency?: string
  discount?: { name: string, description: string, code: string }
  email: string
  id: number
  orderItems: OrderItemType[]
  paidAt?: string
  refunds: Array<{
    createdAt: string
    id: number
    refundAmount: number | string
    refundAmountFormat: string
    refundNote: string
    refundedBy: string
  }>
  serial: string
  status: string
  totalPrice: number
  totalPriceFormat: string
  discountValue: number
  discountValueFormat: string
  creditCardFee: string
  creditCardFeeFormat: string
  tax: number
  taxFormat: string
  taxName: string
  paymentOption: string
  currencyCode: string
  registrationId?: number
  groupRegistrationId?: number
  createdAt: string
  surcharge: number
  surchargeFormat: string
  secureHash: string
  eventId: number
  invoiceUrl?: string
  orderType?: string
}

export function loadOrderById(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (
    id: number
  ): Promise<ApiResponseSingle<OrderById>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/orders/info';
    request.endpoint.query = {
      orderId: id
    };
    return await properCallApi<ApiResponseSingle<OrderById>>(request);
  };
}

interface ChartDataItem {
  category?: string
  ticketType?: string
  country?: string
  name?: string
  totalPrice?: number
  count?: number
}

export interface OrderReport {
  byAttendeeCategory?: ChartDataItem[]
  byMainTicketType?: ChartDataItem[]
  byCountry?: ChartDataItem[]
  byDiscountCode?: ChartDataItem[]
  byFunctionTicket?: ChartDataItem[]
  byWorkshopTicket?: ChartDataItem[]
  byTourTicket?: ChartDataItem[]
  byTotalTicket?: ChartDataItem[]
}

export function loadOrderStatistics(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (
    params: { eventId: string, type: string }
  ): Promise<ApiResponseSingle<OrderReport>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/orders/statistics';
    request.endpoint.query = {
      event_id: params.eventId,
      type: params.type,
    };

    return await properCallApi<ApiResponseSingle<OrderReport>>(request);
  };
};

export function exportOrderStatistics(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (
    params: { email: string, type: string }
  ): Promise<ApiResponseSingle<undefined>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/orders/statistics_export';
    request.endpoint.query = {
      email: params.email,
      type: params.type
    };

    return await properCallApi<ApiResponseSingle<undefined>>(request);
  };
};

export function orderListActivated(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (
    params: { groupName: string, eventId: string }
  ): Promise<ApiResponseSingle<ListActivatedChart>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/orders/list_activated';
    request.endpoint.query = {
      groupName: params.groupName,
      eventId: params.eventId,
    };

    return await properCallApi<ApiResponseSingle<ListActivatedChart>>(request);
  };
};

import { Box, useDisclosure } from '@chakra-ui/react';
import React, { FC, useEffect, useRef, useState } from 'react';
import { EditorRef } from 'react-email-editor/dist/types';
import EmailEditor, { EmailEditorProps } from 'react-email-editor';
import { type MergeTags } from 'state/types/types';
import BadgeBuilderNav from './BadgeBuilderNav';
import { BadgeTemplate } from './BadgeDesignerPage';
import { BadgeTemplateData, BadgeTemplatesData, getbBadgeTemplates } from './requests';
import { useTenantApi } from '@/account/hook/useTenantApi';
interface Props {

}

const BadgeDesignBuilder: FC<Props> = ({

}) => {
	const { isOpen, onToggle } = useDisclosure();
  const [ready, setReady] = useState(false);
  const [tags, setTags] = useState<MergeTags>();
  const [template, setTemplate] = useState<BadgeTemplate | null>(null);
  const [badgeTemplates, setBadgeTemplates] = useState<BadgeTemplate[] | null>(
    null
  );
	const badgeEditorRef = useRef<EditorRef>(null);

	useEffect(() => {
    fetchTemplates();
  }, []);

  const onReady = () => {
    setReady(true);
  };

  useEffect(() => {
    if (badgeTemplates && badgeTemplates.length > 0 && template === null) {
      const activeTemplate = badgeTemplates.find((badgeTemplate) => {
        return badgeTemplate.active === true;
      });
      if (activeTemplate) {
        setTemplate(activeTemplate);
      }
    }
  }, [badgeTemplates]);

  useEffect(() => {
    if (badgeEditorRef.current?.editor) {
      if (template?.template) {
        if (tags){
          badgeEditorRef.current.editor.setMergeTags(tags);
        }
        badgeEditorRef.current.editor.loadDesign(template.template);
      } else {
        const emptyDesign = {
          "counters": {
              "u_column": 1,
              "u_row": 1
          },
          "body": {
              "id": "1y9AUjgUCZ",
              "rows": [
                  {
                      "id": "N93uXiDjzy",
                      "cells": [
                          1
                      ],
                      "columns": [
                          {
                              "id": "eikVAQY7cm",
                              "contents": [],
                              "values": {
                                  "backgroundColor": "",
                                  "padding": "0px",
                                  "border": {},
                                  "borderRadius": "0px",
                                  "_meta": {
                                      "htmlID": "u_column_1",
                                      "htmlClassNames": "u_column"
                                  }
                              }
                          }
                      ],
                      "values": {
                          "displayCondition": null,
                          "columns": false,
                          "_styleGuide": null,
                          "backgroundColor": "",
                          "columnsBackgroundColor": "",
                          "backgroundImage": {
                              "url": "",
                              "fullWidth": true,
                              "repeat": "no-repeat",
                              "size": "custom",
                              "position": "center",
                              "customPosition": [
                                  "50%",
                                  "50%"
                              ]
                          },
                          "padding": "0px",
                          "anchor": "",
                          "hideDesktop": false,
                          "_meta": {
                              "htmlID": "u_row_1",
                              "htmlClassNames": "u_row"
                          },
                          "selectable": true,
                          "draggable": true,
                          "duplicatable": true,
                          "deletable": true,
                          "hideable": true
                      }
                  }
              ],
              "headers": [],
              "footers": [],
              "values": {
                  "_styleGuide": null,
                  "popupPosition": "center",
                  "popupWidth": "600px",
                  "popupHeight": "auto",
                  "borderRadius": "10px",
                  "contentAlign": "center",
                  "contentVerticalAlign": "center",
                  "contentWidth": "500px",
                  "fontFamily": {
                      "label": "Arial",
                      "value": "arial,helvetica,sans-serif"
                  },
                  "textColor": "#000000",
                  "popupBackgroundColor": "#FFFFFF",
                  "popupBackgroundImage": {
                      "url": "",
                      "fullWidth": true,
                      "repeat": "no-repeat",
                      "size": "cover",
                      "position": "center"
                  },
                  "popupOverlay_backgroundColor": "rgba(0, 0, 0, 0.1)",
                  "popupCloseButton_position": "top-right",
                  "popupCloseButton_backgroundColor": "#DDDDDD",
                  "popupCloseButton_iconColor": "#000000",
                  "popupCloseButton_borderRadius": "0px",
                  "popupCloseButton_margin": "0px",
                  "popupCloseButton_action": {
                      "name": "close_popup",
                      "attrs": {
                          "onClick": "document.querySelector('.u-popup-container').style.display = 'none';"
                      }
                  },
                  "language": {},
                  "backgroundColor": "#F7F8F9",
                  "preheaderText": "",
                  "linkStyle": {
                      "body": true,
                      "linkColor": "#0000ee",
                      "linkHoverColor": "#0000ee",
                      "linkUnderline": true,
                      "linkHoverUnderline": true
                  },
                  "backgroundImage": {
                      "url": "",
                      "fullWidth": true,
                      "repeat": "no-repeat",
                      "size": "custom",
                      "position": "center"
                  },
                  "_meta": {
                      "htmlID": "u_body",
                      "htmlClassNames": "u_body"
                  }
              }
          },
          "schemaVersion": 17
      };
        badgeEditorRef.current.editor.loadDesign(emptyDesign);
      }
    }
  }, [template]);

  useEffect(() => {
    if (badgeEditorRef.current?.editor && template?.template && tags) {

      badgeEditorRef.current.editor.setMergeTags(tags);
      badgeEditorRef.current.editor.loadDesign(template.template);
    }
  }, [template])

  const { createTenantAdminApiRequest } = useTenantApi();
	const fetchTemplates = async () => {
    const response = await getbBadgeTemplates(createTenantAdminApiRequest)();
    const badge_templates: BadgeTemplatesData = await response.items?.[0];
    if (badge_templates.success && badge_templates.badgeTemplates) {
      setBadgeTemplates(badge_templates.badgeTemplates);
      setTags(badge_templates.tags);
      if (badgeEditorRef.current?.editor && badge_templates.tags) {
        badgeEditorRef.current.editor.setMergeTags(badge_templates.tags);
      }
    }
  };

	return (
		<Box mx={2} my={10}>
			<BadgeBuilderNav
            badgeTemplates={badgeTemplates}
            disabled={!ready}
            badgeEditorRef={badgeEditorRef}
            template={template}
            setTemplate={setTemplate}
            fetchTemplates={fetchTemplates}
			/>
			<EmailEditor ref={badgeEditorRef} onReady={onReady} />
		</Box>
	)
}

export default BadgeDesignBuilder;
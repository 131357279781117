import { type FC, useState, useCallback, useRef } from 'react';
import * as Yup from 'yup';
import { Formik, type FormikProps, type FormikHelpers } from 'formik';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { omit } from 'lodash';

import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';

import { type ApiResponseSingle } from '@/api/tenantClient';
import { type EventWebsiteTenantUpdateRequest, type EventWebsiteTenant, updateEventWebsiteTenant, createEventWebsiteTenant, type EventWebsiteTenantNewRequest, tenantListQueryKey } from '@/api/account/tenant';

import BaseFormInputField from '@/base/Form/InputField';
import { UiBox, UiStack } from '@/lib/ui';
import BaseFormCheckboxListField from '@/base/Form/CheckboxListField';
import BaseFormImageSelectField from '@/base/Form/ImageSelectField';
import { toBase64 } from '@/lib/util';
import { useGlobalToast } from '@/lib/hook';
import { validateFile } from '../../Event/EventForm';
import { type FileOrUrl } from '@/base/ImageSelect/ImageSelectItem';
import { useTenantRoute } from '@/account/hook/useTenantRoute';

export interface TenantFormData {
  name: string
  createdAt: string
  updatedAt: string
  blackLogo: FileOrUrl[]
  favicon: FileOrUrl[]
  metaLogo: FileOrUrl[]
  appLogo: FileOrUrl[]
  headerBannerImage: FileOrUrl[]
  phone: string
  addressStreet1: string
  addressCity: string
  addressPostCode: string
  addressCountry: string
  active: string
  registrationEnabled: string
  shortUserRegistration: string
  gdprCompliance: string
  perEventRegistration: string
  eventSitemap: string
  smsNotifications: string
  dashboard: string
  ivsEnabled: string
  alertOnMailUnsubscribe: string
  featureFlagEmailBuilder: string
  samlSsoEnabled: string
  primaryColour: string
  secondaryColour: string
  colourEmailHeader?: string
  colourEmailFooter?: string
  colourEventHubBannerColour?: string
  facebookDomainVerificationKey?: string
  privacyPolicy?: string
}

const emptyFormData: TenantFormData = {
  name: '',
  createdAt: '',
  updatedAt: '',
  blackLogo: [],
  favicon: [],
  metaLogo: [],
  appLogo: [],
  headerBannerImage: [],
  phone: '',
  addressStreet1: '',
  addressCity: '',
  addressPostCode: '',
  addressCountry: '',
  active: 'false',
  registrationEnabled: 'false',
  shortUserRegistration: 'false',
  gdprCompliance: 'false',
  perEventRegistration: 'false',
  eventSitemap: 'false',
  smsNotifications: 'false',
  dashboard: 'false',
  ivsEnabled: 'false',
  alertOnMailUnsubscribe: 'false',
  featureFlagEmailBuilder: 'false',
  samlSsoEnabled: 'false',
  facebookDomainVerificationKey: '',
  primaryColour: '#000000',
  secondaryColour: '#000000',
  colourEventHubBannerColour: '#000000',
};

const defaultOptions = [
  {
    label: 'Enabled',
    value: 'true',
  },
  {
    label: 'Disabled',
    value: 'false',
  },
];

interface TenantFormProps {
  tenant: EventWebsiteTenant | null
  onClose: () => void
  onSaveSuccess: () => void
  isVisible: boolean
}

const formSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required.')
    .min(3, 'Name is too short (minimum is 3 characters)'),
  shortName: Yup.string().required('Tenant initials'),
  businessNumberLabel: Yup.string().required('Tax identifier/name is required.'),
  businessNumber: Yup.string().required('Company business number is required.'),
  websiteUrl: Yup.string().required('Website url is required.').url('Invalid url.'),
  invoiceName: Yup.string().required('Invoice name is required.'),
  addressStreet1: Yup.string().required('Address street 1 is required.'),
  addressCity: Yup.string().required('City is required.'),
  addressState: Yup.string().required('State is required.'),
  addressPostCode: Yup.string().required('Postcode is required.'),
  addressCountry: Yup.string().required('Country is required.'),
  metaTitle: Yup.string().required('Meta title is required.'),
  metaDescription: Yup.string().required('Meta description is required.'),
  metaKeywords: Yup.string().required('Meta keywords is required.'),
  primaryColour: Yup.string().required('Primary colour is required.'),
  secondaryColour: Yup.string().required('Secondary colour is required.'),
  metaLogo: Yup.mixed().required('Meta logo is required.').test(
    'fileSize',
    'File size is too large (max 10MB)',
    async (value) => { return await validateFile(value as File[], 1920, 1080, 'tmpRegistrationBanner', false); }
  ),
  appLogo: Yup.mixed().nullable().required('App logo is required.').test(
    'fileSize',
    'File size is too large (max 10MB)',
    async (value) => { return await validateFile(value as File[], 1920, 1080, 'tmpRegistrationBanner', false); }
  ),
});

const TenantForm: FC<TenantFormProps> = ({
  tenant,
  onClose,
  isVisible,
  onSaveSuccess,
}) => {
  const queryClient = useQueryClient();
  const formRef = useRef<FormikProps<TenantFormData>>(null);
  const { tenantCode } = useTenantRoute();
  const scrollErrorRef = useRef<HTMLDivElement>(null);
  const { showToast } = useGlobalToast();
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const { reportToGlobal } = useApiErrorHandler();
  const { createTenantAdminApiRequest } = useTenantApi();

  const { mutate, isLoading } = useMutation<ApiResponseSingle<EventWebsiteTenant>, Error, EventWebsiteTenantUpdateRequest>(
    async (data: EventWebsiteTenantUpdateRequest) => {
      return await updateEventWebsiteTenant(createTenantAdminApiRequest)(data);
    },
    {
      onSuccess: (data) => {
        if ((data?.errors?.length ?? 0) > 0) {
          setSaveErrors([...data.errors ?? []]);
          scrollErrorRef.current?.scrollIntoView({ behavior: 'smooth' });
          return;
        }
        showToast.success('Tenant updated successfully');
        void queryClient.invalidateQueries({ queryKey: [tenantListQueryKey, { tenantCode }] });
        onClose();
      },
      onError: (error: Error) => {
        reportToGlobal(error);
        setSaveErrors([error.message ?? 'Failed to save the note.']);
      }
    }
  );

  const { mutate: createTenantMutation, isLoading: isCreateLoading } = useMutation<ApiResponseSingle<EventWebsiteTenant>, Error, EventWebsiteTenantNewRequest>(
    async (data: EventWebsiteTenantNewRequest) => {
      return await createEventWebsiteTenant(createTenantAdminApiRequest)(data);
    },
    {
      onSuccess: async (data) => {
        if ((data?.errors?.length ?? 0) > 0) {
          setSaveErrors([...data.errors ?? []]);
          scrollErrorRef.current?.scrollIntoView({ behavior: 'smooth' });
          return;
        }
        void queryClient.invalidateQueries({ queryKey: [tenantListQueryKey, { tenantCode }] });
        onSaveSuccess();
        if (formRef.current) {
          formRef.current?.resetForm();
        }
        showToast.success('Tenant created successfully');
        setSaveErrors([]);
        onClose();
      },
      onError: (error: Error) => {
        reportToGlobal(error);
        setSaveErrors([error.message ?? 'Failed to save the note.']);
      }
    }
  );

  const getInitValues = useCallback(() => {
    if (tenant) {
      const tenantImage = {
        blackLogo: tenant?.blackLogo ? [tenant.blackLogo] : [],
        favicon: tenant?.favicon ? [tenant.favicon] : [],
        metaLogo: tenant?.metaLogo ? [tenant.metaLogo] : [],
        appLogo: tenant?.appLogo ? [tenant.appLogo] : [],
        headerBannerImage: tenant?.headerBannerImage ? [tenant.headerBannerImage] : [],
      };
      return {
        ...tenant,
        ...tenantImage,
        name: tenant?.name ?? '',
        createdAt: tenant?.createdAt ?? '',
        updatedAt: tenant?.updatedAt ?? '',
        phone: tenant?.phone ?? '',
        addressStreet1: tenant?.addressStreet1 ?? '',
        addressCity: tenant?.addressCity ?? '',
        addressPostCode: tenant?.addressPostCode ?? '',
        addressCountry: tenant?.addressCountry ?? '',
        active: tenant?.active ? 'true' : 'false',
        facebookDomainVerificationKey: tenant?.facebookDomainVerificationKey ?? '',
        registrationEnabled: tenant?.registrationEnabled ? 'true' : 'false',
        shortUserRegistration: tenant?.shortUserRegistration ? 'true' : 'false',
        gdprCompliance: tenant?.gdprCompliance ? 'true' : 'false',
        perEventRegistration: tenant?.perEventRegistration ? 'true' : 'false',
        eventSitemap: tenant?.eventSitemap ? 'true' : 'false',
        smsNotifications: tenant?.smsNotifications ? 'true' : 'false',
        dashboard: tenant?.dashboard ? 'true' : 'false',
        ivsEnabled: tenant?.ivsEnabled ? 'true' : 'false',
        alertOnMailUnsubscribe: tenant?.alertOnMailUnsubscribe ? 'true' : 'false',
        featureFlagEmailBuilder: tenant?.featureFlagEmailBuilder ? 'true' : 'false',
        samlSsoEnabled: tenant?.samlSsoEnabled ? 'true' : 'false',
        privacyPolicy: tenant?.privacyPolicy ?? '',
        colourEmailFooter: tenant?.colourEmailFooter ?? '#000000',
        colourEventHubBannerColour: tenant?.colourEventHubBannerColour ?? '#000000',
      } satisfies Partial<TenantFormData>;
    }

    return emptyFormData;
  }, [tenant]);

  const onSubmit = useCallback(async (
    values: TenantFormData,
    { setSubmitting, resetForm }: FormikHelpers<TenantFormData>
  ) => {
    const tenantImage: {
      tmpBlackLogo?: string
      tmpFavicon?: string
      tmpMetaLogo?: string
      tmpAppLogo?: string
      tmpHeaderBannerImage?: string
    } = {
      ...(tenant?.blackLogo !== values?.blackLogo?.[0] && { tmpBlackLogo: values.blackLogo[0] instanceof File ? await toBase64(values.blackLogo[0]) : values.blackLogo[0] }),
      ...(tenant?.favicon !== values?.favicon?.[0] && { tmpFavicon: values.favicon[0] instanceof File ? await toBase64(values?.favicon?.[0]) : values.favicon[0] }),
      ...(tenant?.metaLogo !== values?.metaLogo?.[0] && { tmpMetaLogo: values.metaLogo[0] instanceof File ? await toBase64(values?.metaLogo?.[0]) : values.metaLogo[0] }),
      ...(tenant?.appLogo !== values?.appLogo?.[0] && { tmpAppLogo: values.appLogo[0] instanceof File ? await toBase64(values?.appLogo?.[0]) : values.appLogo[0] }),
      ...(tenant?.headerBannerImage !== values?.headerBannerImage?.[0] && { tmpHeaderBannerImage: values.headerBannerImage[0] instanceof File ? await toBase64(values.headerBannerImage[0]) : values.headerBannerImage[0] }),
    };

    const ommitedTenant = omit(tenant, ['blackLogo', 'favicon', 'metaLogo', 'appLogo', 'headerBannerImage']);
    const omittedValues = omit(values, ['blackLogo', 'favicon', 'metaLogo', 'appLogo', 'headerBannerImage']);

    if (tenant?.id) {
      mutate({ tenant: { ...ommitedTenant, ...omittedValues, ...tenantImage } });
    } else {
      createTenantMutation({ tenant: { ...omittedValues, ...tenantImage } });
    }
  }, [createTenantMutation, mutate, tenant]);

  return (
    <Formik<TenantFormData>
      innerRef={formRef}
      initialValues={getInitValues()}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={formSchema}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {(form) => {
        return <BaseFormDrawer
          isOpen={isVisible}
          onClose={() => {
            setSaveErrors([]);
            onClose();
          }}
          title={'Tenant'}
          size={'xl'}
          isLoading={isLoading || isCreateLoading}
        >
          <div ref={scrollErrorRef} />
          {saveErrors.length > 0 && (
            <UiStack spacing={4} flexGrow={1} py={4}>
              {saveErrors.map((error, index) => {
                return (
                  <BaseMessageBarError key={index}>
                    {error}
                  </BaseMessageBarError>
                );
              })}
            </UiStack>
          )}
          <BaseFormFieldGroup>
            <BaseFormInputField
              name={'name'}
              label={'Tenant name'}
            />
            <BaseFormInputField
              name={'shortName'}
              label={'Tenant initials'}
            />
            <BaseFormInputField
              name={'businessNumberLabel'}
              label={'Tax identifier/name'}
            />
            <BaseFormInputField
              name={'businessNumber'}
              label={'Company business number'}
            />
            <BaseFormInputField
              name={'helpEmail'}
              label={'Support email'}
              isRequired={false}
            />
            <BaseFormInputField
              name={'emailAccounts'}
              label={'Accounts email'}
              isRequired={false}
            />
            <BaseFormInputField
              name={'phone'}
              label={'Accounts phone number'}
              isRequired={false}
            />
            <BaseFormInputField
              name={'websiteUrl'}
              label={'Website'}
            />
            <BaseFormInputField
              name={'twitter'}
              label={'X profile'}
              isRequired={false}
            />
            <BaseFormInputField
              name={'instagram'}
              label={'Instagram profile'}
              isRequired={false}
            />
            <BaseFormInputField
              name={'facebook'}
              label={'Facebook profile'}
              isRequired={false} />
            <BaseFormInputField
              name={'linkedin'}
              label={'LinkedIn profile'}
              isRequired={false} />
            <BaseFormInputField
              name={'invoiceName'}
              label={'Invoice name'}
            />
            <BaseFormInputField
              name={'addressStreet1'}
              label={'Address line 1'}
            />
            <BaseFormInputField
              name={'addressStreet2'}
              label={'Address line 2'}
              isRequired={false}
            />
            <BaseFormInputField
              name={'addressCity'}
              label={'Suburb/city'}
            />
            <BaseFormInputField
              name={'addressState'}
              label={'State'}
            />
            <BaseFormInputField
              name={'addressPostCode'}
              label={'Postcode'}
            />
            <BaseFormInputField
              name={'addressCountry'}
              label={'Country'}
            />
            <BaseFormInputField
              name={'scheduleNickname'}
              label={'Agenda label'}
              isRequired={false}
            />
            <BaseFormInputField
              name={'nicknameAccount'}
              label={'Account label'}
              isRequired={false}
            />
            <BaseFormInputField
              name={'nicknameProfile'}
              label={'Profile label'}
              isRequired={false}
            />
            <BaseFormInputField
              name={'nicknameMyAccount'}
              label={'My account label'}
              isRequired={false}
            />
            <BaseFormInputField
              name={'nicknameMyProfile'}
              label={'My profile label'}
              isRequired={false}
            />
            <BaseFormInputField
              name={'clientIdLabel'}
              label={'Client ID'}
              isRequired={false}
            />
            <BaseFormInputField
              name={'metaTitle'}
              label={'Meta title'}
            />
            <BaseFormInputField
              name={'metaDescription'}
              label={'Meta description'}
            />
            <BaseFormInputField
              name={'metaKeywords'}
              label={'Meta keywords'}
            />
            <BaseFormInputField
              name={'googleAnalyticsKey'}
              label={'Google analytics key'}
              isRequired={false}
            />
            <BaseFormInputField
              name={'googleTagManagerKey'}
              label={'Google tag manager key'}
              isRequired={false}
            />
            <BaseFormInputField
              name={'facebookPixelKey'}
              label={'Facebook pixel key'}
              isRequired={false}
            />
            <BaseFormInputField
              name={'facebookDomainVerificationKey'}
              label={'Facebook domain verification key'}
              isRequired={false}
            />
            <BaseFormInputField
              name={'jwtRedirectUrl'}
              label={'JWT redirect url'}
              isRequired={false}
            />
            <BaseFormInputField
              name={'primaryColour'}
              label={'Primary colour'}
              maxLength={7}
              isRequired
              rightElement={<UiBox
                width="35px"
                height="35px"
                background={typeof form?.values?.primaryColour === 'string' ? form.values.primaryColour : '#000000'}
                borderRadius="md"
                border="1px"
                borderColor="gray.200"
              />}
            />
            <BaseFormInputField
              name={'secondaryColour'}
              label={'Secondary colour'}
              maxLength={7}
              isRequired
              rightElement={<UiBox
                width="35px"
                height="35px"
                background={typeof form?.values?.secondaryColour === 'string' ? form.values.secondaryColour : '#000000'}
                borderRadius="md"
                border="1px"
                borderColor="gray.200"
              />}
            />
            <BaseFormInputField
              name={'colourEmailHeader'}
              label={'Email header colour'}
              maxLength={7}
              isRequired={false}
              rightElement={<UiBox
                width="35px"
                height="35px"
                background={typeof form?.values?.colourEmailHeader === 'string' ? form.values.colourEmailHeader : '#000000'}
                borderRadius="md"
                border="1px"
                borderColor="gray.200"
              />}
            />
            <BaseFormInputField
              name={'colourEmailFooter'}
              label={'Email footer colour'}
              maxLength={7}
              isRequired={false}
              rightElement={<UiBox
                width="35px"
                height="35px"
                background={typeof form?.values?.colourEmailFooter === 'string' ? form.values.colourEmailFooter : '#000000'}
                borderRadius="md"
                border="1px"
                borderColor="gray.200"
              />}
            />
            <BaseFormInputField
              name={'colourEventHubBannerColour'}
              label={'Event hub banner colour'}
              maxLength={7}
              isRequired={false}
              rightElement={<UiBox
                width="35px"
                height="35px"
                background={typeof form?.values?.colourEventHubBannerColour === 'string' ? form.values.colourEventHubBannerColour : '#000000'}
                borderRadius="md"
                border="1px"
                borderColor="gray.200"
              />}
            />
            <BaseFormCheckboxListField
              name={'gdprCompliance'}
              label={'GDPR compliance'}
              options={defaultOptions}
              isMultiple={false}
              isRequired={false}
            />
            <BaseFormInputField
              name={'gdprCookieBannerHeader'}
              label={'Cookie banner header'}
              isRequired={false}
            />
            <BaseFormInputField
              name={'gdprCookieBannerText'}
              label={'Cookie banner text'}
              isRequired={false}
            />
            <BaseFormInputField
              name={'privacyPolicyLink'}
              label={'Privacy policy url'}
              isRequired={false}
            />
            <BaseFormCheckboxListField
              name={'samlSsoEnabled'}
              label={'Enable SAML SSO'}
              options={defaultOptions}
              isMultiple={false}
              isRequired={false}
            />
            <BaseFormCheckboxListField
              name={'ivsEnabled'}
              label={'Enable automated IVS channel creation'}
              options={defaultOptions}
              isMultiple={false}
              isRequired={false}
            />
            {/* There should be only one tenant and this one tenant should be active, no need to supply the ability to change tenant status */}
            {/* <BaseFormCheckboxListField
              name={'active'}
              label={'Tenant status'}
              options={defaultOptions}
              isMultiple={false}
              isRequired={false}
            /> */}
            <BaseFormCheckboxListField
              name={'smsNotifications'}
              label={'Enable SMS notifications'}
              options={defaultOptions}
              isMultiple={false}
              isRequired={false}
            />
            <BaseFormCheckboxListField
              name={'dashboard'}
              label={'Enable events hub'}
              options={defaultOptions}
              isMultiple={false}
              isRequired={false}
            />
            <BaseFormImageSelectField
              name={'blackLogo'}
              label={'Logo image'}
              isRequired={false}
              helperText='Please upload file under format: jpg, jpeg, png, gif. Size limit: 1920x1080 10MB'
              hintText='This is the logo that will appear on the app'
            />
            <BaseFormImageSelectField
              name={'favicon'}
              label={'Favicon'}
              isRequired={false}
              helperText='Please upload file under format: ico. Size limit: 16x16 10MB'
              hintText='This is the icon that will appear on the browser tab'
            />
            <BaseFormImageSelectField
              name={'metaLogo'}
              label={'Meta logo'}
              helperText='Please upload file under format: jpg, jpeg, png, gif. Size limit: 1920x1080 10MB'
              hintText='This is the logo that will appear on the app'
            />
            <BaseFormImageSelectField
              name={'appLogo'}
              label={'App logo'}
              helperText='Please upload file under format: jpg, jpeg, png, gif. Size limit: 1920x1080 10MB'
              hintText='This is the logo that will appear on the app'
            />
            <BaseFormImageSelectField
              name={'headerBannerImage'}
              label={'Email header banner image'}
              helperText='Please upload file under format: jpg, jpeg, png, gif. Size limit: 1920x1080 10MB'
              hintText='This is the logo that will appear on the app'
              isRequired={false}
            />
          </BaseFormFieldGroup>
        </BaseFormDrawer>;
      }}
    </Formik>
  );
};

export default TenantForm;

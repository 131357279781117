import { useCallback, useEffect, useState, type FC } from 'react';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import useFields from '@/base/FormBuilder/useFields';
import { InfoFormType, type InfoForm, type InfoFormSaveRequest, initAdditionalFieldsLayout, initAdditionalFieldsMetadata } from '@/api/registration';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { registration } from '@/api';
import { type Layout } from 'react-grid-layout';
import { type FieldMetadata } from '@/base/FormBuilder/Field';
import {
  UiHStack,
  UiIconArrowSquareOut,
  UiIconCaretLeft,
  UiLink,
  UiStack,
  UiText,
  uiStyles,
  UiIconX,
  UiIconEye,
  UiVStack,
  UiFormLabel,
  UiInput,
  UiBox,
} from '@/lib/ui';
import BaseRouterLink from '@/base/Router/Link';
import { generatePageUrl } from '@/app/pages';
import BaseFormBuilder from '@/base/FormBuilder';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDisclosure } from '@chakra-ui/react';
import FullScreen from '@/base/Modal/FullScreen';
import FormPreview from '@/base/FormGenerator/FormPreview';
import BaseFloatingContainerTop from '@/base/FloatingContainer/Top';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { type ApiResponseSingle } from '@/api/tenantClient';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';

const BuildAdditionalInfoForm: FC = () => {
  const navigate = useNavigate();
  const { tenantCode, eventId } = useRegisterRoute();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const queryClient = useQueryClient();
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();
  const [searchParams] = useSearchParams();
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const id = Number(searchParams.get('id'));

  const infoFormQuery = useQuery<InfoForm, Error>(
    [registration.infoFormQueryKey, { id }],
    async () => {
      const result = await registration.loadInfoForm(createTenantAdminApiRequest)(id);
      return result.item;
    },
    {
      enabled: !isApiPreparing && !!id
    }
  );

  const [name, setName] = useState(infoFormQuery?.data?.name ?? '');
  useEffect(() => {
    if (infoFormQuery.data) {
      setName(infoFormQuery.data.name);
    }
  }, [infoFormQuery.data]);

  const {
    fieldsLayout,
    fieldsMetadata,
    addField,
    setFieldsLayout,
    deleteField,
    updateFieldMetadata
  } = useFields(
    (infoFormQuery?.data?.config.fieldsLayout ?? initAdditionalFieldsLayout),
    (infoFormQuery?.data?.config?.fieldsMetadata ?? initAdditionalFieldsMetadata)
  );

  const { mutateAsync } = useMutation<ApiResponseSingle<InfoForm>, Error, InfoFormSaveRequest>({
    mutationFn: async (data: InfoFormSaveRequest) => {
      setSaveErrors([]);
      return await registration.saveInfoForm(createTenantAdminApiRequest)(data);
    },
    onSuccess: (result) => {
      if (result?.errors && Array.isArray(result?.errors) && result?.errors.length > 0) {
        setSaveErrors(result?.errors);
      } else {
        void queryClient.invalidateQueries({ queryKey: [registration.infoFormsQueryKey, { eventId, formType: InfoFormType.ADDITIONAL }] });
        void (!!id && queryClient.invalidateQueries({ queryKey: [registration.infoFormQueryKey, { id }] }));
        navigate(generatePageUrl('RegistrationBuildEventAdditionalInfoForm', { tenantCode, eventId }));
      }
    },
    onError: () => {
      // setSaveErrors([error.message ?? 'Failed to save the attendee group.']);
    }
  });

  const onFieldAdd = (newFieldsMetadata: FieldMetadata) => {
    let layout: Layout = { x: 0, y: Infinity, w: 1, h: 1, maxH: 1, i: newFieldsMetadata.id };
    if (newFieldsMetadata.type === 'sectionBreak') {
      layout = { x: 0, y: Infinity, w: 2, h: 1, maxH: 1, minW: 2, maxW: 2, i: newFieldsMetadata.id };
    }
    addField(layout, newFieldsMetadata);
  };

  const handleSaveAndGoback = useCallback(async () => {
    if (!name) {
      setSaveErrors(['Form name is required']);
    } else {
      setSaveErrors([]);
      await mutateAsync({
        infoForm: {
          ...(id ? { id } : {}),
          formType: InfoFormType.ADDITIONAL,
          eventId,
          config: JSON.stringify({
            totalFields: fieldsLayout.length,
            fieldsLayout,
            fieldsMetadata,
          }),
          name,
        },
      });
    }
  }, [eventId, fieldsLayout, fieldsMetadata, id, name, mutateAsync]);

  return (
    <>
      <UiStack
        // width={1200}
        bgColor={'gray.100'}
        alignItems={'stretch'}
        borderRadius={uiStyles.borderRadius}
        minHeight={'100vh'}
        flexGrow={1}
        // p={8}
        pb={12}
      >
        <BaseFloatingContainerTop heightOffset={1}>
          <UiStack
            spacing={0}
            // borderRadius={uiStyles.bodyRadius}
            bgColor={'#fff'}
          >
            <UiHStack
              px={8}
              py={6}
              alignItems={'stretch'}
              justifyContent={'space-between'}
            >
              <UiBox onClick={handleSaveAndGoback} {...uiStyles.hover}>
                <UiHStack>
                  <UiIconCaretLeft color={'primary.500'} />
                  <UiText variant={'body1'} color={'primary.400'}>Save & go back</UiText>
                </UiHStack>
              </UiBox>
              <BaseRouterLink to={generatePageUrl('RegistrationBuildEventAdditionalInfoForm', { tenantCode, eventId })}>
                <UiHStack {...uiStyles.hover}>
                  <UiIconX color={'red.500'} size={'2xl'}/>
                </UiHStack>
              </BaseRouterLink>
            </UiHStack>
          </UiStack>
        </BaseFloatingContainerTop>

        {/*<UiStack*/}
        {/*  position={'fixed'}*/}
        {/*  alignItems={'stretch'}*/}
        {/*  width={'100%'}*/}
        {/*  zIndex={999}*/}
        {/*  bgColor={'gray.100'}*/}
        {/*>*/}
        {/*  <UiStack*/}
        {/*    px={8}*/}
        {/*    py={4}*/}
        {/*    // bgColor={'#fff'}*/}
        {/*    {...uiStyles.topBar}*/}
        {/*    // shadow={'base'}*/}
        {/*  >*/}
        {/*    <UiHStack alignItems={'stretch'} justifyContent={'space-between'}>*/}
        {/*      <BaseRouterLink to={generatePageUrl('RegistrationBuildEventAdditionalInfoForm', { tenantCode, eventId })}>*/}
        {/*        <UiHStack {...uiStyles.hover} onClick={onSaveAndGoBack}>*/}
        {/*          <UiIconCaretLeft color={'primary.500'} weight={'bold'}/>*/}
        {/*          <UiText variant={'body1'} color={'primary.400'} fontWeight={600}>Save & go back</UiText>*/}
        {/*        </UiHStack>*/}
        {/*      </BaseRouterLink>*/}
        {/*      <UiLink*/}
        {/*        color={'primary.500'}*/}
        {/*        fontSize={'xl'}*/}
        {/*        fontWeight={'bold'}*/}
        {/*        onClick={onOpen}*/}
        {/*        target={'_blank'}*/}
        {/*      >*/}
        {/*        <UiHStack {...uiStyles.hover}>*/}
        {/*          <UiText variant={'body1'} color={'primary.500'} fontWeight={600}>Preview</UiText>*/}
        {/*          <UiIconArrowSquareOut color={'primary.500'} weight={'bold'}/>*/}
        {/*        </UiHStack>*/}
        {/*      </UiLink>*/}
        {/*    </UiHStack>*/}
        {/*  </UiStack>*/}
        {/*</UiStack>*/}
        <UiStack
          spacing={4}
          flexGrow={1}
          alignItems={'center'}
          pt={8}
        >
          <UiHStack {...uiStyles.hover} onClick={onOpen} width={1080} justifyContent={'flex-end'}>
            <UiIconEye color={'primary.500'}/>
            <UiText variant={'body1'} color={'primary.500'}>Preview</UiText>
          </UiHStack>
          <UiStack
            width={1080}
            alignItems={'stretch'}
            spacing={4}
          >
            {saveErrors.length > 0 && (
              <UiStack spacing={4} flexGrow={1} py={4}>
                {saveErrors.map((error, index) => {
                  return (
                    <BaseMessageBarError key={index}>
                      {error}
                    </BaseMessageBarError>
                  );
                })}
              </UiStack>
            )}
            <UiStack
              // bgColor={'#fff'}
              // p={8}
              // alignItems={'center'}
              borderRadius={uiStyles.borderRadius}
              spacing={0}
            >
              <UiHStack spacing={0}>
                <UiFormLabel>Form name *</UiFormLabel>
              </UiHStack>
              <UiInput
                value={name}
                onChange={(event) => { setName(event.target.value); }}
                type={'text'}
              />
            </UiStack>
            <BaseDividerHorizontal height={4} />
            <BaseFormBuilder
              onLayoutChange={setFieldsLayout}
              onFieldUpdate={updateFieldMetadata}
              fieldsLayout={fieldsLayout}
              fieldsMetadata={fieldsMetadata}
              onFieldAdd={onFieldAdd}
              onFieldDelete={deleteField}
            />
          </UiStack>
        </UiStack>
        <FullScreen isOpen={isOpen} onClose={onClose}>
          <FormPreview fieldsLayout={fieldsLayout} fieldsMetadata={fieldsMetadata} />
        </FullScreen>
      </UiStack>
    </>
  );
};

export default BuildAdditionalInfoForm;

import { type BackgroundProps, type EffectProps } from '@chakra-ui/react';

export interface HoverFlexOptions {
  bgColor?: BackgroundProps['bgColor']
  opacity?: EffectProps['opacity']
  scale?: number
}

export interface LiftOptions {
  isEnabled: boolean
}

export const uiStyles = {
  hover: {
    // transform: 'opacity',
    transition: 'all .2s ease-in-out',
    // transitionDuration: '0.2s',
    // transitionTimingFunction: 'ease-in-out',
    opacity: 1,
    _hover: {
      opacity: 0.7,
      // transform: 'opacity',
      transition: 'all .2s ease-in-out',
      // transitionDuration: '0.2s',
      // transitionTimingFunction: 'ease-in-out',
      cursor: 'pointer',
    }
  },
  hoverShadow: {
    transition: 'all .2s ease-in-out',
    transform: 'translateY(0)',
    // opacity: 1,
    _hover: {
      shadow: 'md',
      transform: 'translateY(-1px)',
      cursor: 'pointer',
      // opacity: 0.8,
    }
  },
  hoverFlex: (options: HoverFlexOptions) => {
    const transform = options.scale ? `scale(${options.scale})` : '';
    return {
      transition: 'all .2s ease-in-out',
      _hover: {
        bgColor: options.bgColor ?? 'none',
        transition: 'all .2s ease-in-out',
        transform,
        opacity: options.opacity ?? 1,
        cursor: 'pointer'
      }
    };
  },
  lift: (options: LiftOptions) => {
    return {
      transform: `translateY(${options.isEnabled ? '-12px' : 0})`,
      shadow: options.isEnabled ? 'xl' : uiStyles.cardShadow,
    };
  },
  glass: {
    // shadow: 'base',
    // bgColor: 'whiteAlpha.600',
    bgColor: 'gray.100',
    // borderTopWidth: '2px',
    // borderTopColor: 'whiteAlpha.400',
    // borderStyle: 'solid',
  },
  transition: {
    zoomIn: {
      transform: 'scale'
    }
  },
  layoutPadding: {
    px: { base: 8, '2xl': 12 },
    py: 6
  },
  buttonRadius: '3vmin',
  borderRadius: 16,
  bodyRadius: 16, // This is the page body radius.
  listRadius: 16,
  formElementBorderRadius: 8,
  cardShadow: 'base',
  topBar: {
    // bgGradient: 'radial(35% 100% at 0% 35%, brand.50 25%, gray.50 100%)',
    // bgGradient: 'radial(200% 100% at 0% 35%, brand.50 25%, gray.200 100%)',
    // bgColor: 'brand.50',
    // borderBottomWidth: '1px',
    // borderBottomColor: 'gray.300',
    // borderStyle: 'solid',
    bgColor: '#fff',
    shadow: 'md',
  },
  borderCard: {
    borderWidth: '1px',
    borderColor: 'gray.300',
    borderStyle: 'solid',
    shadow: 'none',
  },
};

import { type FC, useCallback, useEffect } from 'react';
import BaseLayout from '@/base/Layout';
import {
  UiHStack,
  UiStack,
  UiButton,
  UiText,
  uiStyles
} from '@/lib/ui';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import ManageEventLayoutBody from '@/registration/component/BuildEvent/LayoutBody';
import ManageEventSteps from '@/registration/component/BuildEvent/Steps';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import NewLocale from './NewLocale';
import LocaleList from './LocaleList';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useIsSetMutation } from '@/registration/hook/useIsSetMutation';
import { useBuildEventSteps } from '@/registration/hook/useBuildEventSteps';
import StepInstruction from '@/registration/component/BuildEvent/StepInstruction';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import { useEventSettingsQuery } from '@/registration/hook/useEventSettingsQuery';
import { PermissionAction, PermissionDomain } from '@/api/constant/adminUserPermission';
import BaseAclContainer from '@/account/component/AclContainer';
import NewTaxRate from '@/registration/buildEvent/Locales/NewTaxRate';
import TaxRateList from '@/registration/buildEvent/Locales/TaxRateList';
import CurrencyList from './CurrencyList';
import NewCurrency from './NewCurrency';

const Locales: FC = () => {
  const { eventId, tenant } = useRegisterRoute();
  const { toNextStep } = useBuildEventSteps();
  const { mutate, isLoading } = useIsSetMutation();
  const { data: eventSettings } = useEventSettingsQuery(eventId);

  const onNext = useCallback(
    () => {
      // mutate({ eventId, key: 'discountCode', bool: true }, {
      //   onSuccess: () => {
      //     toNextStep();
      //   }
      // });
      toNextStep();
    },
    [
      // mutate,
      // eventId,
      toNextStep
    ]
  );

  // Mark the step as set regardless.
  useEffect(
    () => {
      return () => {
        if (eventSettings && !eventSettings.discountCode.isSet) {
          mutate({ eventId, key: 'discountCode', bool: true });
        }
      };
    },
    [eventSettings?.discountCode.isSet]
  );

  return (
    <>
      <BaseLayout
        smartWindow={(<SmartWindow />)}
        // disableTopNav={true}
        appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard showDescription={false} />} />)}
        MainTransition={null}
      >
        <ManageEventLayoutBody
          steps={(
            <ManageEventSteps
              eventId={eventId}
              activeStep={'locales'}
            />
          )}
          message={'Please manage your locales.'}
        >
          <UiStack
            // pt={4}
            spacing={4}
            flexGrow={1}
            // pt={4}
            p={8}
          >
            <UiHStack>
              <StepInstruction label={'Instructions'}>
                <UiStack p={8} py={6} borderRadius={uiStyles.borderRadius} spacing={4} enableDivider={true}>
                  <UiText variant="h5">
                    Locales
                  </UiText>
                  <UiText variant="body1">
                    You can sell your tickets in different currencies by creating locales.
                  </UiText>
                  <UiText variant="body1">
                    You can set the country, currency and the tax for each locale.
                  </UiText>
                </UiStack>
              </StepInstruction>
            </UiHStack>
            <BaseDividerHorizontal height={4} />
            <BaseAclContainer tenantId={tenant?.id ?? 0} permissionsRequired={[[PermissionDomain.Registration, PermissionAction.Write]]}>
              <NewCurrency />
            </BaseAclContainer>
            <CurrencyList />
            <BaseDividerHorizontal height={4} />
            <BaseAclContainer tenantId={tenant?.id ?? 0} permissionsRequired={[[PermissionDomain.Registration, PermissionAction.Write]]}>
              <NewTaxRate />
            </BaseAclContainer>
            <TaxRateList />
            <BaseDividerHorizontal height={4} />
            <BaseAclContainer tenantId={tenant?.id ?? 0} permissionsRequired={[[PermissionDomain.Registration, PermissionAction.Write]]}>
              <NewLocale />
            </BaseAclContainer>
            <LocaleList />
          </UiStack>
          <UiHStack
            // pt={8}
            p={8}
            justifyContent={'space-between'}
            // flexDirection={'row-reverse'}
          >
            <UiStack flexGrow={1} />
            <BaseAclContainer tenantId={tenant?.id ?? 0} permissionsRequired={[[PermissionDomain.Registration, PermissionAction.Write]]}>
              <UiButton
                size={'lg'}
                colorScheme={'primary'}
                type={'submit'}
                fontSize={'lg'}
                onClick={onNext}
                isLoading={isLoading}
              >
                Next
              </UiButton>
            </BaseAclContainer>
          </UiHStack>
        </ManageEventLayoutBody>
      </BaseLayout>
    </>
  );
};

export default Locales;

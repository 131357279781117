import { useDisclosure } from '@chakra-ui/react';
import {
  UiIconPlusCircle, UiStack, UiText,
  UiHStack,
  uiStyles, UiIconPencilSimple, UiIconArrowsLeftRight,
} from '@/lib/ui';
import { useQuery } from '@tanstack/react-query';
import { type FC, useState } from 'react';

import { useTenantApi } from '@/account/hook/useTenantApi';
import NoteList from './NoteList';
import NoteForm from './NoteForm';
import { type AdminNote, loadNotes } from '@/api/admin/note';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import BaseButtonGhost from '@/base/Button/Ghost';
import BaseButtonIconText from '@/base/Button/IconText';

const AdminNotes: FC<{ profileableType: string, profileableId: number | null }> = ({ profileableType, profileableId }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedNote, setSelectedNote] = useState<AdminNote | null>(null);

  const { createTenantAdminApiRequest } = useTenantApi();

  const notesQuery = useQuery<AdminNote[], Error>({
    queryKey: [{ profileableType, profileableId }],
    queryFn: async () => {
      const result = await loadNotes(createTenantAdminApiRequest)({ profileableType, profileableId });
      return result.items ?? [];
    }
  });

  const handleCreate = () => {
    setSelectedNote(null);
    onOpen();
  };

  const onSaveSuccess = () => {
    setSelectedNote(null);
  };

  return (
    <UiStack spacing={4}>
      <UiHStack justifyContent={'flex-start'} spacing={4}>
        <UiText variant={'title'}>Notes</UiText>
        {/*<BaseButtonGhost*/}
        {/*  LeftIcon={UiIconPlusCircle}*/}
        {/*  onClick={handleCreate}*/}
        {/*>*/}
        {/*  Add note*/}
        {/*</BaseButtonGhost>*/}
        <BaseButtonIconText
          Icon={UiIconPlusCircle}
          onClick={handleCreate}
        >
          Add note
        </BaseButtonIconText>
      </UiHStack>
      <QueryContainer query={notesQuery}>
        {(data: AdminNote[]) => {
          return (
            <NoteList
              profileableType={profileableType}
              profileableId={profileableId}
              data={data}
              onSaveSuccess={onSaveSuccess}
            />);
        }}
      </QueryContainer>
      <NoteForm
        profileableType={profileableType}
        profileableId={profileableId}
        note={selectedNote}
        isVisible={isOpen}
        onClose={onClose}
        onSaveSuccess={onSaveSuccess}
      />
    </UiStack>
  );
};

export default AdminNotes;

import { useCallback, useMemo, type FC } from 'react';
import { UiStack, UiText } from '@/lib/ui';
import { Formik, type FormikHelpers } from 'formik';
import { eventQueryKey, type CloneEventRequest, type Event } from '@/api/registration';
import BaseFormSelectField from '@/base/Form/SelectField';
import * as Yup from 'yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { registration } from '@/api';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { type ApiResponse } from '@/api/tenantClient';
import { useNavigate } from 'react-router-dom';
import { generatePageUrl } from '@/app/pages';
import BaseMessageBarError from '@/base/MessageBar/Error';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import BaseFormDrawer from '@/base/Form/Drawer';
import { useEventOptionsQuery } from '@/registration/hook/useEventOptions';

const formSchema = Yup.object().shape({
  eventId: Yup.string().required('Event is required.')
});

interface FormData {
  eventId?: string
}

interface CloneEventFormProps {
  eventId: string
  tenantCode: string
  isVisible: boolean
  onClose: () => void
}

const CloneEventForm: FC<CloneEventFormProps> = ({
  eventId,
  tenantCode,
  isVisible,
  onClose,
}) => {
  const eventListQuery = useEventOptionsQuery();
  const { createTenantAdminApiRequest } = useTenantApi();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const options = useMemo(
    () => {
      return eventListQuery.data?.items
        ?.filter((item) => { return Number(item.id) !== Number(eventId); })
        ?.filter((item) => { return item.cloneable; })
        ?.map(item => { return { value: item.id, label: item.name }; }) ?? [];
    }
    , [eventId, eventListQuery.data?.items]);

  const { mutateAsync: cloneEvent, isLoading, error } = useMutation<ApiResponse<Event>, Error, CloneEventRequest>({
    mutationFn: async (request) => {
      return await registration.cloneEvent(createTenantAdminApiRequest)(request);
    },
    onSuccess: (data) => {
      if (data.errors && data.errors.length > 0) {
        throw new Error(data.errors.join(', '));
      }
      void queryClient.invalidateQueries({ queryKey: [eventQueryKey, { eventId }] });
    },
    onError: () => {
      // setErrors([error.message ?? 'Failed to save the attendee group.']);
      // setErrors(['Login failed (connection error).']);
    }
  });

  const onSubmit = useCallback(async (
    values: FormData,
    { setSubmitting }: FormikHelpers<FormData>
  ) => {
    setSubmitting(true);
    await cloneEvent({ eventFromId: values.eventId!.toString(), eventToId: eventId });
    onClose();
    navigate(generatePageUrl('RegistrationBuildEventAttendeeCategories', { tenantCode, eventId }));
    setSubmitting(false);
  }, [cloneEvent, eventId, navigate, onClose, tenantCode]);

  return (
    <Formik<FormData>
      initialValues={{}}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={formSchema}
      onSubmit={onSubmit}
    >
      <BaseFormDrawer
        isOpen={isVisible}
        onClose={onClose}
        title={'Choose an event to clone'}
        size={'lg'}
        isLoading={isLoading}
        buttonText={'Clone'}
        buttonLoadingText={'Cloning'}
      >
        <QueryContainer query={eventListQuery}>
          {() => {
            return (
              <UiStack flexGrow={1}>
                {error && (
                  <UiStack spacing={4} flexGrow={1} py={4}>
                    <BaseMessageBarError key={error.message}>
                      {error.message}
                    </BaseMessageBarError>
                  </UiStack>
                )}
                <BaseFormSelectField
                  name={'eventId'}
                  label={'Event'}
                  layout="stack"
                  options={options}
                />
                <UiText variant={'body1'} color={'text.secondary'}>
                  This list only includes events that can be cloned, empty events with no content can not be cloned
                </UiText>
              </UiStack>
            );
          }}
        </QueryContainer>
      </BaseFormDrawer>
    </Formik>
  );
};

export default CloneEventForm;

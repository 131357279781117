import { UiButton, UiHStack, UiText, UiVStack } from '@/lib/ui';
import { useDataTableOptions } from '@/registration/hook/useDataTableOptions';
import dayjs, { type Dayjs } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import {
  MantineReactTable,
  type MRT_Cell,
  useMantineReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_SortingState,
  type MRT_PaginationState,
  type MRT_VisibilityState,
} from 'mantine-react-table';
import type React from 'react';
import { useMemo, type FC } from 'react';
import { type RegistrationImportAttemptErrorInfo } from '@/api/importer';
import { useWindowSize } from '@/lib/hook';

dayjs.extend(advancedFormat);

interface DataTableProps {
  data: RegistrationImportAttemptErrorInfo[]
  isLoading: boolean
  isError: boolean
  isFetching: boolean
  refetch: () => void
  filters: MRT_ColumnFiltersState
  setFilters: React.Dispatch<React.SetStateAction<MRT_ColumnFiltersState>>
  sorting: MRT_SortingState
  setSorting: React.Dispatch<React.SetStateAction<MRT_SortingState>>
  search: string
  setSearch: (searchText: string) => void
  pagination: MRT_PaginationState
  setPagination: React.Dispatch<React.SetStateAction<MRT_PaginationState>>
  columnVisibility: MRT_VisibilityState
  setColumnVisibility: React.Dispatch<React.SetStateAction<MRT_VisibilityState>>
  rowCount: number
}

interface RowErrorProps {
  value: unknown
  errors: string[]
}

const RowError: FC<RowErrorProps> = ({ value, errors }: RowErrorProps) => {
  return (
    <UiVStack alignItems={'flex-start'}>
      {!!value && (<UiText variant={'body2'} >{String(value)}</UiText>)}
      <UiText variant={'body2'} color={'red.500'}>
        {errors.join(', ')}
      </UiText>
    </UiVStack>
  );
};
const defaultCellWithError = ({ cell }: { cell: MRT_Cell<RegistrationImportAttemptErrorInfo> }) => {
  const value = cell.getValue();
  const cellErrors = cell.row.original.validationErrors?.[cell.column.columnDef.id];
  if (cellErrors) {
    return (
      <RowError value={value} errors={cellErrors} />
    );
  }
  return cell.getValue() ? String(value) : null;
};

const DataTable: FC<DataTableProps> = ({
  data, isLoading, isError, isFetching, refetch, filters, setFilters, sorting, setSorting, pagination, setPagination, search, setSearch,
  rowCount, columnVisibility, setColumnVisibility
}) => {
  const { clientWidth: windowClientWidth } = useWindowSize();
  const dataTableOptions = useDataTableOptions<RegistrationImportAttemptErrorInfo>();
  const handleClearFilters = () => {
    setFilters([]);
    setPagination({ pageIndex: 0, pageSize: 10 });
    setSearch('');
    setSorting([]);
  };
  const columns = useMemo<Array<MRT_ColumnDef<RegistrationImportAttemptErrorInfo>>>(
    () => {
      return [
        {
          accessorKey: 'id',
          header: 'ID',
          enableColumnFilter: false,
          enableColumnOrdering: false,
        },
        {
          accessorKey: 'email',
          header: 'Email',
          Cell: defaultCellWithError,
        },
        {
          accessorKey: 'firstName',
          header: 'First Name',
          Cell: defaultCellWithError,
        },
        {
          accessorKey: 'lastName',
          header: 'Last Name',
          Cell: defaultCellWithError,
        },
        {
          accessorKey: 'gender',
          header: 'Gender',
          Cell: defaultCellWithError,
        },
        {
          accessorKey: 'eventName',
          header: 'Event Name',
          Cell: defaultCellWithError,
        },
        {
          accessorKey: 'attendeeCategoryName',
          header: 'Attendee Category',
          Cell: defaultCellWithError,
        },
        {
          accessorKey: 'registrationTypeName',
          header: 'Registration Type',
          Cell: defaultCellWithError,
        },
        {
          accessorKey: 'status',
          header: 'Status',
          Cell: defaultCellWithError,
        },
        {
          accessorKey: 'country',
          header: 'Country',
          Cell: defaultCellWithError,
        },
        {
          accessorKey: 'city',
          header: 'City',
          Cell: defaultCellWithError,
        },
        {
          accessorKey: 'location',
          header: 'Location',
          Cell: defaultCellWithError,
        },
        {
          accessorKey: 'phone',
          header: 'Phone',
          Cell: defaultCellWithError,
        },

        {
          accessorKey: 'pronoun',
          header: 'Pronoun',
          enableColumnFilter: false,
          enableColumnOrdering: false,
          Cell: defaultCellWithError,
        },
        {
          accessorKey: 'tmpAvatar',
          header: 'Avatar',
          enableColumnFilter: false,
          enableColumnOrdering: false,
          Cell: defaultCellWithError,
        },
        {
          accessorKey: 'bio',
          header: 'Bio',
          enableColumnFilter: false,
          enableColumnOrdering: false,
          Cell: defaultCellWithError,
        },
        {
          accessorKey: 'jobTitle',
          header: 'Job Title',
          Cell: defaultCellWithError,
        },
        {
          accessorKey: 'company',
          header: 'Company',
          Cell: defaultCellWithError,
        },
        {
          accessorKey: 'companyPosition',
          header: 'Company Position',
          enableColumnFilter: false,
          enableColumnOrdering: false,
          Cell: defaultCellWithError,
        },
        {
          accessorKey: 'companyWebsite',
          header: 'Company Website',
          enableColumnFilter: false,
          enableColumnOrdering: false,
          Cell: defaultCellWithError,
        },
        {
          accessorKey: 'facebook',
          header: 'Facebook',
          enableColumnFilter: false,
          enableColumnOrdering: false,
          Cell: defaultCellWithError,
        },
        {
          accessorKey: 'github',
          header: 'Github',
          enableColumnFilter: false,
          enableColumnOrdering: false,
          Cell: defaultCellWithError,
        },
        {
          accessorKey: 'headline',
          header: 'Headline',
          enableColumnFilter: false,
          enableColumnOrdering: false,
          Cell: defaultCellWithError,
        },
        {
          accessorKey: 'instagram',
          header: 'Instagram',
          enableColumnFilter: false,
          enableColumnOrdering: false,
          Cell: defaultCellWithError,
        },
        {
          accessorKey: 'linkedin',
          header: 'Linkedin',
          enableColumnFilter: false,
          enableColumnOrdering: false,
          Cell: defaultCellWithError,
        },
        {
          accessorKey: 'skype',
          header: 'Skype',
          enableColumnFilter: false,
          enableColumnOrdering: false,
          Cell: defaultCellWithError,
        },
        {
          accessorKey: 'title',
          header: 'Title',
          enableColumnFilter: false,
          enableColumnOrdering: false,
          Cell: defaultCellWithError,
        },
        {
          accessorKey: 'twitter',
          header: 'Twitter',
          enableColumnFilter: false,
          enableColumnOrdering: false,
          Cell: defaultCellWithError,
        },
        {
          accessorKey: 'wechat',
          header: 'Wechat',
          enableColumnFilter: false,
          enableColumnOrdering: false,
          Cell: defaultCellWithError,
        },
        {
          accessorKey: 'whatsappNumber',
          header: 'Whatsapp Number',
          enableColumnFilter: false,
          enableColumnOrdering: false,
          Cell: defaultCellWithError,
        },
        {
          accessorKey: 'createdAt',
          header: 'CreatedAt',
          Cell: ({ cell }) => { return cell.getValue() ? dayjs(cell.getValue() as string | number | Date | Dayjs).format('hh:mm A Do MMMM Z') : ''; },
          filterVariant: 'date-range',
          enableColumnFilter: false
        },
        {
          accessorKey: 'importErrors',
          header: 'Import Errors',
          enableColumnFilter: false,
          enableColumnOrdering: false,
          Cell: ({ cell }) => {
            const value = cell.getValue();
            return value ? <UiText variant={'body2'} color={'red.500'}>
              {JSON.stringify(value)}
            </UiText> : '';
          },
        }
      ];
    },
    []
  );

  const table = useMantineReactTable({
    columns,
    data,
    initialState: {
      showColumnFilters: true,
      showGlobalFilter: !!search,
      columnVisibility
    },
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    onColumnFiltersChange: setFilters,
    onSortingChange: setSorting,
    onGlobalFilterChange: setSearch,
    onPaginationChange: setPagination,
    onColumnVisibilityChange: setColumnVisibility,
    rowCount,
    state: {
      columnFilters: filters,
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isFetching,
      sorting,
      globalFilter: search,
      pagination,
      columnVisibility
    },
    displayColumnDefOptions: { 'mrt-row-actions': { size: 120 } },
    enableRowActions: false,
    enableSelectAll: false,
    positionActionsColumn: 'last',
    globalFilterFn: 'contains',
    renderTopToolbarCustomActions: () => {
      return (
        <UiHStack
          spacing={8}
          flexGrow={1}
          justifyContent={'flex-end'}
          px={8}
        >
          <UiButton
            px={0}
            size={'sm'}
            variant={'ghost'}
            colorScheme={'primary'}
            onClick={refetch}>
            Apply filter
          </UiButton>
          <UiButton
            px={0}
            size={'sm'}
            variant={'ghost'}
            colorScheme={'primary'}
            onClick={handleClearFilters}>
            Clear all filters
          </UiButton>
        </UiHStack>
      );
    },
    ...dataTableOptions,
    mantineTableContainerProps: {
      sx: {
        maxWidth: `${windowClientWidth - 129}px`,
      },
    },
  });

  return <MantineReactTable table={table} />;
};

export default DataTable;

import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { registration as registrationAPI } from '@/api';
import { type InfoFormResponseTableRow } from '@/api/registration';
import { type InfoFormQuestions, type DatatableSelectOptions } from '@/api/tenantClient';
import { useAdminAuth } from '@/app/ProviderAdminAuth';
import { UiBadge, UiButton, UiHStack } from '@/lib/ui';
import { useDataTableOptions } from '@/registration/hook/useDataTableOptions';
import { useMutation } from '@tanstack/react-query';
import dayjs, { type Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import {
  MantineReactTable,
  type MRT_VisibilityState,
  useMantineReactTable,
  type MRT_Cell,
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_ColumnFiltersState,
  type MRT_SortingState,
  type MRT_PaginationState
} from 'mantine-react-table';
import type React from 'react';
import { useCallback, useMemo, type FC } from 'react';
import { useGlobalToast } from '@/lib/hook';

dayjs.extend(advancedFormat);
dayjs.extend(timezone);
export interface SerializedFormResponseTableRow extends Omit<InfoFormResponseTableRow, 'createdAt' | 'confirmDelegateTypeAt'> {
  createdAt: Date
  confirmDelegateTypeAt: Date | null
}

interface DatatableProps {
  data: SerializedFormResponseTableRow[]
  isLoading: boolean
  isError: boolean
  isFetching: boolean
  refetch: () => void
  filters: MRT_ColumnFiltersState
  setFilters: React.Dispatch<React.SetStateAction<MRT_ColumnFiltersState>>
  sorting: MRT_SortingState
  setSorting: React.Dispatch<React.SetStateAction<MRT_SortingState>>
  search: string
  setSearch: (searchText: string) => void
  pagination: MRT_PaginationState
  setPagination: React.Dispatch<React.SetStateAction<MRT_PaginationState>>
  columnVisibility: MRT_VisibilityState
  setColumnVisibility: React.Dispatch<React.SetStateAction<MRT_VisibilityState>>
  rowCount: number
  personalColumns?: InfoFormQuestions
  additionalColumns?: InfoFormQuestions
  eventId: string
  attendeeCategoryId: string
  statusSelectOptions: DatatableSelectOptions
  delegateTypeSelectOptions: DatatableSelectOptions
  workshopTicketsSelectOptions: DatatableSelectOptions
  tourTicketsSelectOptions: DatatableSelectOptions
  functionTicketsSelectOptions: DatatableSelectOptions
}

const Datatable: FC<DatatableProps> = ({
  data, isLoading, isError, isFetching, refetch, filters, setFilters, sorting, setSorting, pagination, setPagination, search, setSearch, personalColumns, additionalColumns, rowCount, eventId, attendeeCategoryId, statusSelectOptions, delegateTypeSelectOptions, workshopTicketsSelectOptions, tourTicketsSelectOptions, functionTicketsSelectOptions, columnVisibility, setColumnVisibility
}) => {
  const dataTableOptions = useDataTableOptions<SerializedFormResponseTableRow>();
  const { createTenantAdminApiRequest } = useTenantApi();
  const { adminAuth } = useAdminAuth();
  const { reportToGlobal } = useApiErrorHandler();
  const { showToast } = useGlobalToast();

  const { mutate: exportEmailAsCSV, isLoading: isExportCSVLoading } = useMutation<{}, Error>({
    mutationFn: async () => {
      return await registrationAPI.exportEmailAsCSV(createTenantAdminApiRequest)({
        model: 'Form-Response',
        email: adminAuth.user!.email,
        eventId,
        attendeeCategoryId,
      });
    },
    onError: (error) => {
      reportToGlobal(error);
    }
  });

  const onExportEmailAsCSV = useCallback(() => {
    showToast.success('CSV email sent!');
    return exportEmailAsCSV();
  }, [exportEmailAsCSV]);

  const columns = useMemo<Array<MRT_ColumnDef<SerializedFormResponseTableRow>>>(
    () => {
      const personalColumnsHeader = personalColumns?.map((column, columnIndex) => {
        return {
          accessorKey: `infoFormResponses.personal.${column.id}`,
          header: column.label,
          enableColumnFilter: false,
          enableSorting: false,
          id: `infoFormResponses.personal.${columnIndex}`,
          Cell: ({ cell }: { cell: MRT_Cell<SerializedFormResponseTableRow> }) => {
            const infoResponses = cell.getValue() as string[] ?? '';
            if (infoResponses instanceof Array) {
              return (
                <>
                  {infoResponses.map((response, index) => {
                    return (
                      <UiBadge key={index} colorScheme="blue" mr={1}>
                        {`${response}`}
                      </UiBadge>
                    );
                  })}
                </>
              );
            }

            return (
              <>
                {infoResponses ?? ''}
              </>
            );
          }
        };
      });

      const additionalColumnsHeader = additionalColumns?.map((column, columnIndex) => {
        return {
          accessorKey: `infoFormResponses.additional.${column.id}`,
          header: column.label,
          sortable: false,
          enableColumnFilter: false,
          enableSorting: false,
          id: `infoFormResponses.additional.${columnIndex}`,
          Cell: ({ cell }: { cell: MRT_Cell<SerializedFormResponseTableRow> }) => {
            const infoResponses = cell?.getValue() as string[] ?? '';
            if (infoResponses instanceof Array) {
              return (
                <>
                  {infoResponses.map((response, index) => {
                    return (
                      <UiBadge key={index} colorScheme="blue" mr={1}>
                        {`${response}`}
                      </UiBadge>
                    );
                  })}
                </>
              );
            }

            return (
              <>
                {infoResponses}
              </>
            );
          }
        };
      });

      return [
        {
          accessorKey: 'id',
          header: 'Id'
        },
        {
          accessorKey: 'idInfo',
          header: 'Id Info'
        },
        {
          accessorKey: 'attendee.email',
          header: 'Email'
        },
        {
          accessorKey: 'attendee.name',
          header: 'Name'
        },
        {
          accessorKey: 'attendeeCategory.name',
          header: 'Attendee group'
        },
        {
          accessorKey: 'status',
          header: 'Status',
          filterVariant: 'select',
          mantineFilterSelectProps: {
            data: statusSelectOptions
          }
        },
        {
          accessorKey: 'registrationType.name',
          header: 'Registration type',
          filterVariant: 'select',
          size: 300,
          mantineFilterSelectProps: {
            data: delegateTypeSelectOptions
          },
        },
        {
          accessorKey: 'ticket.function',
          header: 'Function tickets',
          mantineFilterSelectProps: {
            data: functionTicketsSelectOptions
          },
          enableSorting: false,
          size: 300,
          filterVariant: 'multi-select',
          Cell: ({ cell }: { cell: MRT_Cell<SerializedFormResponseTableRow> }) => {
            const tickets = cell.getValue() as Array<{ name: string, quantity: number, type: string }>;
            return (
              <>
                {tickets.map((ticket, index) => {
                  return (
                    <UiBadge key={index} colorScheme="blue" mr={1}>
                      {`${ticket.name}`}
                    </UiBadge>
                  );
                })}
              </>
            );
          }
        },
        {
          accessorKey: 'ticket.workshop',
          header: 'Workshop tickets',
          enableSorting: false,
          size: 300,
          filterVariant: 'multi-select',
          mantineFilterSelectProps: {
            data: workshopTicketsSelectOptions
          },
          Cell: ({ cell }: { cell: MRT_Cell<SerializedFormResponseTableRow> }) => {
            const tickets = cell.getValue() as Array<{ name: string, quantity: number, type: string }>;
            return (
              <>
                {tickets.map((ticket, index) => {
                  return (
                    <UiBadge key={index} colorScheme="blue" mr={1}>
                      {`${ticket.name}`}
                    </UiBadge>
                  );
                })}
              </>
            );
          }
        },
        {
          accessorKey: 'ticket.tour',
          header: 'Tour tickets',
          enableSorting: false,
          size: 300,
          filterVariant: 'multi-select',
          mantineFilterSelectProps: {
            data: tourTicketsSelectOptions
          },
          Cell: ({ cell }: { cell: MRT_Cell<SerializedFormResponseTableRow> }) => {
            const tickets = cell.getValue() as Array<{ name: string, quantity: number, type: string }>;
            return (
              <>
                {tickets.map((ticket, index) => {
                  return (
                    <UiBadge key={index} colorScheme="blue" mr={1}>
                      {`${ticket.name}`}
                    </UiBadge>
                  );
                })}
              </>
            );
          }
        },
        {
          accessorKey: 'createdAt',
          header: 'Created at date',
          id: 'createdAtDate',
          filterVariant: 'date-range',
          Cell: ({ cell, row }: { cell: MRT_Cell<SerializedFormResponseTableRow>, row: MRT_Row<SerializedFormResponseTableRow> }) => {
            return cell.getValue() ? dayjs.tz(cell.getValue() as string | number | Date | Dayjs, row.original.event.timeZoneName).format('Do MMMM') : '';
          },
        },
        {
          accessorKey: 'createdAt',
          header: 'Created at time',
          id: 'createdAtTime',
          Cell: ({ cell, row }: { cell: MRT_Cell<SerializedFormResponseTableRow>, row: MRT_Row<SerializedFormResponseTableRow> }) => {
            return cell.getValue() ? dayjs.tz(cell.getValue() as string | number | Date | Dayjs, row.original.event.timeZoneName).format('hh:mm A Z') : '';
          },
          enableColumnFilter: false,
          enableSorting: false,
        },
        {
          accessorKey: 'confirmDelegateTypeAt',
          header: 'Completed at date',
          id: 'completedAtDate',
          filterVariant: 'date-range',
          Cell: ({ cell, row }: { cell: MRT_Cell<SerializedFormResponseTableRow>, row: MRT_Row<SerializedFormResponseTableRow> }) => {
            return cell.getValue() ? dayjs.tz(cell.getValue() as string | number | Date | Dayjs, row.original.event.timeZoneName).format('Do MMMM') : '';
          },
        },
        {
          accessorKey: 'confirmDelegateTypeAt',
          header: 'Completed at time',
          id: 'completedAtTime',
          Cell: ({ cell, row }: { cell: MRT_Cell<SerializedFormResponseTableRow>, row: MRT_Row<SerializedFormResponseTableRow> }) => {
            return cell.getValue() ? dayjs.tz(cell.getValue() as string | number | Date | Dayjs, row.original.event.timeZoneName).format('hh:mm A Z') : '';
          },
          enableColumnFilter: false,
          enableSorting: false,
        }, ...personalColumnsHeader ?? [], ...additionalColumnsHeader ?? []];
    },
    [additionalColumns, delegateTypeSelectOptions, functionTicketsSelectOptions, personalColumns, tourTicketsSelectOptions, workshopTicketsSelectOptions]
  );

  const handleClearFilters = () => {
    setFilters([]);
    setPagination({ pageIndex: 0, pageSize: 10 });
    setSearch('');
    setSorting([]);
  };

  const table = useMantineReactTable({
    columns,
    data,
    initialState: {
      showColumnFilters: true,
      showGlobalFilter: !!search,
      columnVisibility,
    },
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    onColumnFiltersChange: setFilters,
    onSortingChange: setSorting,
    onGlobalFilterChange: setSearch,
    onPaginationChange: setPagination,
    onColumnVisibilityChange: setColumnVisibility,
    rowCount,
    state: {
      columnFilters: filters,
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isFetching,
      sorting,
      globalFilter: search,
      pagination,
      columnVisibility
    },
    displayColumnDefOptions: { 'mrt-row-actions': { size: 96 } },
    enableRowActions: false,
    positionActionsColumn: 'last',
    globalFilterFn: 'contains',
    renderTopToolbarCustomActions: () => {
      return (
        <UiHStack
          spacing={8}
          flexGrow={1}
          justifyContent={'flex-end'}
          px={8}
        >
          <UiButton
            px={0}
            size={'sm'}
            variant={'ghost'}
            colorScheme={'primary'}
            onClick={onExportEmailAsCSV}
            isLoading={isExportCSVLoading}>
            Email as CSV
          </UiButton>
          <UiButton
            px={0}
            size={'sm'}
            variant={'ghost'}
            colorScheme={'primary'}
            onClick={handleClearFilters}>
            Clear all filters
          </UiButton>
          <UiButton
            px={0}
            size={'sm'}
            variant={'ghost'}
            colorScheme={'primary'}
            onClick={refetch}>
            Apply filter
          </UiButton>
        </UiHStack>
      );
    },
    ...dataTableOptions
  });

  return <MantineReactTable table={table} />;
};

export default Datatable;

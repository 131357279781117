import { type FC, useCallback, useMemo, useState } from 'react';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { lowerCase } from 'lodash';
import { Formik, type FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { type UiHStackProps, UiStack } from '@/lib/ui';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
import BaseMessageBarError from '@/base/MessageBar/Error';
import BaseFormSelectField, { type Option } from '@/base/Form/SelectField';
import BaseFormCheckboxListField, { type CheckboxOption } from '@/base/Form/CheckboxListField';
import { registration } from '@/api';
import {
  type Locale,
} from '@/api/registration';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useEventQuery } from '@/registration/hook/useEventQuery';
import { type ApiResponse } from '@/api/tenantClient';
import BaseLoadingSpinner from '@/base/Loading/Spinner';
import { TicketType } from '@/api/constant';

export interface LocaleFormProps extends UiHStackProps {
  onClose: () => void
  onSaveSuccess: () => void
  isVisible: boolean
  locale?: registration.Locale
}

interface FormData {
  /**
   * It's ok to name the locale as you like. E.g. Australia.
   * The actual local code we use internally is always a combination of language and country. E.g. en-AU, en-US, en-NZ
   */
  name: string
  country: string
  currency: string | number // This is the currency code. e.g. AUD
  language: string // This will always be English for now. Having this field reserved for future. We do not need to provide an input field for this attribute.
  taxRateId: number
  default: boolean | string
}

const formSchema = Yup.object().shape({
  name: Yup.string().required('Name is required.'),
  country: Yup.string().required('Country is required.'),
  currency: Yup.string().required('Currency is required.'),
  taxRateId: Yup.number().required('Tax rate is required.'),
  language: Yup.string().required('Language is required.'),
});

const LocaleForm: FC<LocaleFormProps> = ({
  onClose,
  onSaveSuccess,
  isVisible,
  locale,
}) => {
  const { eventId } = useRegisterRoute();
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const queryClient = useQueryClient();
  const { createTenantAdminApiRequest, isLoaded: isTenantLoaded } = useTenantApi();
  const { data: event } = useEventQuery(eventId);

  // Load the admin user session once an accessToken is provided.
  const { data: taxRateList, isLoading: isTaxRateListLoading } = useQuery<ApiResponse<registration.TaxRate>, Error>({
    queryKey: [registration.taxRateListQueryKey, { eventId }],
    queryFn: async () => {
      return await registration.loadTaxRateList(createTenantAdminApiRequest)({ eventId: Number(eventId) });
    },
    enabled: !isTenantLoaded
  });

  const { data: currencyOptionsData } = useQuery({
    queryKey: [registration.localeListQueryKey, 'currency', { eventId }],
    queryFn: async () => {
      return await registration.currencyOptions(createTenantAdminApiRequest)({ eventId });
    }
  });

  const { data: countryOptionsData } = useQuery({
    queryKey: [registration.localeListQueryKey, 'countryOptions', { eventId }],
    queryFn: async () => {
      return await registration.countryOptions(createTenantAdminApiRequest)();
    }
  });

  const countryOptions = useMemo(() => {
    if (!countryOptionsData?.items) return [];
    return countryOptionsData.items.map(([label, value]) => {
      return {
        label,
        value,
      };
    });
  }, [countryOptionsData]);

  // Get the currency code options.
  const currencyOptions: Option[] = useMemo(() => {
    if (!currencyOptionsData?.items) return [];
    return currencyOptionsData?.items?.map((currency) => {
      return {
        value: currency.id,
        label: currency.code,
      };
    });
  }, [currencyOptionsData]);

  const taxRateOptions: Option[] = useMemo(() => {
    // Create the option array using taxRateList
    if (!taxRateList?.items || taxRateList?.items.length === 0) return [];

    return (taxRateList?.items ?? []).map((taxRate) => {
      return {
        value: taxRate.id,
        label: taxRate.name,
      };
    });
  }, [
    taxRateList,
  ]);

  const { mutateAsync, isLoading } = useMutation<ApiResponse<Locale[]>, Error, registration.SaveLocaleRequest>(
    {
      mutationFn: async (data: registration.SaveLocaleRequest) => {
        setSaveErrors([]);
        return await registration.saveLocale(createTenantAdminApiRequest)(data);
      },
      onSuccess: (result) => {
        if (result?.errors && Array.isArray(result?.errors) && result?.errors.length > 0) {
          setSaveErrors(result?.errors);
        } else {
          onSaveSuccess();
          void queryClient.invalidateQueries({ queryKey: [registration.ticketListQueryKey, { eventId }] });
          void queryClient.invalidateQueries({ queryKey: [registration.localeListQueryKey, { eventId }] });
          onClose();
        }
      },
      onError: (errorMessage) => {
        setSaveErrors([errorMessage.message ?? 'Failed to save the locale.']);
      }
    }
  );
  const getInitValues = useCallback(() => {
    if (locale) {
      return {
        id: locale.id,
        name: locale.name,
        country: lowerCase(locale.country),
        currency: locale.currencyId,
        language: locale.language,
        taxRateId: locale.taxRateId,
        default: locale.default.toString(),
      };
    }
    return {
      name: '',
      country: '',
      currency: '',
      default: 'false',
      language: 'en', // This is always 'en' for now. This field is reserved for future. We DO NOT offer multi-language support for now.
      taxRateId: 0,
    };
  }, [locale]);

  const onSubmit = async (values: FormData,
    { setSubmitting, resetForm }: FormikHelpers<FormData>) => {
    setSubmitting(true);
    await mutateAsync({
      id: locale?.id,
      eventId: event ? Number(event.id) : 0,
      name: values.name,
      country: values.country,
      currency: values.currency,
      language: values.language,
      taxRateId: values.taxRateId,
      default: values.default
    });
    !locale && resetForm();
    setSubmitting(false);
  };

  return (
    <Formik<FormData>
      initialValues={getInitValues()}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={formSchema}
      onSubmit={onSubmit}
    >{({ setFieldValue, values }) => {
        return (
          <BaseFormDrawer
            isOpen={isVisible}
            onClose={onClose}
            title={locale ? 'Edit locale' : 'Add locale'}
            size={'lg'}
            isLoading={isLoading}
          >
            {saveErrors.length > 0 && (
              <UiStack spacing={4} flexGrow={1} py={4}>
                {saveErrors.map((errorMessage, index) => {
                  return (
                    <BaseMessageBarError key={index}>{errorMessage}</BaseMessageBarError>
                  );
                })}
              </UiStack>
            )}
            <BaseFormFieldGroup>
              <BaseFormInputField
                name="name"
                label="Name"
              />
              <BaseFormSelectField
                name={'country'}
                label={'Country'}
                // defaultValue={}
                options={countryOptions}
              />
              <BaseFormSelectField
                name={'currency'}
                label={'Currency'}
                // defaultValue={}
                options={currencyOptions}
              />
              {isTaxRateListLoading ? (
                <BaseLoadingSpinner />
              ) : (
                <BaseFormSelectField
                  name={'taxRateId'}
                  label={'Tax rate'}
                  // defaultValue={}
                  options={taxRateOptions}
                />
              )}
              <BaseFormSelectField
                name={'default'}
                label={'Set as default'}
                options={[{
                  value: 'true',
                  label: 'Yes',
                }, {
                  value: 'false',
                  label: 'No',
                }]}
              />
            </BaseFormFieldGroup>
          </BaseFormDrawer>);
      }}
    </Formik>
  );
};

export default LocaleForm;

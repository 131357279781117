import { type FC, useCallback, useMemo, useState } from 'react';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { Formik, type FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { type UiHStackProps, UiStack } from '@/lib/ui';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseMessageBarError from '@/base/MessageBar/Error';
import BaseFormSelectField from '@/base/Form/SelectField';
import { registration } from '@/api';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { type ApiResponse } from '@/api/tenantClient';

export interface CurrencyFormProps extends UiHStackProps {
  onClose: () => void
  onSaveSuccess: () => void
  isVisible: boolean
  currency?: registration.ICurrency
}

interface FormData {
  currencyCode: string
}

const formSchema = Yup.object().shape({
  currencyCode: Yup.string().required('Code is required.'),
});

const CurrencyForm: FC<CurrencyFormProps> = ({
  onClose,
  onSaveSuccess,
  isVisible,
  currency,
}) => {
  const { eventId } = useRegisterRoute();
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const queryClient = useQueryClient();
  const { createTenantAdminApiRequest, isLoaded: isTenantLoaded } = useTenantApi();

  const { data: currencyOptionsData } = useQuery({
    queryKey: [registration.currencyOptionsQueryKey],
    queryFn: async () => {
      return await registration.loadCurrencyOptions(createTenantAdminApiRequest)();
    },
    enabled: isTenantLoaded
  });

  const currencyOptions = useMemo(() => {
    if (!currencyOptionsData?.items.length) return [];
    return currencyOptionsData.items.map((currencyCode) => {
      return {
        value: currencyCode,
        label: currencyCode,
      };
    });
  }, [currencyOptionsData]);

  const { mutateAsync, isLoading } = useMutation<ApiResponse<registration.IMutateCurrencyResponse>, Error, registration.IMutateCurrencyParams>(
    {
      mutationFn: async (data: registration.IMutateCurrencyParams) => {
        setSaveErrors([]);
        return await registration.mutateCurrency(createTenantAdminApiRequest)(data);
      },
      onSuccess: async (result) => {
        if (result?.errors && Array.isArray(result?.errors) && result?.errors.length > 0) {
          setSaveErrors(result?.errors);
        } else {
          onSaveSuccess();
          await Promise.all([
            queryClient.invalidateQueries({ queryKey: [registration.currencyListQueryKey, { eventId }] }),
            queryClient.invalidateQueries({ queryKey: [registration.localeListQueryKey, { eventId }] }),
            queryClient.invalidateQueries({ queryKey: [registration.localeListQueryKey, 'currency', { eventId }] })
          ]);
          onClose();
        }
      },
      onError: (errorMessage) => {
        setSaveErrors([errorMessage.message ?? 'Failed to save the currency.']);
      }
    }
  );
  const getInitValues = useCallback(() => {
    if (currency) {
      return {
        currencyCode: currency.code
      };
    }
    return {
      currencyCode: '',
    };
  }, [currency]);

  const onSubmit = async (values: FormData,
    { setSubmitting, resetForm }: FormikHelpers<FormData>) => {
    setSubmitting(true);
    await mutateAsync({
      eventId,
      currencyId: currency?.id,
      currencyCode: values.currencyCode,
    });
    !currency && resetForm();
    setSubmitting(false);
  };

  return (
    <Formik<FormData>
      initialValues={getInitValues()}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={formSchema}
      onSubmit={onSubmit}
    >{({ setFieldValue, values }) => {
        return (
          <BaseFormDrawer
            isOpen={isVisible}
            onClose={onClose}
            title={currency ? 'Edit currency' : 'Add currency'}
            size={'lg'}
            isLoading={isLoading}
          >
            {saveErrors.length > 0 && (
              <UiStack spacing={4} flexGrow={1} py={4}>
                {saveErrors.map((errorMessage, index) => {
                  return (
                    <BaseMessageBarError key={index}>{errorMessage}</BaseMessageBarError>
                  );
                })}
              </UiStack>
            )}
            <BaseFormFieldGroup>
              <BaseFormSelectField
                name={'currencyCode'}
                label={'Code'}
                options={currencyOptions}
              />
            </BaseFormFieldGroup>
          </BaseFormDrawer>);
      }}
    </Formik>
  );
};

export default CurrencyForm;

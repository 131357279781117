const toastDuration = 4000;

import { UseToastOptions } from '@chakra-ui/react';

export const designSavedToast = (
  toast: (options: UseToastOptions) => void
) => {
  toast({
    title: "Design saved",
    description: "We've successfully saved your design",
    status: "success",
    duration: toastDuration,
    isClosable: true,
  });
};

export const designNotSavedToast = (
  toast: (options: UseToastOptions) => void
) => {
  toast({
    title: "Design was not saved",
    description: "There was an issue with saving your design, please try again later",
    status: "error",
    duration: toastDuration,
    isClosable: true,
  });
};

export const designDeletedToast = (
  toast: (options: UseToastOptions) => void
) => {
  toast({
    title: "Design deleted",
    description: "We've successfully deleted your design",
    status: "success",
    duration: toastDuration,
    isClosable: true,
  });
};

export const designNotDeletedToast = (
  toast: (options: UseToastOptions) => void,
  errorMessage: string
) => {
  toast({
    title: "Design was not deleted",
    description: errorMessage,
    status: "error",
    duration: toastDuration,
    isClosable: true,
  });
};

export const designActivated = (
  toast: (options: UseToastOptions) => void
) => {
  toast({
    title: "Design made active",
    description: "We've successfully enabled this design for this trigger notification",
    status: "success",
    duration: toastDuration,
    isClosable: true,
  });
};

export const designNotActivated = (
  toast: (options: UseToastOptions) => void
) => {
  toast({
    title: "Design was not made active",
    description: "There was an issue with making this design active, please try again later",
    status: "error",
    duration: toastDuration,
    isClosable: true,
  });
};

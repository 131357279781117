import {
  type ApiRequest,
  type ApiResponse,
  properCallApi
} from '@/api/tenantClient';
import { ID } from 'victory';

export const registrationBadgeQueryKey = 'registration.registrationBadge';

export interface RegistrationBadgeData {
  registrationBadge: any;
  badge_back_page_template_id: number | null;
  badge_front_page_template_id: number | null;
  reprint_allowed: string;
  print_back_page: string;
  status: string;
  id: number
  title: string
  term_string: string | null
}

export interface RegistrationBadgeSaveRequestItem {
  title: string
  status: string
  printBackPage: string
  reprintAllowed: string
  badgeFrontPageTemplateId: number | null
  badgeBackPageTemplateId: number | null
  termString: string | null
}

export interface RegistrationBadgeSaveRequest {
  id: number | null
  registrationBadge: RegistrationBadgeSaveRequestItem
}

export function getRegistrationBadges(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (eventId: string): Promise<ApiResponse<RegistrationBadgeData>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/admin/delegate_types/registration_badges';
    request.endpoint.query = { eventId };
    return await properCallApi<ApiResponse<RegistrationBadgeData>>(request);
  };
}

export function loadRegistrationBadgeById(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async ( id: number ): Promise<ApiResponse<RegistrationBadgeData>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/admin/registration_badges/' + id;

    return await properCallApi<ApiResponse<RegistrationBadgeData>>(request);
  };
}

export function saveRegistrationBadge(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (params: RegistrationBadgeSaveRequest): Promise<ApiResponse<RegistrationBadgeData>> => {
    request.method = 'PUT';
    if (params.id) {
      request.endpoint.path = `/api/v1/admin/registration_badges/${params.id}`;
    } else {
      request.endpoint.path = '/api/v1/admin/registration_badges';
    }
    request.payload = params;
    return await properCallApi<ApiResponse<RegistrationBadgeData>>(request);
  };
}
import { type FC } from 'react';
import {
  Plus
} from '@phosphor-icons/react';
import { UiIcon, type UiIconProps } from './UiIcon';

export type UiIconPlusProps = Omit<UiIconProps, 'IconComponent'>;

export const UiIconPlus: FC<UiIconPlusProps> = (props) => {
  return (
    <UiIcon
      IconComponent={Plus}
      {...props}
    />
  );
};

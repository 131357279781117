import { FC } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { UiIconPaperPlaneTilt } from '@/lib/ui';
import TestEmailsForm from '@/registration/buildEvent/AutomatedEmail/TestEmailsForm';
import BaseButtonPlainIcon from '@/base/Button/PlainIcon';

export interface SendTestEmailsProps {
  emailId: number
  emailType: string
}

const SendTestEmails: FC<SendTestEmailsProps> = ({
  emailId,
  emailType,
}) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <BaseButtonPlainIcon
        label={"Send test email"}
        Icon={UiIconPaperPlaneTilt}
        color={"primary.500"}
        onClick={onToggle}
        iconSize={'xl'}
      />
      <TestEmailsForm emailId={emailId} emailType={emailType} isOpen={isOpen} onClose={onToggle}/>
    </>
  );
};

export default SendTestEmails;


import { type FC } from 'react';
import {
  UiStack,
  uiStyles,
  UiIconCornersOut,
  UiIconCornersIn,
} from '@/lib/ui';
import { useLayout } from '@/base/Layout/LayoutProvider';
import BaseButtonPlainIcon from '@/base/Button/PlainIcon';

export interface FullScreenToggleProps {
}

const FullScreenToggle: FC<FullScreenToggleProps> = () => {
  const { isFullScreen, toggleFullScreen } = useLayout();

  return (
    <UiStack {...uiStyles.hover} onClick={toggleFullScreen}>
      {isFullScreen ? (
        <BaseButtonPlainIcon
          label={`Exit full screen`}
          Icon={UiIconCornersIn}
          color={'brand.500'}
          iconSize={'3xl'}
        />
      ) : (
        <BaseButtonPlainIcon
          label={`Full screen`}
          Icon={UiIconCornersOut}
          color={'primary.500'}
          iconSize={'3xl'}
        />
      )}
    </UiStack>
  );
};

export default FullScreenToggle;

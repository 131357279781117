import { FC, useMemo, useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
import BaseFormSelectField, { Option } from '@/base/Form/SelectField';
import { BadgeTemplateStatusType, BadgeTemplatePrintBackType, BadgeTemplateAllowReprintType } from '@/api/constant';
import BaseFormDrawer from '@/base/Form/Drawer';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { BadgeTemplatesData, badgeTemplatesQueryKey, getbBadgeTemplates } from '@/api/registration/badgeTemplate';
import { useTenantApi } from '@/account/hook/useTenantApi';
import {
  loadRegistrationBadgeById,
  RegistrationBadgeData,
  registrationBadgeQueryKey,
  RegistrationBadgeSaveRequest,
  RegistrationBadgeSaveRequestItem,
  registrationTableQueryKey,
  saveRegistrationBadge,
} from '@/api/registration';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import type { ApiResponse } from '@/api/tenantClient';
import { UiStack } from '@/lib/ui';
import BaseMessageBarError from '@/base/MessageBar/Error';
import BaseLoadingSpinner from '@/base/Loading/Spinner';

export interface BadgeFormData {
  id?: number
  badgeName: string
  status: string
  printBackPage: string
  reprintAllowed: string
  badgeTemplate: number | null
  badgeBackPageTemplate: number | null
  termString: string | null
}

interface RegistrationBadgeFormProps {
  // onSubmit: (values: BadgeFormData, helpers: FormikHelpers<BadgeFormData>) => Promise<void>
  onClose: () => void
  onSaveSuccess: () => void
  isVisible: boolean
  badgeId?: number
}

const validationSchema = Yup.object({
  badgeName: Yup.string().required('Badge name is required'),
});

const BadgeForm: FC<RegistrationBadgeFormProps> = ({
  // onSubmit,
  onClose,
  onSaveSuccess,
  isVisible,
  badgeId = undefined,
}) => {
  const [errors, setErrors] = useState<string[]>([]);
  const { reportToGlobal } = useApiErrorHandler();
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();
  const queryClient = useQueryClient();
  const { data: badgeTemplateData, isLoading: isBadgeTemplateLoading } = useQuery<BadgeTemplatesData, Error>({
    queryKey: [badgeTemplatesQueryKey],
    queryFn: async (): Promise<BadgeTemplatesData> => {
      const response = await getbBadgeTemplates(createTenantAdminApiRequest)();
      return response.items[0];
    },
    enabled: !isApiPreparing,
  });

  // Load badge template options.
  const badgeTemplateOptions: Option[] = useMemo(() => {
    if (badgeTemplateData) {
      return badgeTemplateData.badgeTemplates?.map((badgeTemplates: { id: any; name: any; }) => ({
        value: badgeTemplates.id ?? '',
        label: badgeTemplates.name ?? '',
      })) || [];
    }
    return [];
  }, [
    badgeTemplateData,
  ]);

  // Load the badge data if badgeId is passed.
  const {data: badgeData, isFetching: isBadgeFetching} = useQuery<RegistrationBadgeData, Error>({
    queryKey: [registrationBadgeQueryKey, { id: badgeId }],
    queryFn: async (): Promise<RegistrationBadgeData> => {
      const response = await loadRegistrationBadgeById(createTenantAdminApiRequest)(badgeId ?? 0);
      return response.items[0];
    },
    enabled: !!badgeId && isVisible,
  });

  // Get the form initial data.
  const initFormData: BadgeFormData = useMemo(() => {
    if (!badgeData) {
      return {
        badgeName: '',
        status: 'true',
        printBackPage: 'true',
        reprintAllowed: 'false',
        badgeTemplate: null,
        badgeBackPageTemplate: null,
        termString: '',
      };
    } else {
      return {
        badgeName: badgeData.registrationBadge.title ?? '',
        status: badgeData.registrationBadge.status.toString() ?? '',
        printBackPage: badgeData.registrationBadge.printBackPage.toString() ?? '',
        reprintAllowed: badgeData.registrationBadge.reprintAllowed.toString() ?? '',
        badgeTemplate: badgeData.registrationBadge.badgeFrontPageTemplateId || null,
        badgeBackPageTemplate: badgeData.registrationBadge.badgeBackPageTemplateId || null,
        termString: badgeData.registrationBadge.termString ?? '',
      };
    }
  }, [
    badgeData,
  ]);

  // Save the badge.
  const { mutateAsync, isLoading: isSaveLoading } = useMutation<ApiResponse<RegistrationBadgeData>, Error, RegistrationBadgeSaveRequest>({
    mutationFn: async (data: RegistrationBadgeSaveRequest) => {
      return await saveRegistrationBadge(createTenantAdminApiRequest)(data);
    },
    onSuccess: (result) => {
      if (result?.errors && Array.isArray(result?.errors) && result?.errors.length > 0) {
        setErrors(result?.errors);
      } else {
        setErrors([]);
        onSaveSuccess();
        void queryClient.invalidateQueries({ queryKey: [registrationTableQueryKey] });
        void queryClient.invalidateQueries({ queryKey: [registrationBadgeQueryKey, { id: badgeId }] });
        onClose();
      }
    },
    onError: (error) => {
      reportToGlobal(error);
      setErrors([error.message ?? 'Failed to save the badge.']);
    }
  });

  // Submit the form to save the badge.
  const onSubmit = async (
    values: BadgeFormData,
    { setSubmitting, resetForm }: FormikHelpers<BadgeFormData>
  ) => {
    setSubmitting(true);
    const registrationBadge: RegistrationBadgeSaveRequestItem = {
      title: values.badgeName,
      status: values.status,
      printBackPage: values.printBackPage,
      reprintAllowed: values.reprintAllowed,
      badgeFrontPageTemplateId: values.badgeTemplate,
      badgeBackPageTemplateId: values.badgeBackPageTemplate,
      termString: values.termString ?? null,
    };
    if (badgeId) {
      await mutateAsync({
        id: badgeId,
        registrationBadge: registrationBadge,
      });
    } else {
      await mutateAsync({
        id: null,
        registrationBadge: registrationBadge,
      });
    }
    setSubmitting(false);
  };

  // if ((isBadgeTemplateLoading || isBadgeFetching)) {
  //   return null;
  // }

  // console.log('-----', initFormData);
  return (
    <Formik<BadgeFormData>
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialValues={initFormData}
      validateOnChange={false}
      validateOnBlur={true}
      enableReinitialize={true}
    >
      {() => (
        <BaseFormDrawer
          isOpen={isVisible}
          onClose={onClose}
          title={'Registration badge'}
          size={'lg'}
          isLoading={isSaveLoading}
          buttonText={'Save'}
          buttonLoadingText={'Saving...'}
        >
          {errors.length > 0 && (
            <UiStack spacing={4} flexGrow={1} py={4}>
              {errors.map((error, index) => {
                return (
                  <BaseMessageBarError key={index}>
                    {error}
                  </BaseMessageBarError>
                );
              })}
            </UiStack>
          )}
          {(isBadgeTemplateLoading || isBadgeFetching) ? (
            <BaseLoadingSpinner/>
          ) : (
            <BaseFormFieldGroup>
              <BaseFormInputField name="badgeName" label="Badge name"/>
              <BaseFormSelectField
                name={'status'}
                label={'Status'}
                options={[
                  {value: BadgeTemplateStatusType.YES, label: 'Enabled'},
                  {value: BadgeTemplateStatusType.NO, label: 'Disabled'},
                ]}
              />
              <BaseFormSelectField
                name={'printBackPage'}
                label={'Print back page'}
                options={[
                  {value: BadgeTemplatePrintBackType.YES, label: 'Enabled'},
                  {value: BadgeTemplatePrintBackType.NO, label: 'Disabled'},
                ]}
              />
              <BaseFormSelectField
                name={'reprintAllowed'}
                label={'Reprints allowed?'}
                options={[
                  {value: BadgeTemplateAllowReprintType.YES, label: 'Enabled'},
                  {value: BadgeTemplateAllowReprintType.NO, label: 'Disabled'},
                ]}
              />
              <BaseFormSelectField
                name={'badgeTemplate'}
                label={'Badge front page template'}
                options={badgeTemplateOptions}
                isRequired={false}
              />
              <BaseFormSelectField
                name={'badgeBackPageTemplate'}
                label={'Badge back page template'}
                options={badgeTemplateOptions}
                isRequired={false}
              />
              <BaseFormInputField name="termString" label="Terms required for badge printing" isRequired={false}/>
            </BaseFormFieldGroup>
          )}
        </BaseFormDrawer>
      )}
    </Formik>
  );
}

export default BadgeForm;

import { type FC } from 'react';
import {
  useQuery
} from '@tanstack/react-query';
import {
  registration
} from '@/api';
import { UiStack } from '@/lib/ui';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import { useTenantApi } from '@/account/hook/useTenantApi';
import BaseBlockList from '@/base/BlockList';
import CurrencyCard from './CurrencyCard';
import { type ApiResponse } from '@/api/tenantClient';

export interface CurrencyListProps {
  // queryParams: registration.EventListQueryRequest;
}

const CurrencyList: FC<CurrencyListProps> = () => {
  const { eventId, tenant } = useRegisterRoute();
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();

  const currencyListQuery = useQuery<ApiResponse<registration.ICurrency>, Error>({
    queryKey: [registration.currencyListQueryKey, { eventId }],
    queryFn: async () => {
      return await registration.loadCurrencyList(createTenantAdminApiRequest)({ eventId: Number(eventId) });
    },
    enabled: !isApiPreparing
  });

  if (tenant) {
    return (
      <UiStack
        spacing={4}
        alignItems={'stretch'}
        flexGrow={1}
      >
        <QueryContainer query={currencyListQuery}>
          {(data) => {
            return (
              <BaseBlockList>
                {(data.items ?? []).map((currency) => {
                  return (
                    <CurrencyCard
                      key={currency.id}
                      currency={currency}
                      tenant={tenant}
                    />
                  );
                })}
              </BaseBlockList>
            );
          }}
        </QueryContainer>
      </UiStack>
    );
  }
};

export default CurrencyList;

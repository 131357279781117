import { type ApiRequest, callApi } from '@/api/globalClient';
import { TenantState } from '@/api/constant';
import {
  type ApiResponse,
  type ApiResponseSingle,
  properCallApi,
} from '../tenantClient';

// GO API - Tenant

export const tenantQueryKey = 'account.tenant';
export const tenantSearchQueryKey = 'tenant.tenantSearch';
export const tenantListQueryKey = 'tenant.tenantList';

export interface TenantData {
  id: number
  state: number
  name: string
  code: string
  apiEndpoint: string
  createdAt: number
  updatedAt: number
}

export interface TenantLoadByCodeRequest {
  code: string
}

export interface TenantLoadByCodeResponse {
  tenant: TenantData
  errors: string[]
}

const emptyTenant: TenantData = {
  id: 0,
  state: TenantState.Active,
  name: '',
  code: '',
  apiEndpoint: '',
  createdAt: 0,
  updatedAt: 0,
};

export function loadTenantByCode(createGlobalApiRequest: () => ApiRequest) {
  const apiRequest = createGlobalApiRequest();
  return async function (
    params: TenantLoadByCodeRequest
  ): Promise<TenantLoadByCodeResponse> {
    if (params.code) {
      apiRequest.endpoint.path = '/api/tenant/load-tenant-by-code';
      apiRequest.payload = {
        code: params.code,
      };

      const response = await callApi<TenantLoadByCodeResponse>(apiRequest);
      return {
        tenant: response.tenant,
        errors: response.errors,
      };
    }
    return {
      tenant: emptyTenant,
      errors: [],
    };
  };
}

export interface TenantSearchTenantData extends TenantData {}

export interface TenantSearchRequest {
  name?: string
  state?: number
  code?: string
  page?: number
  limit?: number
  orderByField?: string
  isOrderedAsc?: boolean
}

export interface TenantSearchResponse {
  tenantList: TenantSearchTenantData[]
  page: number
  total: number
  errors: string[]
}

export function searchTenants(createGlobalApiAclRequest: () => ApiRequest) {
  const apiRequest = createGlobalApiAclRequest();
  return async function (
    params: TenantSearchRequest
  ): Promise<TenantSearchResponse> {
    apiRequest.endpoint.path = '/api/tenant/search-tenants';
    apiRequest.payload = params;
    const response = await callApi<TenantSearchResponse>(apiRequest);
    response.tenantList = response.tenantList ?? [];
    return response;
  };
}

export interface TenantSaveRequest {
  tenant: {
    id?: number
    name: string
    code: string
    state: number
    apiEndpoint: string
  }
}

export interface TenantSaveResponse {
  id: number
  errors: string[]
}

export function saveTenant(createGlobalApiAclRequest: () => ApiRequest) {
  const apiRequest = createGlobalApiAclRequest();
  return async function (
    params: TenantSaveRequest
  ): Promise<TenantSaveResponse> {
    apiRequest.endpoint.path = '/api/tenant/save-tenant';
    apiRequest.payload = params;
    return await callApi<TenantSaveResponse>(apiRequest);
  };
}

export interface TenantLoadRequest {
  id: number
}

export interface TenantLoadResponse {
  tenant: TenantData
  errors: string[]
}

export function loadTenant(createGlobalApiAclRequest: () => ApiRequest) {
  const apiRequest = createGlobalApiAclRequest();
  return async function (
    params: TenantLoadRequest
  ): Promise<TenantLoadResponse> {
    apiRequest.endpoint.path = '/api/tenant/load-tenant';
    apiRequest.payload = params;
    return await callApi<TenantLoadResponse>(apiRequest);
  };
}

// Event website - Tenant

export interface EventWebsiteTenant {
  name: string
  createdAt: string
  updatedAt: string
  id: number
  favicon: string
  domainName: string
  metaDescription: string
  metaTitle: string
  metaKeywords: string
  googleAnalyticsKey: string
  facebookPixelKey: string
  localiseKey: string
  mailchimpKey: string | null
  mailchimpListId: string | null
  primaryColour: string
  secondaryColour: string
  active: boolean | string
  websiteUrl: string
  helpEmail: string
  shortName: string
  googleMap: string
  privacyPolicy: string | null
  cookiePolicy: string | null
  termsAndConditions: string | null
  twitter: string
  instagram: string
  line: string | null
  facebook: string
  linkedin: string
  metaLogo: string
  blackLogo: string
  deletedAt: string | null
  codeOfConduct: string
  about: string | null
  aboutSummary: string | null
  privacyPolicyLink: string
  scheduleNickname: string
  aboutSummaryHomepage: string | null
  aboutImageHomepage: string | null
  packAlias: string | null
  volunteerLanguageHint: string | null
  volunteerTeeShirtSizeHint: string | null
  volunteeringTerms: string | null
  appLogo: string
  registrationEnabled: boolean | string
  shortUserRegistration: boolean | string
  clientIdLabel: string | null
  googleTagManagerKey: string
  matomoSiteKey: string
  gdprCompliance: boolean | string
  gdprCookieBannerHeader: string | null
  gdprCookieBannerText: string | null
  colourEmailHeader: string
  colourEmailFooter: string
  headerBannerImage: string | null
  nicknameAccount: string
  nicknameProfile: string
  nicknameMyAccount: string
  nicknameMyProfile: string
  perEventRegistration: boolean | string
  legalName: string
  businessNumberLabel: string
  businessNumber: string
  emailAccounts: string
  invoiceName: string
  addressStreet1: string
  addressStreet2: string
  addressCity: string
  addressState: string
  addressPostCode: string
  addressCountry: string
  phone: string
  zoomApiKey: string | null
  zoomApiSecretKey: string | null
  zoomJwtToken: string | null
  zoomCorpOrigintrialKey: string | null
  eventSitemap: boolean | string
  facebookDomainVerificationKey: string
  loginLinkExpirationLength: number
  smsNotifications: boolean | string
  featureFlagEmailBuilder: boolean | string
  dashboard: boolean | string
  pageTemplateId: string | null
  hubspotCompanyId: number
  demoId: string | null
  createdById: number | null
  updatedById: number
  jwtRedirectUri: string
  ivsEnabled: boolean | string
  alertOnMailUnsubscribe: boolean | string
  samlSsoEnabled: boolean | string
  googleAdsConversionLabel: string | null
  publicApiKey: string
  financeCode: string | null
  colourEventHubBannerColour: string
  tmpBlackLogo: string
  tmpFavicon: string
  tmpMetaLogo: string
  tmpAppLogo: string
  tmpHeaderBannerImage: string,
  jwtClientId: string | null,
  jwtClientSecret: string | null
}

export interface EventWebsiteTenantNewRequest {
  tenant?: {
    name: string
    createdAt: string
    updatedAt: string
    phone: string
    addressStreet1: string
    addressCity: string
    addressPostCode: string
    addressCountry: string
    tmpBlackLogo?: string
    tmpFavicon?: string
    tmpMetaLogo?: string
    tmpAppLogo?: string
    tmpHeaderBannerImage?: string
  }
}

export interface EventWebsiteTenantCreateRequest {
  tenant?: EventWebsiteTenant
}

export interface EventWebsiteTenantUpdateRequest {
  tenant: Omit<
  EventWebsiteTenant,
  | 'createdAt'
  | 'updatedAt'
  | 'appLogo'
  | 'blackLogo'
  | 'favicon'
  | 'metaLogo'
  | 'headerBannerImage'
  >
}

export interface EventTenantDeleteRequest {
  id: number
}

export function loadEventWebsiteTenants(
  createTenantAdminRequest: () => ApiRequest
) {
  const request = createTenantAdminRequest();
  return async (): Promise<ApiResponse<EventWebsiteTenant>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/admin/tenants';
    return await properCallApi<ApiResponse<EventWebsiteTenant>>(request);
  };
}

export function createEventWebsiteTenant(
  createTenantAdminRequest: () => ApiRequest
) {
  const request = createTenantAdminRequest();
  return async function (
    params: EventWebsiteTenantNewRequest
  ): Promise<ApiResponseSingle<EventWebsiteTenant>> {
    request.method = 'POST';
    request.endpoint.path = '/api/v1/admin/tenants';
    request.payload = params;
    return await properCallApi<ApiResponseSingle<EventWebsiteTenant>>(request);
  };
}

export function updateEventWebsiteTenant(
  createTenantAdminRequest: () => ApiRequest
) {
  const request = createTenantAdminRequest();
  return async function (
    params: EventWebsiteTenantUpdateRequest
  ): Promise<ApiResponseSingle<EventWebsiteTenant>> {
    request.method = 'PUT';
    request.endpoint.path = `/api/v1/admin/tenants/${params.tenant.id}`;
    request.payload = params;
    return await properCallApi<ApiResponseSingle<EventWebsiteTenant>>(request);
  };
}

export function deleteEventWebsiteTenant(
  createTenantAdminRequest: () => ApiRequest
) {
  const request = createTenantAdminRequest();
  return async function (
    params: EventTenantDeleteRequest
  ): Promise<ApiResponseSingle<EventWebsiteTenant>> {
    request.method = 'DELETE';
    request.endpoint.path = `/api/v1/admin/tenants/${params.id}`;
    return await properCallApi<ApiResponseSingle<EventWebsiteTenant>>(request);
  };
}

import React, { FC } from "react";
import {
  Button,
  Input,
  MenuItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";

interface Props {
  setName: React.Dispatch<React.SetStateAction<string>>;
  handleNewTemplate: (ev: React.MouseEvent<HTMLElement>) => void;
  loading: boolean;
}

const NewModalMenuItem: FC<Props> = ({ setName, handleNewTemplate, loading }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const saveAs = (ev: React.MouseEvent<HTMLElement>) => {
    handleNewTemplate(ev);
    onClose();
  };

  return (
    <>
      <MenuItem onClick={onOpen}>
        {"New template"}
      </MenuItem>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {"New template"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              placeholder="Template name"
              onChange={(ev) => setName(ev.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Stack direction={"row"} spacing={2}>
              <Button variant="secondary" onClick={onClose}>
                {"Close"}
              </Button>
              <Button
                isLoading={loading}
                variant="secondary"
                onClick={(ev) => saveAs(ev)}
              >
                {"Create"}
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default NewModalMenuItem;

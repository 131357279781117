import { type FC } from 'react';
import { UiHStack, UiIconPlusCircle, UiStack, uiStyles, UiText } from '@/lib/ui';
import { useQuery } from '@tanstack/react-query';
import {
  loadRegistrationInfoFormResponse,
  type RegistrationInfo,
  type InfoFormResponse,
  InfoFormType,
  type InfoForm,
  type InfoFormByAttendeeCategoryQuery,
  infoFormByAttendeeCategoryQueryKey,
  loadInfoFormByAttendeeCategory,
  infoFormResponseQueryKey,
} from '@/api/registration';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import { useTenantApi } from '@/account/hook/useTenantApi';
import InformationList from './InformationList';
import { useDisclosure } from '@chakra-ui/react';
import FullScreen from '@/base/Modal/FullScreen';
import GenericInfoForm from './GenericInfoForm';
import BaseButtonPlainIconEdit from '@/base/Button/PlainIconEdit';
import BaseButtonIconText from '@/base/Button/IconText';

interface AdditionalInfoSessionProps {
  registration: RegistrationInfo
}

const AdditionalInfoSession: FC<AdditionalInfoSessionProps> = ({
  registration,
}) => {
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } =
    useTenantApi();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const infoFormByAttendeeCategoryQuery = useQuery<InfoForm, Error>({
    queryKey: [
      infoFormByAttendeeCategoryQueryKey,
      { registrationId: registration?.id, formType: InfoFormType.ADDITIONAL },
    ],
    queryFn: async () => {
      return await fetchInfoFormByAttendeeCategory(registration?.id);
    },
    enabled: !!registration && !isApiPreparing,
  });

  async function fetchInfoFormByAttendeeCategory(
    registrationId: number | undefined
  ): Promise<InfoForm> {
    if (typeof registrationId === 'undefined') {
      return await Promise.reject(new Error('Invalid registrationId'));
    }

    const params: InfoFormByAttendeeCategoryQuery = {
      formType: InfoFormType.ADDITIONAL,
      registrationId,
    };
    const result = await loadInfoFormByAttendeeCategory(
      createTenantAdminApiRequest
    )(params);
    return result.item ?? {};
  }

  const additionalInfoQuery = useQuery<InfoFormResponse, Error>({
    queryKey: [
      infoFormResponseQueryKey,
      { registrationId: registration?.id, type: InfoFormType.ADDITIONAL },
    ],
    queryFn: async () => {
      return await fetchRegistrationInfoFormResponse(registration?.id);
    },
    enabled: !!registration && !isApiPreparing,
  });

  async function fetchRegistrationInfoFormResponse(
    registrationId: number | undefined
  ): Promise<InfoFormResponse> {
    if (typeof registrationId === 'undefined') {
      return await Promise.reject(new Error('Invalid registrationId'));
    }
    const response = await loadRegistrationInfoFormResponse(
      createTenantAdminApiRequest
    )({
      registrationId,
      type: InfoFormType.ADDITIONAL,
    });

    return response.item ?? {};
  }

  const toModify = () => {
    onOpen();
  };

  return (
    <UiStack>
      <QueryContainer query={infoFormByAttendeeCategoryQuery}>
        {(infoForm) => {
          return (
            <QueryContainer query={additionalInfoQuery}>
              {(additionalInfoResponse) => {
                return (
                  <UiStack spacing={4}>
                    <UiHStack spacing={4}>
                      <UiText fontWeight={'bold'}>Additional information</UiText>
                      {infoForm.config && (
                        <BaseButtonIconText Icon={UiIconPlusCircle} onClick={toModify}>Edit</BaseButtonIconText>
                      )}
                    </UiHStack>
                    {infoForm.config && (
                      <>
                        <InformationList
                          fieldsMetadata={infoForm.config.fieldsMetadata}
                          response={additionalInfoResponse.response ?? {}}
                          {...uiStyles.borderCard}
                        />
                        <FullScreen isOpen={isOpen} onClose={onClose}>
                          <GenericInfoForm
                            infoForm={infoForm}
                            infoFormResponse={additionalInfoResponse}
                            registration={registration}
                            onSuccess={onClose}
                          />
                        </FullScreen>
                      </>
                    )}
                  </UiStack>
                );
              }}
            </QueryContainer>
          );
        }}
      </QueryContainer>
    </UiStack>
  );
};

export default AdditionalInfoSession;

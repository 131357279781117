import { type FC, useState } from 'react';
import BaseLayout from '@/base/Layout';
import BaseLayoutBody from '@/base/Layout/Body';
// import Filter from './Filter';
import AdminSideNav from '@/registration/component/Layout/AdminSideNav';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import RegistrationTable from './RegistrationTable';
import { UiFlex, UiHStack, UiStack, UiText } from '@/lib/ui';
import { LayoutProvider } from '@/base/Layout/LayoutProvider';
import BaseLayoutFullScreenToggle from '@/base/Layout/FullScreenToggle';
import SubscribeButton from '@/base/ScheduleReport/SubscribeButton';
import { reportSubscriptionName } from '@/api/reports/reportSubscriptions';

const Registration: FC = () => {
  const { tenantCode } = useRegisterRoute();

  return (
    <BaseLayout
      smartWindow={(<SmartWindow />)}
      sideMenu={(<AdminSideNav activeNodes={['manage', 'registrations']} tenantCode={tenantCode} />)}
      appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard showDescription={false} />} currentAppName={'registration'} />)}
    >
      <BaseLayoutBody
        isStandalone={false}
        // breadcrumbNodes={breadcrumbNodes}
      >
        <UiHStack spacing={4}>
          <BaseLayoutFullScreenToggle />
          <UiText variant={'title'}>Registrations</UiText>
          <UiHStack>
            <SubscribeButton
              reportName={reportSubscriptionName.registrationReport}
              label={'Subscribe the report'}
              labelSubscribed={'Report subscribed'}
            />
          </UiHStack>
        </UiHStack>
        <UiStack height={4} />
        <RegistrationTable />
      </BaseLayoutBody>
    </BaseLayout>
  );
};

export default Registration;

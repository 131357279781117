import { type FC, useCallback, useMemo, useState } from 'react';
import { Formik } from 'formik';
import {
  type UiHStackProps,
  UiStack
} from '@/lib/ui';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { type FileOrUrl } from '@/base/ImageSelect/ImageSelectItem';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { loadImportAttemptsForTableQueryKey, uploadCSV, type UploadCSVRequest, type UploadCSVResponse } from '@/api/importer';
import Uploader from '@/base/Form/Uploader';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
import * as yup from 'yup';

export interface ImporterFormProps extends UiHStackProps {
  onClose: () => void
  onSaveSuccess: () => void
  isVisible: boolean
  modelType: string
}

interface FormData {
  upload_csv: FileOrUrl[]
}

const initFormData = {
  upload_csv: [],
};

const isValidUTF8 = async (file: File): Promise<boolean> => {
  return await new Promise((resolve) => {
    const reader = new FileReader();

    reader.onload = ({ target }) => {
      try {
        const buffer = new Uint8Array(target?.result as ArrayBuffer);
        new TextDecoder('utf-8', { fatal: true }).decode(buffer);
        resolve(true);
      } catch {
        resolve(false);
      }
    };

    reader.onerror = () => { return resolve(false); };
    reader.readAsArrayBuffer(file);
  });
};

const fileSchema = yup.mixed<File>()
  .required('File is required')
  .test('fileFormat', 'Only .csv files are allowed', (file) => { return file && file.type === 'text/csv'; }
  )
  .test('isUTF8', 'File must be UTF-8 encoded', async (file) => {
    return file ? await isValidUTF8(file) : false;
  });

const schema = yup.object().shape({
  upload_csv: yup.array().of(fileSchema).required('File is required')
});

const ImporterForm: FC<ImporterFormProps> = ({
  onClose,
  onSaveSuccess,
  isVisible,
  modelType
}) => {
  const { tenant } = useTenantRoute();
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const { createTenantAdminApiRequest } = useTenantApi();

  const { reportToGlobal } = useApiErrorHandler();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation<UploadCSVResponse, Error, UploadCSVRequest>({
    mutationFn: async (data: UploadCSVRequest) => {
      return await uploadCSV(createTenantAdminApiRequest)(data);
    },
    onSuccess: (result) => {
      if (result?.errors && Array.isArray(result?.errors) && result?.errors.length > 0) {
        setSaveErrors(result?.errors);
      } else {
        setSaveErrors([]);
        onSaveSuccess();
        void queryClient.invalidateQueries({ queryKey: [loadImportAttemptsForTableQueryKey] });
        onClose();
      }
    },
    onError: (error) => {
      reportToGlobal(error);
      setSaveErrors([error.message ?? 'Failed to import.']);
    }
  });

  const submitForm = useCallback(async (values: FormData) => {
    const files = values.upload_csv;
    if (files.length === 0) {
      setSaveErrors(['Please select a CSV file.']);
      return;
    }
    const uploadFile = files[0] as File;
    void mutate({ uploadFile, modelType, });
  }, [mutate, modelType]);
  // useMemo get linkTemplate by modelType
  const linkDownloadTemplate = useMemo(() => {
    switch (modelType) {
      case 'Registration':
        return `${tenant?.apiEndpoint}/import-templates/evexus-template-import-registrations-api.csv`;
      default:
        return null;
    }
  }, [modelType, tenant]);

  return (
    <Formik
      initialValues={initFormData}
      validateOnChange
      validateOnBlur={false}
      validationSchema={schema}
      onSubmit={async (values: FormData) => {
        await submitForm(values);
      }}
    >
      {({ setFieldValue }) => {
        return (
          <BaseFormDrawer
            isOpen={isVisible}
            onClose={onClose}
            title={'Upload CSV'}
            size={'lg'}
            isLoading={isLoading}
            buttonText={'Send'}
            buttonLoadingText={'Sending...'}
          >
            {saveErrors.length > 0 && (
              <UiStack spacing={4} flexGrow={1} py={4}>
                {saveErrors.map((error, index) => {
                  return (
                    <BaseMessageBarError key={index}>
                      {error}
                    </BaseMessageBarError>
                  );
                })}
              </UiStack>
            )}
            <BaseFormFieldGroup>
              <Uploader
                isMultiple={true}
                isRequired={true}
                name="upload_csv"
                label="Upload CSV file"
                helperText='Please upload file under format: .csv (UTF-8 encoded)'
                acceptFiles={{ 'application/octet-stream': ['.csv'] }}
                setFieldValue={setFieldValue}
                defaultTemplateLink={linkDownloadTemplate}
                defaultTemplateTitle='Download CSV template' />
            </BaseFormFieldGroup>
          </BaseFormDrawer>
        );
      }}
    </Formik>
  );
};

export default ImporterForm;

import { type FC } from 'react';
import dayjs from 'dayjs';
import {
  UiHStack, UiIconPencilSimple, UiIconPlusCircle,
  UiSimpleGrid,
  UiStack,
  uiStyles,
  UiText,
} from '@/lib/ui';
import { type EventWebsiteTenant } from '@/api/account/tenant';
import BaseButtonIconText from '@/base/Button/IconText';
import TenantForm from '@/account/setup/Tenant/TenantForm';
import { useDisclosure } from '@chakra-ui/react';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';

export interface TenantInfoProps {
  tenant: EventWebsiteTenant
}

const TenantInfo: FC<TenantInfoProps> = ({
  tenant,
}) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <UiStack spacing={4}>
        <UiHStack spacing={4}>
          <UiText variant={'title'}>Summary</UiText>
          <BaseButtonIconText Icon={UiIconPencilSimple} onClick={onToggle}>Edit</BaseButtonIconText>
        </UiHStack>
        <UiStack
          p={8}
          // py={6}
          borderRadius={uiStyles.borderRadius}
          bgColor={'#fff'}
          flexGrow={1}
          shadow={uiStyles.cardShadow}
        >
          <UiSimpleGrid columns={{base: 2, 'lg': 4, '2xl': 5}} gap={8}>
            <UiStack spacing={1}>
              <UiText variant={'body2'} color={'text.secondary'}>Tenant name</UiText>
              <UiText>{tenant.name ?? ''}</UiText>
            </UiStack>
            <UiStack spacing={1}>
              <UiText variant={'body2'} color={'text.secondary'}>Created at</UiText>
              <UiText>{tenant.createdAt ? dayjs(tenant.createdAt).format('Do MMMM YYYY HH:mm:ss') : 'N/A'}</UiText>
            </UiStack>
            <UiStack spacing={1}>
              <UiText variant={'body2'} color={'text.secondary'}>Updated at</UiText>
              <UiText>{tenant.updatedAt ? dayjs(tenant.updatedAt).format('Do MMMM YYYY HH:mm:ss') : 'N/A'}</UiText>
            </UiStack>
            <UiStack spacing={1}>
              <UiText variant={'body2'} color={'text.secondary'}>Tenant initials</UiText>
              <UiText>{tenant.shortName ?? ''}</UiText>
            </UiStack>
            <UiStack spacing={1}>
              <UiText variant={'body2'} color={'text.secondary'}>Tax identifier/name</UiText>
              <UiText>{tenant.businessNumberLabel ?? ''}</UiText>
            </UiStack>
            <UiStack spacing={1}>
              <UiText variant={'body2'} color={'text.secondary'}>Company business number</UiText>
              <UiText>{tenant.businessNumber ?? ''}</UiText>
            </UiStack>
            <UiStack spacing={1}>
              <UiText variant={'body2'} color={'text.secondary'}>Website</UiText>
              <UiText>{tenant.websiteUrl ?? ''}</UiText>
            </UiStack>
            <UiStack spacing={1}>
              <UiText variant={'body2'} color={'text.secondary'}>Invoice name</UiText>
              <UiText>{tenant.invoiceName ?? ''}</UiText>
            </UiStack>
            <UiStack spacing={1}>
              <UiText variant={'body2'} color={'text.secondary'}>JWT client id</UiText>
              <UiText>{tenant.jwtClientId ?? ''}</UiText>
            </UiStack>
            <UiStack spacing={1}>
              <UiText variant={'body2'} color={'text.secondary'}>JWT client secret</UiText>
              <UiText>{tenant.jwtClientSecret ?? ''}</UiText>
            </UiStack>
          </UiSimpleGrid>
          <BaseDividerHorizontal height={4}/>
          <UiSimpleGrid columns={{base: 2, 'lg': 4, '2xl': 5}} gap={8}>
            <UiStack spacing={1}>
              <UiText variant={'body2'} color={'text.secondary'}>Address line 1</UiText>
              <UiText>{tenant.addressStreet1 ?? ''}</UiText>
            </UiStack>
            <UiStack spacing={1}>
              <UiText variant={'body2'} color={'text.secondary'}>Address line 2</UiText>
              <UiText>{tenant.addressStreet2 ?? ''}</UiText>
            </UiStack>
            <UiStack spacing={1}>
              <UiText variant={'body2'} color={'text.secondary'}>Suburb/city</UiText>
              <UiText>{tenant.addressCity ?? ''}</UiText>
            </UiStack>
            <UiStack spacing={1}>
              <UiText variant={'body2'} color={'text.secondary'}>Suburb</UiText>
              <UiText>{tenant.addressCity ?? ''}</UiText>
            </UiStack>
            <UiStack spacing={1}>
              <UiText variant={'body2'} color={'text.secondary'}>State</UiText>
              <UiText>{tenant.addressState ?? ''}</UiText>
            </UiStack>
            <UiStack spacing={1}>
              <UiText variant={'body2'} color={'text.secondary'}>Postcode</UiText>
              <UiText>{tenant.addressPostCode ?? ''}</UiText>
            </UiStack>
            <UiStack spacing={1}>
              <UiText variant={'body2'} color={'text.secondary'}>Country</UiText>
              <UiText>{tenant.addressCountry ?? ''}</UiText>
            </UiStack>
          </UiSimpleGrid>
        </UiStack>
      </UiStack>
      <TenantForm tenant={tenant} onClose={onToggle} onSaveSuccess={() => { }} isVisible={isOpen} />
    </>
  );
};

export default TenantInfo;

import { useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { useTenantApi } from '@/account/hook/useTenantApi';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import { UiIconPlusCircle, UiStack, UiText } from '@/lib/ui';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useQuery } from '@tanstack/react-query';
import { type IEmailTableRes } from '@/api/registration/automatedEmail';
import CampaignEmailList from './CampaignEmailList';
import CampaignEmailForm from './CampaignEmailForm';
import { campaignsEmailKey, getCampaignsEmail } from '@/api/registration/campaignsEmail';
import BaseButtonIconText from '@/base/Button/IconText';
import CreateAutomatedEmailForm from '@/registration/buildEvent/AutomatedEmail/CreateAutomatedEmailForm';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';

export interface AutomatedOptions {
  value: keyof Omit<IEmailTableRes, 'newEmailTypes'>
  label: string
}

const NewCampaignEmail = () => {
  const { isOpen, onToggle } = useDisclosure();
  const { eventId } = useRegisterRoute();
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();
  const [selectedEmailId, setSelectedEmailId] = useState<number | string | null>(null);
  const { onOpen: onOpenDelete } = useDisclosure();

  const campaignEmailQuery = useQuery({
    queryKey: [campaignsEmailKey, { eventId }],
    queryFn: async () => {
      return await getCampaignsEmail(createTenantAdminApiRequest)({ eventId });
    },
    enabled: !isApiPreparing
  });

  return (
    <>
      <BaseButtonIconText onClick={onToggle} Icon={UiIconPlusCircle}>Send campaign emails</BaseButtonIconText>
      <CampaignEmailForm
        isOpen={isOpen}
        onToggle={onToggle}
        emailId={selectedEmailId}
        onClose={() => {
          setSelectedEmailId(null);
          onToggle();
        }}
      />
    </>
  );

  // return (
  //   <QueryContainer query={campaignEmailQuery}>
  //     {(campaignEmailTableData) => {
  //       return (
  //         <>
  //           <CampaignEmailForm
  //             isOpen={isOpen}
  //             campaignEmailTableData={campaignEmailTableData.items}
  //             onToggle={onToggle}
  //             emailId={selectedEmailId}
  //             onClose={() => {
  //               setSelectedEmailId(null);
  //               onToggle();
  //             }}
  //           />
  //           <UiStack
  //             borderRadius={'lg'}
  //             spacing={4}
  //           >
  //             <UiText>Campaign emails</UiText>
  //             <CampaignEmailList
  //               data={campaignEmailTableData.items}
  //               onDelete={(id) => {
  //                 setSelectedEmailId(id);
  //                 onOpenDelete();
  //               }}
  //               onCreateCampaignEmail={(id) => {
  //                 if (!id) {
  //                   return;
  //                 }
  //                 setSelectedEmailId(id);
  //                 onToggle();
  //               }}
  //             />
  //           </UiStack>
  //         </>
  //       );
  //     }}
  //   </QueryContainer>
  // );
};

export default NewCampaignEmail;

import { type FC } from 'react';
import {
  useQuery
} from '@tanstack/react-query';
import {
  registration
} from '@/api';
import { UiStack } from '@/lib/ui';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import { useTenantApi } from '@/account/hook/useTenantApi';
import BaseBlockList from '@/base/BlockList';
import { type ApiResponse } from '@/api/tenantClient';
import LocaleCard from './LocaleCard';

export interface LocaleListProps {
  // queryParams: registration.EventListQueryRequest;
}

const LocaleList: FC<LocaleListProps> = () => {
  const { eventId, tenant } = useRegisterRoute();
  const { createTenantAdminApiRequest, isLoading: isApiPreparing } = useTenantApi();

  const localeListQuery = useQuery<ApiResponse<registration.Locale>, Error>({
    queryKey: [registration.localeListQueryKey, { eventId }],
    queryFn: async () => {
      return await registration.loadLocaleList(createTenantAdminApiRequest)({ eventId: Number(eventId) });
    },
    enabled: !isApiPreparing
  });

  const { data: taxRateData } = useQuery<ApiResponse<registration.TaxRate>, Error>({
    queryKey: [registration.taxRateListQueryKey, { eventId }],
    queryFn: async () => {
      return await registration.loadTaxRateList(createTenantAdminApiRequest)({ eventId: Number(eventId) });
    },
    enabled: !isApiPreparing
  });

  if (tenant) {
    return (
      <UiStack
        spacing={4}
        alignItems={'stretch'}
        flexGrow={1}
      >
        <QueryContainer query={localeListQuery}>
          {(data) => {
            return (
              <BaseBlockList>
                {
                  (data?.items ?? []).map(locale => {
                    return <LocaleCard key={locale.id} locale={locale} tenant={tenant} taxRates={taxRateData?.items ?? []} />;
                  })
                }
              </BaseBlockList>
            );
          }}
        </QueryContainer>
      </UiStack>
    );
  }
};

export default LocaleList;

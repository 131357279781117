import { UiStack } from '@/lib/ui';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type FC, useState, useCallback } from 'react';

import { Formik, type FormikHelpers } from 'formik';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseMessageBarError from '@/base/MessageBar/Error';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormTextareaField from '@/base/Form/TextareaField';

import * as Yup from 'yup';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { type ApiResponseSingle } from '@/api/tenantClient';
import { type SaveNoteRequest, createNote, type AdminNote } from '@/api/admin/note';

const formSchema = Yup.object().shape({
  content: Yup.string().required('Content is required')
});

interface FormData {
  content: string
}

interface NoteFormProps {
  profileableType: string
  profileableId: number | null
  note: AdminNote | null
  onClose: () => void
  onSaveSuccess: () => void
  isVisible: boolean
}

const NoteForm: FC<NoteFormProps> = ({ profileableType, profileableId, note, onClose, isVisible, onSaveSuccess }) => {
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const { createTenantAdminApiRequest } = useTenantApi();
  const queryClient = useQueryClient();
  const { reportToGlobal } = useApiErrorHandler();

  const { mutate, isLoading } = useMutation<ApiResponseSingle<AdminNote>, Error, SaveNoteRequest>(
    async (data: SaveNoteRequest) => {
      return await createNote(createTenantAdminApiRequest, profileableType, profileableId)(data);
    },
    {
      onSuccess: async () => {
        onSaveSuccess();
        void queryClient.invalidateQueries({ queryKey: [{ profileableType, profileableId }] });
        onClose();
      },
      onError: (error: Error) => {
        reportToGlobal(error);
        setSaveErrors([error.message ?? 'Failed to save the note.']);
      }
    }
  );

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const getInitValues = useCallback((note: AdminNote | null) => {
    return {
      content: note ? note.content : ''
    } satisfies FormData;
  }, []);

  const onSubmit = useCallback(async (
    values: FormData,
    { setSubmitting, resetForm }: FormikHelpers<FormData>
  ) => {
    setSubmitting(true);
    const noteData = {
      id: note?.id,
      profileableType,
      profileableId,
      content: values?.content ?? '',
      createdAt: note?.createdAt ?? '',
      updatedAt: note?.updatedAt ?? '',
      createdBy: note?.createdBy ?? '',
      updatedBy: note?.updatedBy ?? ''
    } satisfies AdminNote;
    mutate({ note: noteData }, {
      onSuccess: () => {
        resetForm();
      }
    });
    setSubmitting(false);
  }, [getInitValues, mutate]);

  return (
    <Formik<FormData>
      initialValues={{ content: note?.content ?? '' }}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={formSchema}
      onSubmit={onSubmit}
    >
      <BaseFormDrawer
        isOpen={isVisible}
        onClose={onClose}
        title={`${profileableType} (note)`}
        size={'lg'}
        isLoading={isLoading}
      >
        {saveErrors.length > 0 && (
          <UiStack spacing={4} flexGrow={1} py={4}>
            {saveErrors.map((error, index) => {
              return (
                <BaseMessageBarError key={index}>
                  {error}
                </BaseMessageBarError>
              );
            })}
          </UiStack>
        )}
        <BaseFormFieldGroup>
          <BaseFormTextareaField name={'content'} label={'Content'} />
        </BaseFormFieldGroup>
      </BaseFormDrawer>
    </Formik>
  );
};

export default NoteForm;

import { type FC, useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Formik, type FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { type UiHStackProps, UiStack } from '@/lib/ui';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { registration } from '@/api';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { type ApiResponse } from '@/api/tenantClient';

export interface TaxFormProps extends UiHStackProps {
  onClose: () => void
  onSaveSuccess: () => void
  isVisible: boolean
  taxRate?: registration.TaxRate
}

interface FormData {
  name: string
  percentage: number | string
}

const formSchema = Yup.object().shape({
  name: Yup.string().required('Name is required.'),
  percentage: Yup.number().integer().min(0).max(100),
});

const TaxRateForm: FC<TaxFormProps> = ({
  onClose,
  onSaveSuccess,
  isVisible,
  taxRate,
}) => {
  const { eventId } = useRegisterRoute();
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const queryClient = useQueryClient();
  const { createTenantAdminApiRequest } = useTenantApi();

  const { mutateAsync, isLoading } = useMutation<ApiResponse<registration.TaxRate>, Error, registration.SaveTaxRateRequest>(
    {
      mutationFn: async (data: registration.SaveTaxRateRequest) => {
        return await registration.saveTaxRate(createTenantAdminApiRequest)(data);
      },
      onMutate: () => {
        setSaveErrors([]);
      },
      onSuccess: (result) => {
        if (result?.errors && Array.isArray(result?.errors) && result?.errors.length > 0) {
          setSaveErrors(result?.errors);
        } else {
          onSaveSuccess();
          void queryClient.invalidateQueries({ queryKey: [registration.taxRateListQueryKey, { eventId }] });
          onClose();
        }
      },
      onError: (error) => {
        setSaveErrors([error.message ?? 'Failed to save the tax rate.']);
      }
    }
  );

  const getInitValues = useCallback(() => {
    if (taxRate) {
      return {
        id: taxRate.id,
        name: taxRate.name,
        percentage: taxRate.percentage,
      };
    }
    return {
      name: '',
      percentage: 0,
    };
  }, [taxRate]);

  const onSubmit = async (values: FormData,
    { setSubmitting, resetForm }: FormikHelpers<FormData>) => {
    setSubmitting(true);
    await mutateAsync({
      eventId,
      id: taxRate?.id,
      name: values.name,
      percentage: values.percentage,
    });
    !taxRate && resetForm();
    setSubmitting(false);
  };

  return (
    <Formik<FormData>
      initialValues={getInitValues()}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={formSchema}
      onSubmit={onSubmit}
    >{({ setFieldValue, values }) => {
        return (
          <BaseFormDrawer
            isOpen={isVisible}
            onClose={onClose}
            title={taxRate ? 'Edit tax rate' : 'Add tax rate'}
            size={'lg'}
            isLoading={isLoading}
          >
            {saveErrors.length > 0 && (
              <UiStack spacing={4} flexGrow={1} py={4}>
                {saveErrors.map((error, index) => {
                  return (
                    <BaseMessageBarError key={index}>{error}</BaseMessageBarError>
                  );
                })}
              </UiStack>
            )}
            <BaseFormFieldGroup>
              <BaseFormInputField
                name="name"
                label="Name"
              />
              <BaseFormInputField
                name="percentage"
                label="Percentage"
                min={0}
                helperText={'e.g. value of 15 means 15%.'}
              />
            </BaseFormFieldGroup>
          </BaseFormDrawer>);
      }}
    </Formik>
  );
};

export default TaxRateForm;

import { type FC } from 'react';
import {
  UiGrid,
  UiHStack,
  UiIconLayout,
  UiIconPencilSimple,
  UiStack,
  UiText,
} from '@/lib/ui';
import { type IEmailRes } from '@/api/registration/automatedEmail';
import { useNavigate } from 'react-router-dom';
import { generatePageUrl } from '@/app/pages';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useResizeObserver } from 'usehooks-ts';
import Online from '@/registration/buildEvent/PaymentMethods/Online';
import Invoice from '@/registration/buildEvent/PaymentMethods/Invoice';
import BaseBlockList from '@/base/BlockList';
import BaseButtonPlainIcon from '@/base/Button/PlainIcon';
import { PermissionAction, PermissionDomain } from '@/api/constant/adminUserPermission';
import BaseAclContainer from '@/account/component/AclContainer';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
import SendTestEmails from '@/registration/buildEvent/AutomatedEmail/SendTestEmails';
import EmailDesign from '@/registration/component/EmailDesign';
import DesignEmail from '@/registration/buildEvent/AutomatedEmail/DesignEmail';

const thStyles = {
  px: 8,
  py: 6
};
const tdStyles = {
  px: 8,
  py: 6,
};

interface IAutomatedListItemProps {
  data: IEmailRes[]
  onEdit?: (id: string | number) => void
  onDelete?: (id: string | number) => void
}

const AutomatedCategoryList: FC<IAutomatedListItemProps> = ({ data = [], onEdit = () => { }, onDelete = () => { } }) => {
  // const navigate = useNavigate();
  const { tenant } = useTenantRoute();
  const { eventId, tenantCode } = useRegisterRoute();
  // const handleRedirect = ({ id }: { id: string | number }) => {
  //   navigate(generatePageUrl('RegistrationBuildEventCreateAutomatedEmail', { tenantCode, eventId }, { id }));
  // };

  return (
    <BaseBlockList
      bgColor={'#fff'}
      enableDivider={true}
    >
      {data.map(({ id, subjectLine, event, sendEmail, sendPushNotification, type, emailTemplate = '' }) => {
        return (
          <UiHStack
            p={8}
            py={4}
            justifyContent={'space-between'}
            key={id}
          >
            <UiGrid
              templateColumns={'2fr 2fr 1fr 1fr'}
              gap={8}
              flexGrow={1}
            >
              <UiStack spacing={1}>
                <UiText variant={'body2'} color={'text.secondary'}>
                  Subject line
                </UiText>
                <UiText variant={'body2'}>
                  {subjectLine}
                </UiText>
              </UiStack>
              <UiStack spacing={1}>
                <UiText variant={'body2'} color={'text.secondary'}>
                  Type
                </UiText>
                <UiText variant={'body2'}>
                  {type}
                </UiText>
              </UiStack>
              {/*<UiStack spacing={1}>*/}
              {/*  <UiText variant={'body2'} color={'text.secondary'}>*/}
              {/*    Event*/}
              {/*  </UiText>*/}
              {/*  <UiText variant={'body2'}>*/}
              {/*    {event}*/}
              {/*  </UiText>*/}
              {/*</UiStack>*/}
              <UiStack spacing={1}>
                <UiText variant={'body2'} color={'text.secondary'}>
                  Email template
                </UiText>
                <UiText variant={'body2'}>
                  {emailTemplate}
                </UiText>
              </UiStack>
              <UiStack spacing={1}>
                <UiText variant={'body2'} color={'text.secondary'}>
                  Send email
                </UiText>
                <UiText variant={'body2'} color={sendEmail ? 'green.500': 'red.500'}>
                  {sendEmail ? 'Yes' : 'No'}
                </UiText>
              </UiStack>
              {/*<UiStack spacing={1}>*/}
              {/*  <UiText variant={'body2'} color={'text.secondary'}>*/}
              {/*    Send push notification*/}
              {/*  </UiText>*/}
              {/*  <UiText variant={'body2'} color={sendEmail ? 'green.500': 'red.500'}>*/}
              {/*    {sendPushNotification ? 'Yes' : 'No'}*/}
              {/*  </UiText>*/}
              {/*</UiStack>*/}
            </UiGrid>
            <UiHStack spacing={6}>
              <BaseAclContainer tenantId={tenant?.id ?? 0} permissionsRequired={[[PermissionDomain.Registration, PermissionAction.Write]]}>
                <BaseButtonPlainIcon
                  label={"Edit"}
                  Icon={UiIconPencilSimple}
                  color={"primary.500"}
                  onClick={() => { return onEdit(id); }}
                  iconSize={'xl'}
                />
                <DesignEmail
                  emailId={id}
                  emailType={type}
                />
              </BaseAclContainer>
              <SendTestEmails emailId={Number(id)} emailType={type ?? ''}/>
            </UiHStack>
          </UiHStack>
        );
      })}
    </BaseBlockList>
  );
};

export default AutomatedCategoryList;

import { useEffect, useMemo, useState, type FC } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { type UiHStackProps } from '@/lib/ui';
import PageRow from '@/registration/buildEvent/OtherSettings/LabelsDescriptions/PageRow';
import FullScreen from '@/base/Modal/FullScreen';
import BadgeDesignBuilder from './BadgeDesignBuilder';
import {
  type JSONTemplate
} from 'state/types/types';
import { number } from 'yup';
import BaseLayout from '@/base/Layout';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import { BaseBreadcrumbBarNode } from "@/base/BreadcrumbBar";
import { generatePageUrl } from '@/app/pages';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import BreadcrumbBody from '@/base/Layout/BreadcrumbBody';
export interface BadgeDesignerPage extends UiHStackProps {

}

export interface BadgeTemplate {
  id: number;
  name: string;
  template: JSONTemplate;
  html: string;
  active: boolean;
}

const BadgeDesignerPage: FC<BadgeDesignerPage> = ({ ...props }) => {
  const { tenantCode, tenant } = useRegisterRoute();
  const breadcrumbNodes = useMemo<BaseBreadcrumbBarNode[]>(
  () => {
    return [
      { label: 'Manage' },
      { label: 'Registration badges', url: generatePageUrl('RegistrationBadgeManageTable', { tenantCode }) },
      { label: `Badge Designer` }
    ];
  },
  []
);
  return (
    <BaseLayout
      smartWindow={(<SmartWindow />)}
      sideMenu={null}
      MainTransition={null}
      appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard showDescription={false} />} currentAppName={'registration'} />)}
    >
      <BreadcrumbBody breadcrumbNodes={breadcrumbNodes}>
        <BadgeDesignBuilder></BadgeDesignBuilder>
      </BreadcrumbBody>
     
    </BaseLayout>
      
   
  );
};

export default BadgeDesignerPage;

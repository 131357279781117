import BaseLayout from '@/base/Layout';
import BaseLayoutBody from '@/base/Layout/Body';
import { type FC } from 'react';
import { useTenantApi } from '@/account/hook/useTenantApi';
import {
  registrationListActivated,
  type ListActivatedChart,
  registrationReportKey,
  registrationStatistics,
  type RegistrationReport,
  orderStatistics
} from '@/api/registration';
import { type ApiResponseSingle } from '@/api/tenantClient';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import SmartWindow from '@/base/Layout/SmartWindow';
import { UiHStack, UiStack, UiText } from '@/lib/ui';
import AdminSideNav from '@/registration/component/Layout/AdminSideNav';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { SimpleGrid } from '@chakra-ui/react';
import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import RegistrationsChartReport from './RegistrationsChartReport';
import RegistrationsActivatedChartReport from './RegistrationsActivatedChartReport';
import BaseLayoutFullScreenToggle from '@/base/Layout/FullScreenToggle';
import { reportSubscriptionName } from '@/api/reports/reportSubscriptions';
import { useEventOptionsQuery } from '@/registration/hook/useEventOptions';

const groupOptions = [
  { label: 'Attendee group', value: 'byAttendeeCategory' },
  { label: 'Main ticket type', value: 'byMainTicketType' },
  { label: 'Country', value: 'byCountry' },
  { label: 'Discount code', value: 'byDiscountCode' },
  { label: 'Function ticket Type', value: 'byFunctionTicket' },
  { label: 'Workshop ticket Type', value: 'byWorkshopTicket' },
  { label: 'Tour ticket type', value: 'byTourTicket' },
  { label: 'All ticket type', value: 'byTotalTicket' }
];

const groupDateOptions = [
  { label: '7 Day', value: 'last_7_days' },
  { label: '1 Month (28) days)', value: 'last_4_weeks' },
  { label: '6 Month', value: 'last_6_months' },
  { label: '1 Year', value: 'year' }
];

interface Params {
  type: string
  eventId: string
}

interface CompletedParamsChart {
  groupName: string
  eventId: string
}
export type useGetRegistrationStatisticsType = ({ eventId, type }: Params) => UseQueryResult<ApiResponseSingle<RegistrationReport>, Error>;

const useGetRegistrationStatistics = ({ type, eventId }: Params) => {
  const { createTenantAdminApiRequest } = useTenantApi();

  return useQuery<ApiResponseSingle<RegistrationReport>, Error>({
    queryKey: [registrationReportKey, type, eventId],
    queryFn: async (): Promise<ApiResponseSingle<RegistrationReport>> => {
      const response = await registrationStatistics(createTenantAdminApiRequest)({
        type,
        eventId
      });

      return response;
    },
    enabled: !!eventId,
    keepPreviousData: true,
    staleTime: 30_000
  });
};

export type useGetListActivatedChartType = ({ eventId, groupName }: CompletedParamsChart) => UseQueryResult<ApiResponseSingle<ListActivatedChart>, Error>;

const useGetListActivatedChart = ({ groupName, eventId }: CompletedParamsChart) => {
  const { createTenantAdminApiRequest } = useTenantApi();

  return useQuery<ApiResponseSingle<ListActivatedChart>, Error>({
    queryKey: [orderStatistics, groupName, eventId],
    queryFn: async (): Promise<ApiResponseSingle<ListActivatedChart>> => {
      const response = await registrationListActivated(createTenantAdminApiRequest)({
        groupName,
        eventId
      });

      return response;
    },
    enabled: !!groupName && !!eventId,
    keepPreviousData: false,
    staleTime: 30_000
  });
};

const Registration: FC = () => {
  const { tenantCode } = useRegisterRoute();
  const { eventOptions } = useEventOptionsQuery();

  return (
    <BaseLayout
      smartWindow={(<SmartWindow />)}
      sideMenu={(<AdminSideNav activeNodes={['report', 'registrations']} tenantCode={tenantCode} />)}
      appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard showDescription={false} />} currentAppName={'registration'} />)}
    >
      <BaseLayoutBody
        isStandalone={false}
      >
        <UiHStack justifyContent={'flex-start'} spacing={4}>
          <BaseLayoutFullScreenToggle />
          <UiText variant={'title'}>Registration reports</UiText>
        </UiHStack>
        <UiStack height={4} />
        <SimpleGrid columns={{ '2xl': 2, base: 1 }} spacing={8}>
          <RegistrationsChartReport
            selectOptions={groupOptions}
            useGetRegistrationStatistics={useGetRegistrationStatistics}
            type='completed'
            eventOptions={eventOptions}
            title='Total complete registrations'
            reportName={reportSubscriptionName.registrationCompletedReport}
          />
          <RegistrationsChartReport
            selectOptions={groupOptions}
            eventOptions={eventOptions}
            useGetRegistrationStatistics={useGetRegistrationStatistics}
            type='cancelled'
            title='Total canceled registrations'
            reportName={reportSubscriptionName.registrationCancelledReport}
          />
          <RegistrationsChartReport
            selectOptions={groupOptions}
            eventOptions={eventOptions}
            useGetRegistrationStatistics={useGetRegistrationStatistics}
            type='abandoned_cart'
            title='Total abandoned carts'
            reportName={reportSubscriptionName.registrationAbandonedCartReport}
          />
          <RegistrationsActivatedChartReport
            selectOptions={groupDateOptions}
            eventOptions={eventOptions}
            useGetListActivatedChart={useGetListActivatedChart}
            title='Registrations activated'
            type={''}
          />
        </SimpleGrid>
      </BaseLayoutBody>
    </BaseLayout>
  );
};

export default Registration;

import { type FC, useCallback, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Formik, type FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { type UiHStackProps, UiStack, UiHStack, UiButton, UiFormHelperText, UiText, UiVStack } from '@/lib/ui';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
import BaseFormTextareaField from '@/base/Form/TextareaField';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { registration } from '@/api';
import {
  IsRequiredType,
  type DiscountCode,
  type DiscountCodeSaveRequest
} from '@/api/registration';
import dayjs from 'dayjs';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import BaseFormSelectField from '@/base/Form/SelectField';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useEventQuery } from '@/registration/hook/useEventQuery';
import { type ApiResponse } from '@/api/tenantClient';

export interface CodeFormProps extends UiHStackProps {
  onClose: () => void
  onSaveSuccess: () => void
  isVisible: boolean
  discountCode?: DiscountCode
}

interface FormData {
  name: string
  description?: string
  discountCode: string
  startDate: string
  endDate: string
  idPhotocopy: IsRequiredType
  idInfo: IsRequiredType
}

const formSchema = Yup.object().shape({
  name: Yup.string().required('Name is required.'),
  description: Yup.string()
    .max(200, 'Description can not have more than 100 characters.'),
  discountCode: Yup.string()
    .required('Discount code is required.')
    .matches(/^\S*$/, 'Discount code can not include any spaces'),
  startDate: Yup.date()
    .required('Start date is required field.').nullable()
    .typeError('Invalid Date format. Please enter a valid date.'),
  endDate: Yup.date()
    .required('End date is required field.').nullable()
    .typeError('Invalid Date format. Please enter a valid date.')
    .when('startDate',
      (startDate, yup) => {
        if (startDate && startDate[0] instanceof Date) {
          return yup.min(startDate, 'End time cannot be before start time');
        } else {
          return yup;
        }
      }),
  idPhotocopy: Yup.string().required('This field is required.'),
  idInfo: Yup.string().required('This field is required.')
});

const CodeForm: FC<CodeFormProps> = ({
  onClose,
  onSaveSuccess,
  isVisible,
  discountCode
}) => {
  const { eventId } = useRegisterRoute();
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const queryClient = useQueryClient();
  const { createTenantAdminApiRequest } = useTenantApi();
  const { data: event } = useEventQuery(eventId);

  const { mutateAsync, isLoading } = useMutation<ApiResponse<DiscountCode[]>, Error, DiscountCodeSaveRequest>(
    {
      mutationFn: async (data: DiscountCodeSaveRequest) => {
        setSaveErrors([]);
        return await registration.saveDiscountCode(createTenantAdminApiRequest)(data);
      },
      onSuccess: (result) => {
        if (result?.errors && Array.isArray(result?.errors) && result?.errors.length > 0) {
          setSaveErrors(result?.errors);
        } else {
          onSaveSuccess();
          void queryClient.invalidateQueries({ queryKey: [registration.discountCodeQueryKey, { eventId }] });
          void queryClient.invalidateQueries({ queryKey: [registration.ticketDiscountOptionQueryKey] });
          void queryClient.invalidateQueries({ queryKey: [registration.eventSettingsQueryKey, { eventId }] });
          onClose();
        }
      },
      onError: (error) => {
        setSaveErrors([error.message ?? 'Failed to save the host.']);
      }
    }
  );
  const getInitValues = useCallback(() => {
    if (!discountCode) {
      return {
        name: '',
        description: '',
        discountCode: '',
        startDate: '',
        endDate: '',
        idPhotocopy: IsRequiredType.NOT_REQUIRED,
        idInfo: IsRequiredType.NOT_REQUIRED
      };
    }
    return {
      ...discountCode,
      description: discountCode.description ?? '',
      startDate: dayjs(discountCode.startDate).tz(event?.tzInfoName).format('YYYY-MM-DD'),
      endDate: dayjs(discountCode.endDate).tz(event?.tzInfoName).format('YYYY-MM-DD')
    };
  }, [discountCode, event?.tzInfoName]);

  const onSubmit = async (values: FormData,
    { setSubmitting, resetForm }: FormikHelpers<FormData>) => {
    setSubmitting(true);
    const formatString = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
    // Parse the input date, set the time to 00:00:00, and apply the timezone
    const startDate = dayjs(values.startDate).tz(event?.tzInfoName, true).startOf('day').utc().format(formatString);
    const endDate = dayjs(values.endDate).tz(event?.tzInfoName, true).endOf('day').utc().format(formatString);
    await mutateAsync({
      id: discountCode?.id,
      eventId,
      name: values.name,
      description: values.description,
      discountCode: values.discountCode,
      startDate,
      endDate,
      idPhotocopy: values.idPhotocopy,
      idInfo: values.idInfo,
      active: true
    });
    !discountCode && resetForm();
    setSubmitting(false);
  };
  const generateCode = () => {
    return [...Array(8)]
      .map(() => { return Math.random().toString(36)[2]; })
      .join('');
  };

  return (
    <Formik<FormData>
      initialValues={getInitValues()}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={formSchema}
      onSubmit={onSubmit}
    >{({ setFieldValue, values }) => {
        return (
          <BaseFormDrawer
            isOpen={isVisible}
            onClose={onClose}
            title={discountCode ? 'Edit discount' : 'Add discount'}
            size={'xl'}
            isLoading={isLoading}
          >
            {saveErrors.length > 0 && (
              <UiStack spacing={4} flexGrow={1} py={4}>
                {saveErrors.map((error, index) => {
                  return (
                    <BaseMessageBarError key={index}>{error}</BaseMessageBarError>
                  );
                })}
              </UiStack>
            )}
            <BaseFormFieldGroup>
              <BaseFormInputField name="name" label="Name" layout="stack" />
              <BaseFormTextareaField
                name="description"
                label="Description"
                isRequired={false}
                layout="stack"
                maxLength={200}
                helperText={`200 character limit (${200 - (values?.description?.length ?? 0)} characters remaining). This is for your own reference.`} />
              <BaseFormInputField
                name="discountCode"
                label="Discount code"
                layout="stack"
                maxLength={20}
                helperText={`20 characters limit (${20 - (values?.discountCode?.length ?? 0)} characters remaining).`}
              />
              <UiHStack alignItems="flex-start">
                <UiButton px={6}
                  py={4}
                  size={'md'}
                  colorScheme={'primary'}
                  onClick={() => {
                    const code = generateCode();
                    void setFieldValue('discountCode', code);
                  } }>
                Generate
                </UiButton>
              </UiHStack>
              <UiStack>
                <UiText color={'gray.600'} variant="caption">
                  {"Discounts will only be available on dates including and between the 'Start date' and 'End date'"}.
                </UiText>
                <UiHStack flex={1} alignItems="flex-start" >
                  <BaseFormInputField
                    name="startDate"
                    label="Start date"
                    layout="stack"
                    type="date"
                    helperText="The provided date should be in the event's time zone." />
                  <BaseFormInputField
                    name="endDate"
                    label="End date"
                    layout="stack"
                    type="date"
                    helperText="The provided time should be in the event's time zone." />
                </UiHStack>
              </UiStack>
              <BaseFormSelectField
                name={'idPhotocopy'}
                label={'ID photocopy'}
                layout="stack"
                options={[
                  { value: IsRequiredType.NOT_REQUIRED, label: 'Not required' },
                  { value: IsRequiredType.REQUIRED, label: 'Required' },
                  { value: IsRequiredType.OPTIONAL, label: 'Optional' }
                ]} />
              <BaseFormSelectField
                name={'idInfo'}
                label={'ID info'}
                layout="stack"
                options={[
                  { value: IsRequiredType.NOT_REQUIRED, label: 'Not required' },
                  { value: IsRequiredType.REQUIRED, label: 'Required' },
                  { value: IsRequiredType.OPTIONAL, label: 'Optional' }
                ]}
                helperText={'If the attendee needs to provide the information about the ID. E.g. member id'} />
            </BaseFormFieldGroup>
          </BaseFormDrawer>);
      }}
    </Formik>
  );
};

export default CodeForm;

import { UiButton, UiHStack, uiStyles, UiText } from '@/lib/ui';
import { useDataTableOptions } from '@/registration/hook/useDataTableOptions';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import dayjs, { type Dayjs } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import {
  MantineReactTable,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
  useMantineReactTable,
  type MRT_ColumnDef
} from 'mantine-react-table';
import { useMemo, type FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { type ImportAttemptsTableRow } from '@/api/importer';
import { PermissionAction, PermissionDomain } from '@/api/constant/adminUserPermission';
import { generatePageUrl } from '@/app/pages';
import BaseAclContainer from '@/account/component/AclContainer';
import { type ManageRouteName } from '@/registration/pages';
import { type DatatableSelectOptions } from '@/api/tenantClient';

dayjs.extend(advancedFormat);
const StatusValidationError = 'Validation Error';
const StatusImportError = 'Import Error';

interface DataTableProps {
  data: ImportAttemptsTableRow[]
  isLoading: boolean
  isError: boolean
  isFetching: boolean
  refetch: () => void
  filters: MRT_ColumnFiltersState
  setFilters: React.Dispatch<React.SetStateAction<MRT_ColumnFiltersState>>
  sorting: MRT_SortingState
  setSorting: React.Dispatch<React.SetStateAction<MRT_SortingState>>
  pagination: MRT_PaginationState
  setPagination: React.Dispatch<React.SetStateAction<MRT_PaginationState>>
  search: string
  setSearch: (searchText: string) => void
  rowCount: number
  modelType: string
  statusSelectOptions?: DatatableSelectOptions
}

const DataTable: FC<DataTableProps> = ({
  data, isLoading, isError, isFetching, refetch, filters, setFilters, sorting, setSorting, pagination, setPagination, search, setSearch,
  rowCount,
  modelType,
  statusSelectOptions,
}) => {
  const navigate = useNavigate();
  const { tenantCode, tenant } = useRegisterRoute();
  const dataTableOptions = useDataTableOptions<ImportAttemptsTableRow>();

  const columns = useMemo<Array<MRT_ColumnDef<ImportAttemptsTableRow>>>(
    () => {
      return [
        {
          accessorKey: 'id',
          header: 'ID',
          enableColumnFilter: false,
          size: 50,
        },
        {
          accessorKey: 'status',
          header: 'Status',
          filterVariant: 'select',
          mantineFilterSelectProps: {
            data: statusSelectOptions
          }
        },
        {
          accessorKey: 'createdBy',
          header: 'Created by'
        },
        {
          accessorKey: 'createdAt',
          header: 'Created at',
          Cell: ({ cell }) => { return cell.getValue() ? dayjs(cell.getValue() as string | number | Date | Dayjs).format('hh:mm A Do MMMM Z') : ''; },
          filterVariant: 'date-range',
          enableColumnFilter: false,
        },
      ];
    },
    []
  );

  const toInfoPage = (importAttemptId: number) => {
    let pagePath: ManageRouteName | null = null;
    if (modelType === 'Registration') {
      pagePath = 'RegistrationImporterAttemptErrorRegistration';
    }
    if (pagePath) {
      navigate(generatePageUrl(pagePath, { tenantCode, importAttemptId: `${importAttemptId}` }));
    }
  };

  const handleClearFilters = () => {
    setFilters([]);
    setPagination({ pageIndex: 0, pageSize: 10 });
    setSearch('');
    setSorting([]);
  };

  const table = useMantineReactTable({
    columns,
    data,
    initialState: {
      showColumnFilters: true,
      showGlobalFilter: !!search,
    },
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    onColumnFiltersChange: setFilters,
    onSortingChange: setSorting,
    onGlobalFilterChange: setSearch,
    onPaginationChange: setPagination,
    rowCount,
    state: {
      columnFilters: filters,
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isFetching,
      sorting,
      globalFilter: search,
      pagination,
    },
    displayColumnDefOptions: { 'mrt-row-actions': { size: 120 } },
    enableRowActions: true,
    enableSelectAll: false,
    positionActionsColumn: 'last',
    globalFilterFn: 'contains',
    renderTopToolbarCustomActions: () => {
      return (
        <UiHStack
          spacing={8}
          flexGrow={1}
          justifyContent={'flex-end'}
          px={8}
        >
          <UiButton
            px={0}
            size={'sm'}
            variant={'ghost'}
            colorScheme={'primary'}
            onClick={refetch}>
            Apply filter
          </UiButton>
          <UiButton
            px={0}
            size={'sm'}
            variant={'ghost'}
            colorScheme={'primary'}
            onClick={handleClearFilters}>
            Clear all filters
          </UiButton>
        </UiHStack>
      );
    },
    renderRowActions: ({ row }) => {
      if ([StatusValidationError, StatusImportError].includes(row.original.status)) {
        return (
          <BaseAclContainer tenantId={tenant?.id ?? 0} permissionsRequired={[[PermissionDomain.Registration, PermissionAction.Write]]}>
            <UiHStack
              px={0}
              justifyContent={'flex-start'}
              onClick={() => { return toInfoPage(row.original.id); }}
              {...uiStyles.hover}
            >
              <UiText color={'primary.500'}> View errors </UiText>
            </UiHStack>
          </BaseAclContainer>
        );
      }
      return null;
    },
    ...dataTableOptions
  });

  return <MantineReactTable table={table} />;
};

export default DataTable;

import { ApiRequest } from "@/api/globalClient";
import { ApiResponse, properCallApi } from "@/api/tenantClient";
import { BadgeTemplate } from "./BadgeDesignerPage";

export interface BadgeTemplateQueryRequest {
  badgeTemplate: object,
  createBlank: boolean
}

export interface BadgeTemplateData {
  success?: boolean;
  badgeTemplate?: BadgeTemplate;
  error_message?: string;
}

export interface BadgeTemplatesData {
  success?: boolean;
  badgeTemplates?: BadgeTemplate[];
  tags?: any,
  error_message?: string;
}

export function saveAsBadgeTemplate(createTenantAdminApiRequest: () => ApiRequest) {
  const request = createTenantAdminApiRequest();
  return async (
    params: BadgeTemplateQueryRequest
  ): Promise<ApiResponse<BadgeTemplateData>> => {
    request.method = 'POST';
    request.endpoint.path = '/api/v1/admin/badge_templates';
    request.payload = params;
    return await properCallApi<ApiResponse<BadgeTemplateData>>(request);
  };
}

export function saveBadgeTemplate(createTenantAdminApiRequest: () => ApiRequest) {
  const request = createTenantAdminApiRequest();
  return async (
    id: string,
    params: BadgeTemplateQueryRequest
  ): Promise<ApiResponse<BadgeTemplateData>> => {
    request.method = 'PUT';
    request.endpoint.path = `/api/v1/admin/badge_templates/${id}`;
    request.payload = params;
    return await properCallApi<ApiResponse<BadgeTemplateData>>(request);
  };
}

export function getbBadgeTemplates(createTenantAdminApiRequest: () => ApiRequest) {
  const request = createTenantAdminApiRequest();
  return async (): Promise<ApiResponse<BadgeTemplatesData>> => {
    request.method = 'GET';
    request.endpoint.path = `/api/v1/admin/badge_templates`;
    return await properCallApi<ApiResponse<BadgeTemplatesData>>(request);
  };
}

export function deleteBadgeTemplate(createTenantAdminApiRequest: () => ApiRequest) {
  const request = createTenantAdminApiRequest();
  return async (
    id: string
  ): Promise<ApiResponse<BadgeTemplateData>> => {
    request.method = 'DELETE';
    request.endpoint.path = `/api/v1/admin/badge_templates/${id}`;
    return await properCallApi<ApiResponse<BadgeTemplateData>>(request);
  };
}

export function activateBadgeTemplate(createTenantAdminApiRequest: () => ApiRequest) {
  const request = createTenantAdminApiRequest();
  return async (
    id: string
  ): Promise<ApiResponse<BadgeTemplateData>> => {
    request.method = 'PUT';
    request.endpoint.path = `/api/v1/admin/badge_templates/activate/${id}`;
    return await properCallApi<ApiResponse<BadgeTemplateData>>(request);
  };
}

import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { cancelledRegistrationTableQueryKey, loadRegistrationsForTable, type RegistrationTableRow } from '@/api/registration';
import { type ApiResponse } from '@/api/tenantClient';
import BaseMessageBarInfo from '@/base/MessageBar/Info';
import { uiStyles } from '@/lib/ui';
import { useQuery } from '@tanstack/react-query';
import type React from 'react';
import { useEffect } from 'react';
import Datatable from './Datatable';
import BaseLoadingSpinner from '@/base/Loading/Spinner';
import { useLocalStorage } from '@uidotdev/usehooks';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { type MRT_ColumnFiltersState, type MRT_PaginationState, type MRT_SortingState } from 'mantine-react-table';

interface Params {
  filters: any
  sorting: any
  pagination: any
  search: string
}

const useGetCancelledRegistration = ({ filters, sorting, pagination, search }: Params & { setPagination: React.Dispatch<React.SetStateAction<any>> }) => {
  const { createTenantAdminApiRequest, isLoading: isTenantLoading } = useTenantApi();
  return useQuery<ApiResponse<RegistrationTableRow>, Error>({
    queryKey: [cancelledRegistrationTableQueryKey, pagination, filters, sorting, search],
    queryFn: async (): Promise<ApiResponse<RegistrationTableRow>> => {
      return await loadRegistrationsForTable(createTenantAdminApiRequest)({
        page: pagination.pageIndex,
        size: pagination.pageSize,
        type: 'Cancelled',
        filters,
        sorting,
        search
      });
    },
    keepPreviousData: true,
    staleTime: 30_000,
    enabled: !isTenantLoading,
  });
};

const LOCAL_TABLE_STORAGE_KEY = 'cancelled_registration_table';

const CancelledRegistrationTable = () => {
  const { tenantCode } = useRegisterRoute();

  const [filters, setFilters] = useLocalStorage<MRT_ColumnFiltersState>(`${LOCAL_TABLE_STORAGE_KEY}_filters_${tenantCode}`, []);
  const [sorting, setSorting] = useLocalStorage<MRT_SortingState>(`${LOCAL_TABLE_STORAGE_KEY}_sorting_${tenantCode}`, []);
  const [search, setSearch] = useLocalStorage<string>(`${LOCAL_TABLE_STORAGE_KEY}_search_${tenantCode}`, '');
  const [pagination, setPagination] = useLocalStorage<MRT_PaginationState>(`${LOCAL_TABLE_STORAGE_KEY}_pagination_${tenantCode}`, { pageIndex: 0, pageSize: 10 });
  const { reportToGlobal } = useApiErrorHandler();
  const { data, isLoading, isError, error, isFetching, refetch } = useGetCancelledRegistration({ filters, sorting, pagination, search, setPagination });
  useEffect(
    () => {
      if (error) {
        reportToGlobal(error);
      }
    },
    [error]
  );
  if (!data || isLoading) {
    return (
      <BaseLoadingSpinner />
    );
  }

  if (data.items && Array.isArray(data.items)) {
    return (
      <Datatable
        data={data.items}
        isLoading={isLoading}
        isError={isError}
        isFetching={isFetching}
        refetch={refetch}
        filters={filters}
        setFilters={setFilters}
        sorting={sorting}
        setSorting={setSorting}
        search={search}
        setSearch={setSearch}
        pagination={pagination}
        setPagination={setPagination}
        rowCount={data.pagination!.total}
        attendeeCategorySelectOptions={data?.selectOptions?.attendeeCategorySelectOptions ?? []}
        delegateTypeSelectOptions={data.selectOptions!.delegateTypeSelectOptions}
        sessionSelectOptions={data.selectOptions!.sessionSelectOptions}
      />
    );
  } else {
    return (
      <BaseMessageBarInfo borderRadius={uiStyles.borderRadius}>
        No Cancelled Registrations
      </BaseMessageBarInfo>
    );
  }
};

export default CancelledRegistrationTable;

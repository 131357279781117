import { type FC } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useDisclosure
} from '@chakra-ui/react';
import {
  type UiHStackProps,
  UiIconPlusCircle,
} from '@/lib/ui';
import AdminUserForm from './AdminUserForm';
import BaseButtonIconText from '@/base/Button/IconText';

export interface NewEventRowProps extends UiHStackProps {
}

const NewEventRow: FC<NewEventRowProps> = () => {
  const { isOpen, onToggle } = useDisclosure();
  const navigate = useNavigate();

  return (
    <>
      <BaseButtonIconText Icon={UiIconPlusCircle} onClick={onToggle} variant={'large'}>Invite</BaseButtonIconText>
      <AdminUserForm
        onClose={onToggle}
        isVisible={isOpen}
        onSaveSuccess={() => {}}
      />
    </>
  );
};

export default NewEventRow;

import { Center } from '@chakra-ui/react';
import { type UseQueryResult } from '@tanstack/react-query';
import BaseMessageBarError from '../MessageBar/Error';
import Spinner from '../Loading/Spinner';
import { UiBox, UiHStack, UiSpinner } from '@/lib/ui';
import BaseLoadingSpinner from '@/base/Loading/Spinner';

interface QueryContainerProps<T> {
  query: UseQueryResult<T>
  loadingComponent?: React.ReactElement
  errorMessage?: string
  children: (data: T) => React.ReactElement
  disableLoadingEffect?: boolean
  enableLoadingEffectOnFetching?: boolean
}

export function CenteredSpinner() {
  return (
    <Center h="100%" >
      <Spinner size="lg" />
    </Center>
  );
}

export function QueryContainer<T>({
  query,
  loadingComponent,
  children,
  errorMessage,
  disableLoadingEffect = false,
  enableLoadingEffectOnFetching = false,
}: QueryContainerProps<T>) {
  const { data, isLoading, isFetching, isError } = query;
  if (isLoading || (enableLoadingEffectOnFetching && isFetching)) {
    if (disableLoadingEffect) {
      return null;
    }
    return loadingComponent ?? (
      <UiHStack justifyContent={'flex-start'}>
        <BaseLoadingSpinner/>
      </UiHStack>
    );
  }
  if (isError || data === undefined || data === null) {
    return (
      <BaseMessageBarError>
        {errorMessage ?? 'Failed to load data.'}
      </BaseMessageBarError>
    );
  }
  return children(data);
}

export default QueryContainer;

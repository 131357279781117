import { type FC } from 'react';
import {
  useDisclosure
} from '@chakra-ui/react';
import {
  type UiHStackProps,
  UiIconPlusCircle,
  uiStyles,
  UiHStack,
  UiText
  // UiGrid,
} from '@/lib/ui';
import BaseButtonIconText from '@/base/Button/IconText';
import TaxRateForm from '@/registration/buildEvent/Locales/TaxRateForm';

export interface NewTaxRateProps extends UiHStackProps {}

const NewTaxRate: FC<NewTaxRateProps> = () => {
  const { isOpen, onToggle } = useDisclosure();
  return (
    <>
      <BaseButtonIconText Icon={UiIconPlusCircle} onClick={onToggle}>Add tax rate</BaseButtonIconText>
      <TaxRateForm
        onClose={onToggle}
        isVisible={isOpen}
        onSaveSuccess={() => {}}
      />
    </>
  );
};

export default NewTaxRate;
